import { DatePicker, Form, Input, InputNumber, Select } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import { dataType } from '../../../constants/dataType'

function ExtendCallInfo ({ callInfoStrategySettings, callStatus }) {
  const { Option } = Select
  const buildElement = () => {
    return callInfoStrategySettings &&
      callInfoStrategySettings.map((item, key) => {
        const dataTypeInfo = dataType.find(type => type.value === item.fieldType)
        if (!dataTypeInfo) return (<></>)

        let input
        switch (item.fieldType) {
          case 0:
            // text
            input = (
              <Input />
            )
            break
          case 1:
            // number
            input = (
              <InputNumber />
            )
            break
          case 2:
            // date
            input = (
              <DatePicker />
            )
            break
          case 3:
            // date
            input = (
              <DatePicker
                format="YYYY-MM-DD HH:mm:ss"
                showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
                showNow={true}
              />
            )
            break
          case 4:
          case 5:
            // Select
            input = (
              <Select mode={dataTypeInfo.selectMode} placeholder="Chọn giá trị">
                {item.selectValues?.map((selectValue) => {
                  return (
                    <Option key={key} value={selectValue.key}>{selectValue.value}</Option>
                  )
                })}
              </Select>
            )
            break
          default:
            break
        }
        return (
          <div key={key}>
            <Form.Item
              label={item.displayName}
              name={item.code + '_custom'}
              rules={[
                {
                  required: item.requiredCallStatus ? item.requiredCallStatus.includes(callStatus) : false,
                  message: 'Đây là trường bắt buộc'
                }
              ]}
            >
              {input}
            </Form.Item>
          </div>
        )
      })
  }
  return (
    <div>
      {buildElement()}
    </div>
  )
}

export default ExtendCallInfo
