import React from 'react'
import { StrategyDetailBreadCrumb } from '../BreadCrumbs'
function StrategyDetailTitle () {
  return (
    <div className='p-5'>
        <h2>Chi tiết chiến dịch</h2>
        <div>{<StrategyDetailBreadCrumb/>}</div>
    </div>
  )
}

export default StrategyDetailTitle
