export const reportStrategyServices = [
  {
    serviceName: 'StrategyReportByDate',
    chartType: 'spline',
    title: 'Theo ngày'

  },
  {
    serviceName: 'StrategyReportByGroup',
    chartType: 'column',
    title: 'Theo nhóm'
  },
  {
    serviceName: 'StrategyReportByEmployee',
    chartType: 'column',
    title: 'Theo nhân viên'
  }
]
