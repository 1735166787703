import { HistoryOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'

export default function CallCardTitle ({ customerId, extension, handleGetCallHistory }) {
  return (
    <div>
      {
      customerId &&
        <div className="flex justify-end">
          <div className='mr-2'>
            {extension}
          </div>
          <Button
            className="text-xs"
            icon={<HistoryOutlined rotate={180} />}
            onClick={handleGetCallHistory}
          >
            Thông tin lần gọi trước
          </Button>
        </div>
      }
    </div>
  )
}
