import request from '../utils/request'
import { getStaticInfo } from './common'
import { handleError } from './handleError'

export const distributeEmployee = async (userIds) => {
  const { headers, siteId } = getStaticInfo()
  try {
    const response = await request.put(
      'LinePhone/AssignUser',
      { siteId, userIds },
      {
        headers
      }
    )
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const revokeEmployee = async (ids) => {
  const { headers } = getStaticInfo()
  try {
    const response = await request.delete('LinePhone/Users', {
      headers,
      data: {
        ids
      }
    })
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const getLinePhoneWithUser = async () => {
  const { headers } = getStaticInfo()
  try {
    const response = await request.get('LinePhone/Users', {
      headers
    })
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const getLinePhoneByCurrentUser = async () => {
  const { headers, userId } = getStaticInfo()
  try {
    const response = await request.get(`LinePhone/User/${userId}`, {
      headers
    })
    return response.data
  } catch (error) {
    handleError(error)
  }
}
