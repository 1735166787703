import { Link } from 'react-router-dom'
import React from 'react'
import {
  AppstoreOutlined,
  MailOutlined,
  HomeOutlined,
  BarChartOutlined,
  HistoryOutlined
} from '@ant-design/icons'
import { Menu } from 'antd'
import { checkIsTechnical } from '../../permissions'

const items = [
  {
    link: '/',
    label: 'Trang chủ',
    key: 'home',
    icon: <HomeOutlined />
  },
  {
    link: '/strategy',
    label: 'Chiến dịch',
    key: 'strategy',
    icon: <AppstoreOutlined />
  },
  {
    link: '/report',
    label: 'Báo cáo',
    key: 'report',
    icon: <BarChartOutlined />
  },
  {
    link: '/callHistory',
    label: 'Lịch sử',
    key: 'callHistory',
    icon: <HistoryOutlined />
  }
  // {
  //   link: '/microsip',
  //   label: 'Microsip',
  //   key: 'microsip',
  //   icon: <MailOutlined />,
  //   requireTechnical: true
  // }
  // {
  //   link: '/feedback',
  //   label: 'Feedback',
  //   key: 'feedback',
  //   icon: <MailOutlined />,
  //   requireTechnical: true
  // }
]

function getSelectedItem () {
  const pathName = location.pathname
  let pathSelect = items.find(x => pathName === x.link)
  if (!pathSelect) {
    pathSelect = items.findLast(x => pathName.includes(x.link))
  }

  return pathSelect.link
}

function Navigation ({ loginState }) {
  return (
    <Menu
      disabled={!loginState}
      mode="horizontal"
      theme="dark"
      selectedKeys={[getSelectedItem()]}
    >
      {items.map((item) => {
        return (
          <Menu.Item key={item.link}>
            <Link to={item.link}>
              {item.icon}
              <span>{item.label}</span>
            </Link>
          </Menu.Item>
        )
      })}
    </Menu>
  )
}

export default Navigation
