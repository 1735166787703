import React from 'react'
import {
  MailFilled,
  CopyrightOutlined,
  TwitterOutlined,
  FacebookFilled,
  BehanceOutlined
} from '@ant-design/icons'
function Forbidden () {
  return (
    <div className="w-full h-full">
      <div className="bg-white w-full h-full rounded-xl drop-shadow-2xl p-8 flex flex-col justify-between">
        <div className="flex-1 flex-col py-14 justify">
          <div className="text-center text-7xl font-semibold text-indigo-700 my-2">
            403
          </div>
          <div className="text-center text-5xl font-semibold text-indigo-700 mb-8">
            Access Denied
          </div>
          <div className="text-center text-[#d8b4fe]">
            Xin lỗi, bạn không có quyền để truy cập vào trang này
          </div>
          <div className="text-center text-[#d8b4fe]">
            Bạn có thể <a className="text-indigo-700" onClick={() => history.back()}> quay lại trang trước</a>
          </div>
        </div>
        <div className=" w-full flex justify-between">
          <div className="flex items-center">
            <CopyrightOutlined className="mr-1 font-bold" /> 2022-2023 Call
            master
          </div>
          <div className="text-lg">
            <FacebookFilled className="mr-2" />
            <TwitterOutlined className="mr-2" />
            <BehanceOutlined />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Forbidden
