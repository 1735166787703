import {
  Button,
  Select,
  Table,
  Card,
  Modal,
  DatePicker,
  Pagination,
  Tag
} from 'antd'

import React, { useState, useEffect } from 'react'
import { getEntryCallInfo, getRankingByServiceName } from '../../../services/reportService'
import { useDispatch } from 'react-redux'
import { clearAndPushErrorMessage } from '../../../slices/errorMessageSlice'
import { typesRank } from '../../../constants/ranking'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/vi_VN'
import { getAllStrategy } from '../../../services/strategyService'
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'
import { filterEmployees } from '../../../services/userInfoService'
import { DownloadOutlined, UserOutlined } from '@ant-design/icons'
import { checkIsEmployee, checkIsSupperAdmin } from '../../../permissions'
import excelFileFormat from '../../../constants/excelFileFormat'
const { RangePicker } = DatePicker

const originalColumn = [
  {
    title: 'Tên nhân viên',
    dataIndex: 'EmployeeName',
    key: 'employeeName'
  },
  {
    title: 'Tổng số lượng cuộc gọi',
    key: 'total',
    align: 'center',
    render: (record) => {
      return <span>{record.ApproveCount + record.RejectCount + record.FaildCount + record.RemainAgaintCount}</span>
    }
  },
  {
    title: 'Tổng số cuộc gọi thành công',
    key: 'callTimes',
    align: 'center',
    render: (record) => {
      // return <span>{record.ApproveCount + record.RejectCount + record.RemainAgaintCount}</span>
      return <span>{record.ApproveCount + record.RejectCount + record.RemainAgaintCount}</span>
    }
  },
  {
    title: 'Zalo báo nhóm',
    dataIndex: 'ApproveCount',
    key: 'approveCount',
    align: 'center'
  },
  {
    title: 'Không nhu cầu',
    dataIndex: 'RejectCount',
    key: 'rejectCount',
    align: 'center'
  },
  {
    title: 'Thất bại',
    dataIndex: 'FaildCount',
    key: 'faildCount',
    align: 'center'
  }
  // {
  //   title: 'Hẹn gọi lại',
  //   dataIndex: 'RemainAgaintCount',
  //   key: 'remainAgaintCount',
  //   align: 'center'
  // }

]

function Ranking () {
  const [dataSource, setDataSource] = useState(false)
  const [rankingService, setRankingService] = useState(
    'OperationRankBySuccessCallTime'
  )
  const dispatch = useDispatch()
  const { Option } = Select
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
  const [datePickerValue, setDatePickerValue] = useState([moment(), moment()])
  const [strategies, setStrategies] = useState([])
  const [selectedStrategies, setSelectedStrategies] = useState([])
  const [toggleSearch, setToggleSearch] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(10)
  const [isExport, setIsExport] = useState(false)
  const [employees, setEmployees] = useState([])
  const [employeeSelected, setEmployeeSelected] = useState([])
  const [employeeNotCall, setEmployeeNotCall] = useState([])
  const [isLoadingExport, setIsLoadingExport] = useState(false)
  const [isRefesh, setIsRefesh] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [columns, setColumns] = useState(originalColumn)
  const dateFormat = 'DD-MM-YYYY'

  const getEmployees = async () => {
    const STATUS_DEFAULT = 1
    const response = await filterEmployees({ status: STATUS_DEFAULT }) // get all user
    if (response.success) {
      setEmployees(response.results)
    } else {
      dispatch(clearAndPushErrorMessage(response.message))
    }
  }

  const getRanking = async (dataFilter) => {
    setIsLoading(true)
    const response = await getRankingByServiceName(dataFilter)
    if (response.success) {
      const data = response.employees?.employeeRankModels.map((item, key) => ({ ...item, key }))
      console.log('data', data)
      if (data && data.length) {
        const firstData = data[0]
        const ignoreProperties = ['EmployeeId', 'EmployeeName', 'ApproveCount', 'RejectCount', 'RemainAgaintCount', 'FaildCount', 'key']
        const extendColumns = []
        for (const [key, value] of Object.entries(firstData)) {
          if (!ignoreProperties.includes(key)) {
            extendColumns.push({
              title: key,
              dataIndex: key,
              key
            })
          }
        }
        const aa = originalColumn.concat(extendColumns)
        setColumns(aa)
      }

      setDataSource(data)
      setEmployeeNotCall(response.employees?.employeeNotCalls)
    } else {
      dispatch(clearAndPushErrorMessage(response.errorMessage))
    }

    setIsLoading(false)
  }

  useEffect(() => {
    getEmployees()
  }, [])

  useEffect(() => {
    const dataFilter = {
      serviceName: rankingService,
      rangeDate: {
        startTime: startDate,
        endTime: endDate
      },
      strategyIds: selectedStrategies,
      employeeIds: employeeSelected,
      limit: currentPageSize,
      page: currentPage,
      isExport
    }

    getRanking(dataFilter)
  }, [rankingService, startDate, endDate, selectedStrategies, employeeSelected, toggleSearch, currentPage, currentPageSize, isExport, isRefesh])

  useEffect(() => {
    const handler = async () => {
      const activeStatus = -1
      const response = await getAllStrategy(activeStatus)
      const { data } = response
      setStrategies(data)
    }

    handler()
  }, [])

  const onRangePickerChange = (date) => {
    setDatePickerValue(date)
    setStartDate(date[0].format('YYYY-MM-DD'))
    setEndDate(date[1].format('YYYY-MM-DD'))
  }

  const handlePagingChange = (page, pageSize) => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }

  const exportEntryCall = () => {
    const handler = async () => {
      setIsLoadingExport(true)
      const dataFilter = {
        rangeDate: {
          startTime: startDate,
          endTime: endDate
        },
        strategyIds: selectedStrategies,
        employeeIds: employeeSelected
      }

      const response = await getEntryCallInfo(dataFilter)
      if (response.success) {
        const fileName = 'export_callinfo'
        const dataMapping = response.data.map(item => {
          const newItem = {
            'Tên nhân viên': item.employeeName,
            'Tên chiến dịch': item.strategyName,
            'Ngày gọi': item.callCreatedAt,
            'Zalo báo nhóm': item.approveTimes,
            // 'Hẹn gọi lại': item.againTimes,
            'Không nhu cầu': item.rejectTimes,
            'Thất bại': item.faildTimes,
            'Tổng số cuộc gọi thành công': item.approveTimes + item.rejectTimes,
            'Tổng số lượng cuộc gọi': item.approveTimes + item.rejectTimes + item.faildTimes
          }

          return newItem
        })
        const workSheet = XLSX.utils.json_to_sheet(dataMapping)
        const workBook = { Sheets: { data: workSheet }, SheetNames: ['data'] }
        const excelBuffer = XLSX.write(workBook, {
          bookType: 'xlsx',
          type: 'array'
        })
        const data = new Blob([excelBuffer], { type: excelFileFormat.FILE_TYPE })
        FileSaver.saveAs(data, fileName + excelFileFormat.FILE_EXTENSION)
      } else {
        dispatch(clearAndPushErrorMessage(response.message))
      }
      setIsLoadingExport(false)
    }

    handler()
  }

  return (
    <Card
      className="h-full"
      title={
        <div className="flex flex-col tablet:flex-row justify-between ">
          <RangePicker
            className='tablet:w-1/2 w-full tablet:min-w-[250px] mb-2 tablet:mb-0'
            placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
            format={dateFormat}
            value={datePickerValue}
            onChange={onRangePickerChange} showToday={true} locale={locale} />
          <Select
            maxTagCount={2}
            className='tablet:ml-1 mb-2 tablet:mb-0 w-full '
            placeholder="Chọn chiến dịch"
            value={selectedStrategies}
            mode="multiple"
            allowClear={true}
            filterOption={(input, option) => (option?.lable ?? '').includes(input)}
            showArrow={true}
            onChange={(value) => {
              setSelectedStrategies(value)
            }}>
            {
              strategies?.map((item) => (
                <Option lable={item.name} value={item.id} key={item.id}>
                  <div className="">
                    <div className="font-bold">{item.name}</div>
                  </div>
                </Option>
              ))
            }
          </Select>
          {
            !checkIsEmployee() &&
            <Select
              maxTagCount={4}
              className='tablet:ml-1 mb-2 tablet:mb-0 w-full '
              placeholder="Chọn nhân viên"
              suffixIcon={<UserOutlined />}
              value={employeeSelected}
              mode="multiple"
              allowClear={true}
              filterOption={(input, option) => (option?.key ?? '').includes(input)}
              showArrow={true}
              onChange={(value) => {
                setEmployeeSelected(value)
              }}>
              {
                employees?.map((item) => (
                  <Option value={item.id} key={item.name}>
                    <div className="">
                      <div className="font-bold">{item.name}</div>
                      {item.roleInfo?.title && <Tag color="green" className='text-[12px]'>{item.roleInfo?.title}</Tag>}
                    </div>
                  </Option>
                ))
              }
            </Select>
          }

          <div className="flex justify-center">
            <Button
              type="primary"
              className="w-max tablet:ml-2 mb-2 tablet:mb-0"
              onClick={() => setToggleSearch(!toggleSearch)}
            >
              Tìm kiếm
            </Button>
            <Button
              type="primary"
              className="w-max tablet:ml-2 mb-2 tablet:mb-0"
              onClick={() => setIsRefesh(!isRefesh)}
            >
              Tải lại
            </Button>
          </div>

          {
            checkIsSupperAdmin() &&
            <Button loading={isLoadingExport} className='ml-2' onClick={exportEntryCall} icon={<DownloadOutlined />}>Xuất dữ liệu</Button>
          }
        </div>
      }
    >
      {
        !checkIsEmployee() && <p>Tổng số nhân viên gọi: {dataSource.length}</p>
      }
      <Table pagination={false} scroll={{ x: '65vh' }} columns={columns} dataSource={dataSource} loading={isLoading}></Table>
      {/* <Pagination
            className="text-right mt-8"
            total={total}
            showSizeChanger
            showQuickJumper
            showTotal={(total) => `Tổng ${total} nhân viên`}
            defaultCurrent={currentPage}
            defaultPageSize={currentPageSize}
            pageSize={currentPageSize}
            current={currentPage}
            onChange={handlePagingChange}
          /> */}

      {
        !checkIsEmployee() &&
        <Card className='mt-4' title="Nhân viên không trong danh sách trên">
          {employeeNotCall?.map((item, key) => {
            return (
              <Tag key={key}>
                {item}
              </Tag>
            )
          })}
        </Card>
      }

    </Card>
  )
}

export default Ranking
