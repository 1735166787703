import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  id: ''
}

export const strategySlice = createSlice({
  name: 'strategy',
  initialState,
  reducers: {
    setId: (state, { payload }) => {
      state.id = payload.id
    }
  }
})

export const { setId } = strategySlice.actions

export const getId = (state) => state.strategyReducer.id

export default strategySlice.reducer
