import React from 'react'
import { ReportBreadCrumb } from '../BreadCrumbs'
function ReportTitle () {
  return (
    <div className='p-5'>
        <h2>Báo cáo</h2>
        <div>{<ReportBreadCrumb/>}</div>
    </div>
  )
}

export default ReportTitle
