import * as Sentry from '@sentry/react'
import React, { useEffect, useState, useRef } from 'react'
import JsSIP from 'jssip'
import { Button, Modal, Form, Input, Select, DatePicker } from 'antd'
import { useDispatch } from 'react-redux'
import {
  clearAndPushErrorMessage,
  clearAndPushNotifyMessage,
  clearAndPushSuccessMessage
} from '../../slices/errorMessageSlice'
import { callStatuses, FAILD, OTHER, RECALL, REJECT } from '../../constants/callStatuses'
import {
  updateCallStrategyFail as createCallStrategyFail,
  createCallStrategySuccess,
  updateCallInfo
} from '../../services/callStrategyService'
import ExtendCallInfo from '../Strategy/Components/ExtendCallInfo'
import moment from 'moment'
import { Prompt } from 'react-router-dom'
import { getCurrentUserId, getStaticInfo } from '../../services/common'
import { YYYYMMDD_HHMMSS } from '../../constants/dateTimeFormat'
import { INCOMMING, OUTBOUND } from '../../constants/callDirection'
import audioPlayer from '../../utils/audioPlayer'
import StopWatch from '../../components/Common/Stopwatch'
import { createCallInfo } from '../../services/callInfoService'
import { NORMAL } from '../../constants/strategyType'
function CallAction ({ disabled, customerId, strategyId, customerPhoneNumber, resetCustomerState, callInfoStrategySettings, linePhone, setUA, strategyType }) {
  const [remoteAudio] = useState(new Audio())
  const [modalState, setModalState] = useState(false)
  const [callInfoId, setCallInfoId] = useState()
  const [callState, setCallState] = useState(true)
  const [callDuration, setCallDuration] = useState(0)
  const [defaultCallStatus, setDefaultCallState] = useState(REJECT.value)
  const [readyCall, setReadyCall] = useState(false)
  const [isCallBtnLoading, setIsCallBtnLoading] = useState(false)
  const [isLoadingUpdateCallInfo, setIsLoadingUpdateCallInfo] = useState(false)
  const [isStartStopWatch, setIsStartStopWatch] = useState(false)
  const [isResetStopWatch, setIsResetStopWatch] = useState(false)
  const [faildData, setFaildData] = useState({})
  const [triggerCallFaild, setTriggerCallFaild] = useState(false)
  const [trigglerCallEnd, setTrigglerCallEnd] = useState(false)
  const [callButtonText, setCallButtonText] = useState('Đang kết nối')
  const [disableEndCall, setDisableEndCall] = useState(false)
  const [hiddenAutoCall, setHiddenAutoCall] = useState(false)
  const [isAutocall, setIsAutoCall] = useState(false)
  const [autoCalling, setAutoCalling] = useState(false)
  const [incomingPhoneNumber, setIncomingPhoneNumber] = useState()
  const [isStartInCommingStartWatch, setIsStartInCommingStartWatch] = useState()
  const [isResetInCommingStartWatch, setIsResetInCommingStartWatch] = useState()
  const [openIncommingCall, setOpenIncommingCall] = useState()
  const [currentCallDirection, setCurrentCallDirection] = useState(0)
  const isFirstTimeFaildRef = useRef(true)
  const isFirstTimeEndRef = useRef(true)
  const isFirstTimeCallAuto = useRef(true)
  const needReport = useRef(false)
  const sessionRef = useRef()
  const incomingSessionRef = useRef()
  const startCallTime = useRef()
  const isDontPickUp = useRef()
  const isReporting = useRef(false)
  const uaRef = useRef()
  const dispatch = useDispatch()
  const { Option } = Select
  const [form] = Form.useForm()
  const { TextArea } = Input
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const employeeId = userInfo?.id
  const MAX_HANGUP_TIME = 22 // durations
  const mounted = useRef(false)

  useEffect(() => {
    if (!linePhone) {
      return
    }

    try {
      mounted.current = true
      const siteInfo = JSON.parse(localStorage.getItem('siteInfo'))

      const socket = new JsSIP.WebSocketInterface(siteInfo?.socketServer)
      socket.via_transport = 'WSS'

      const uaConfig = {
        sockets: [socket],
        uri: `sip:${linePhone?.extension}@${siteInfo?.uriSuffix}`,
        password: `${linePhone?.secret}`
      }

      if (siteInfo?.uriSuffix === 'sip2598.worldfone.vn') {
        uaConfig.contact_uri = `sip:${linePhone?.extension}@${siteInfo?.uriSuffix}`
        uaConfig.registrar_server = siteInfo?.uriSuffix
        uaConfig.authorization_user = linePhone?.extension
        uaConfig.session_timers = false
      }

      console.log('uaConfig', uaConfig)

      const ua = new JsSIP.UA(uaConfig)

      uaRef.current = ua

      uaRef.current.on('connecting', () => {
        if (!mounted.current) return

        console.log('start connecting ua', moment().format(YYYYMMDD_HHMMSS))
        setCallButtonText('Đang kết nối')
      })

      uaRef.current.on('connected', () => {
        if (!mounted.current) return

        setCallState(true)
        setReadyCall(true)
        setCallButtonText('Gọi')
        console.log('Ua connected', moment().format(YYYYMMDD_HHMMSS))
      })

      uaRef.current.on('disconnected', () => {
        console.log('Ua disconnected...')
        if (!mounted.current) return

        setReadyCall(false)
      })

      uaRef.current.on('registered', () => {
        if (!mounted.current) return

        console.log('Ua registered!', moment().format(YYYYMMDD_HHMMSS))
        setCallState(true)
        setReadyCall(true)
        setCallButtonText('Gọi')
      })

      uaRef.current.on('unregistered', () => {
        console.log('Unregistered!!!')
        if (!mounted.current) return

        if (uaRef.current.isConnected()) {
          setCallState(true)
          setReadyCall(true)
        } else {
          setCallState(true)
          setReadyCall(false)
        }
      })

      uaRef.current.on('registrationFailed', (data) => {
        if (!mounted.current) return

        if (uaRef.current.isConnected()) {
          setCallState(true)
          setReadyCall(true)
        } else {
          setCallState(true)
          setReadyCall(false)
          setCallButtonText('Kết nối thất bại')
        }
      })

      uaRef.current.on('newRTCSession', async (data) => {
        console.log('newRTCSession', data)
        const { siteId, userId } = getStaticInfo()

        if (data.originator === 'local') return

        const session = data.session

        if (sessionRef.current || incomingSessionRef.current || isReporting.current) {
          session.terminate()

          return
        }
        setCurrentCallDirection(INCOMMING.value)

        audioPlayer.play('ringing')

        incomingSessionRef.current = session
        sessionRef.current = session

        console.log('session', session)
        setIncomingPhoneNumber(session.remote_identity._uri._user)

        setOpenIncommingCall(true)

        session.on('failed', (data) => {
          console.log('failed data', data)
          const message = {
            email: userInfo.email,
            time: moment().format(YYYYMMDD_HHMMSS),
            data
          }
          Sentry.captureMessage(message)
          audioPlayer.stop('ringing')
          sessionRef.current = null
          incomingSessionRef.current = null
          setIsStartInCommingStartWatch(false)
          setIsResetInCommingStartWatch(true)
          setOpenIncommingCall(false)
        })

        session.on('ended', (e) => {
          if (needReport.current) {
            console.log('incoming ended', e)
            const durations = (sessionRef.current.end_time - sessionRef.current.start_time) / 1000
            setCallDuration(durations)
            sessionRef.current = null
            incomingSessionRef.current = null
            setIncomingPhoneNumber()
            setIsStartInCommingStartWatch(false)
            setIsResetInCommingStartWatch(true)
            setModalState(true)
            setOpenIncommingCall(false)
            isReporting.current = true
          }
        })

        session.on('accepted', (e) => {
          audioPlayer.stop('ringing')
          console.log('accepted', e)

          setIsStartInCommingStartWatch(true)
          sessionRef.current = session
          incomingSessionRef.current = session
        })

        session.on('peerconnection', (e) => {
          const peerconnection = e.peerconnection
          const remoteStream = new MediaStream()

          peerconnection.ontrack = function (e) {
            peerconnection.getReceivers().forEach(function (receiver) {
              remoteStream.addTrack(receiver.track)
            })
            remoteAudio.volume = 1
            remoteAudio.srcObject = remoteStream
            remoteAudio.play()
          }
        })

        session.answer()

        const callReq = {
          EmployeeId: userId,
          siteId,
          strategyId,
          callDirection: INCOMMING.value,
          phoneNumber: session.remote_identity._uri._user
        }

        const response = await createCallInfo(callReq)
        if (response.success) {
          const { callId } = response
          setCallInfoId(callId)
          needReport.current = true
          console.log('callId', callId)
        }
      })

      setUA(uaRef.current)
    } catch (error) {
      dispatch(clearAndPushErrorMessage('Lỗi kết nối sip' + JSON.stringify(error)))
    }
  }, [linePhone])

  useEffect(() => {
    if (strategyType === NORMAL.value && uaRef.current) {
      uaRef.current.start()
    }
  }, [strategyType, uaRef.current])

  useEffect(() => {
    if (!isFirstTimeFaildRef.current) {
      const handler = async () => {
        setDisableEndCall(true)
        const data = faildData

        console.log('failed data', data)

        let status = OTHER.value
        let faildCause = data.cause
        const message = data?.message
        let statusCode = 0
        if (message) {
          statusCode = message.status_code
        }

        if (data.cause === JsSIP.C.causes.CANCELED && data.originator === 'local') { // Nhân viên tắt máy
          status = FAILD.value
          faildCause = 'BUSY'
        }
        faildCause = faildCause + ' ' + statusCode.toString()

        await createCallStrategyFail({
          strategyId,
          userId: getCurrentUserId(),
          customerId,
          status,
          faildCause
        })

        resetCustomerState()
        setHiddenAutoCall(false)
        setCallState(true)
        setDisableEndCall(false)
        setIsCallBtnLoading(false)
        setIsStartStopWatch(false)
        setIsResetStopWatch(true)
      }

      handler()
    } else {
      isFirstTimeFaildRef.current = false
    }
  }, [triggerCallFaild])

  useEffect(() => {
    if (!isFirstTimeEndRef.current) {
      const handler = async () => {
        console.log('ended the call')
        setDisableEndCall(true)
        setIsStartStopWatch(false)
        setIsResetStopWatch(true)

        const userId = getCurrentUserId()
        if (isDontPickUp.current) {
          console.log('Cuộc gọi kết thúc, khách hàng không bắt máy')
          dispatch(clearAndPushNotifyMessage('Cuộc gọi kết thúc, khách hàng không bắt máy'))

          await createCallStrategyFail({
            strategyId,
            userId,
            customerId,
            status: FAILD.value,
            faildCause: 'CUSTOMER_DONT_PICK_UP'
          })

          resetCustomerState()
        } else {
          console.log('Cuộc gọi kết thúc')

          const durations = (sessionRef.current.end_time - sessionRef.current.start_time) / 1000
          setCallDuration(durations)
          const response = await createCallStrategySuccess({
            strategyId,
            userId,
            customerId,
            durations
          })
          if (response.success) {
            const callInfo = response.callInfo
            setCallInfoId(callInfo?.callId)
            dispatch(clearAndPushSuccessMessage('Cuộc gọi kết thúc'))
            setModalState(true)
          } else {
            dispatch(clearAndPushErrorMessage(`Có lỗi xẩy ra khi tạo thông tin cuộc gọi cho ${customerPhoneNumber}`))
          }
        }
        setCallState(true)
        setHiddenAutoCall(false)
        setDisableEndCall(false)
        setIsCallBtnLoading(false)
      }

      handler()
    } else {
      isFirstTimeEndRef.current = false
    }
  }, [trigglerCallEnd])

  useEffect(() => {
    if (isFirstTimeCallAuto.current) {
      isFirstTimeCallAuto.current = false
    } else {
      if (isAutocall) {
        callCustomer()
      }
    }
  }, [customerPhoneNumber])

  const callCustomer = async () => {
    setIsCallBtnLoading(true)
    const siteInfo = JSON.parse(localStorage.getItem('siteInfo'))

    const RTCsession = uaRef.current.call(`sip:${customerPhoneNumber}@${siteInfo?.uriSuffix}`,
      {
        mediaConstraints: { audio: true, video: false },
        // sessionTimersExpires: 7200,
        eventHandlers
      })

    RTCsession.on('connecting', (data) => {
      console.log('connecting...', data)
      sessionRef.current = RTCsession
    })
  }

  const endTheCall = () => {
    sessionRef.current.terminate()
  }

  // Register callbacks to desired call events
  const eventHandlers = {
    progress: function (e) {
      setCurrentCallDirection(OUTBOUND.value)
      startCallTime.current = moment()
      setCallState(false)
      setIsCallBtnLoading(false)
    },
    failed: function (data) {
      sessionRef.current = null

      setFaildData(data)
      setTriggerCallFaild(!triggerCallFaild)
    },
    ended: function (e) {
      setTrigglerCallEnd(!trigglerCallEnd)
    },
    confirmed: function (e) {
      const hangupTime = moment().diff(startCallTime.current, 'seconds')
      if (hangupTime < MAX_HANGUP_TIME) {
        isDontPickUp.current = false
      } else {
        isDontPickUp.current = true
        endTheCall()
      }
    },
    accepted: function (e) {
      setIsStartStopWatch(true)
      setIsResetStopWatch(false)
    },
    peerconnection: function (e) {
      const peerconnection = e.peerconnection
      const remoteStream = new MediaStream()
      remoteAudio.volume = 1

      peerconnection.ontrack = function (e) {
        peerconnection.getReceivers().forEach(function (receiver) {
          remoteStream.addTrack(receiver.track)
        })
        remoteAudio.srcObject = remoteStream
        remoteAudio.play()
      }
    }
  }

  const handleRejectIncoming = () => {
    incomingSessionRef.current.terminate()
    setOpenIncommingCall(false)
    setModalState(true)
    isReporting.current = true
  }

  const handleAnswerIncoming = () => {
    incomingSessionRef.current.answer()
  }

  const handleUpdateCallInfo = async (callInfo) => {
    if (!callInfoId) {
      dispatch(clearAndPushNotifyMessage('Cuộc gọi chưa khởi tạo xong, vui lòng thử lại'))
      return
    }
    setIsLoadingUpdateCallInfo(true)
    const extendObject = {}
    for (let [key, value] of Object.entries(callInfo)) {
      if (key.includes('_custom') && value) {
        const realKey = key.replace('_custom', '')
        console.log(`${realKey}: ${value}`)
        if (Array.isArray(value)) value = value.join(',')
        Object.assign(extendObject, { [realKey]: value })
      }
    }

    if (callInfo.status === RECALL.value && !callInfo.callLaterTime) {
      dispatch(clearAndPushErrorMessage('Vui lòng chọn thời gian khách hẹn gọi lại'))
    } else {
      const callFullInfo = {
        strategyId,
        employeeId,
        extendInfo: JSON.stringify(extendObject),
        callDirection: currentCallDirection,
        ...callInfo
      }
      if (currentCallDirection === OUTBOUND.value) {
        callFullInfo.customerId = customerId
      }

      const response = await updateCallInfo(callInfoId, callFullInfo)

      if (response.success) {
        dispatch(clearAndPushSuccessMessage('Cập nhật thành công'))
        form.resetFields()
      } else {
        dispatch(clearAndPushErrorMessage(response.message))
      }

      if (currentCallDirection === OUTBOUND.value) {
        setIsCallBtnLoading(false)
        resetCustomerState()
      }

      setModalState(false)
      setIsLoadingUpdateCallInfo(false)
      setCallInfoId()
      isReporting.current = false
      needReport.current = false
    }
  }

  const handleAutoCallOnClick = () => {
    setIsAutoCall(true)
    setAutoCalling(true)
    callCustomer()
  }

  const handleStopAutoCall = () => {
    endTheCall()
    setIsAutoCall(false)
    setAutoCalling(false)
  }

  return (
    <div>
      <Modal
        title="Thông tin cuộc gọi"
        open={modalState}
        // open={true}
        closable={false}
        cancelText={false}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        maskClosable={false}
      >
        <Form
          name="Basic"
          layout="vertical"
          form={form}
          onFinish={handleUpdateCallInfo}
          fields={[
            {
              name: ['durations'],
              value: callDuration
            },
            {
              name: ['status'],
              value: defaultCallStatus
            }
          ]}
        >
          <Form.Item
            label="Kết quả"
            name="status"
            rules={[
              {
                required: true,
                message: 'Đây là trường bắt buộc' // thêm rule cho type
              }
            ]}>
            <Select onChange={(value) => {
              setDefaultCallState(value)
            }}>
              {callStatuses.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {
            defaultCallStatus === RECALL.value
              ? <Form.Item label="Thời gian khách hẹn gọi lại" name="callLaterTime">
                <DatePicker />
              </Form.Item>
              : <></>
          }
          <Form.Item label="Thời gian gọi" name="durations">
            <Input readOnly />
          </Form.Item>

          <ExtendCallInfo
            callInfoStrategySettings={callInfoStrategySettings}
            callStatus={defaultCallStatus}
          />

          <Form.Item label="Ghi chú" name="note">
            <TextArea />
          </Form.Item>

          <Form.Item >
            <Button loading={isLoadingUpdateCallInfo} type="primary" htmlType="submit">
              Hoàn thành
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        maskClosable={false}
        closable={false}
        footer={<></>}
        title={<h2><span>Cuộc gọi đến </span> <span>{incomingPhoneNumber}</span></h2>}
        open={openIncommingCall}>
        {
          <div>
            <div className='flex justify-center'>
              <Button
                danger
                type="text"
                onClick={handleRejectIncoming}>
                Kết thúc cuộc gọi
              </Button>
              {/* <Button
                type='primary'
                onClick={handleAnswerIncoming}>
                Trả lời
              </Button> */}
            </div>
            <div className="text-center mt-4">
              <StopWatch isReset={isResetInCommingStartWatch} isStart={isStartInCommingStartWatch} />
            </div>
          </div>
        }

      </Modal>
      {
        strategyType === NORMAL.value
          ? <div>
            <div className='flex'>
              {
                callState
                  ? <Button
                    type="primary"
                    loading={isCallBtnLoading}
                    onClick={() => {
                      setHiddenAutoCall(true)
                      callCustomer()
                    }}
                    disabled={disabled || !readyCall}
                  >
                    {callButtonText}
                  </Button>
                  : <Button
                    disabled={disableEndCall}
                    type="primary"
                    onClick={() => endTheCall()}
                  >
                    Kết thúc cuộc gọi
                  </Button>
              }
              {
                hiddenAutoCall || getStaticInfo().siteId === '3ade5fde-1ff7-4218-8b5e-f5e9a3e58114' || getStaticInfo().siteId === 'a52c3e22-4670-470c-a64f-04475b04011e'
                  ? <></>
                  : <div>
                    {
                      !autoCalling
                        ? <Button disabled={disabled || !readyCall} onClick={handleAutoCallOnClick} danger className='ml-6'>Gọi tự động</Button>
                        : <Button onClick={handleStopAutoCall} className='ml-6'>Tắt gọi tự động</Button>
                    }
                  </div>
              }
            </div>
            <div className="text-center mt-4">
              <StopWatch isReset={isResetStopWatch} isStart={isStartStopWatch} />
            </div>
          </div>
          : <></>
      }

      <Prompt
        when={!callState}
        message="Cuộc gọi đang diễn ra, bạn vẫn muốn rời đi?"
      />
    </div >
  )
}

export default CallAction
