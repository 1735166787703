import httpResponseStatus from '../constants/httpResponseStatus'
export const handleError = (error) => {
  const statusCode = error.response.status
  if (statusCode === httpResponseStatus.FORBIDDEN.statusCode) {
    window.location.replace('/forbidden')
  } else if (statusCode === httpResponseStatus.UNAUTHORIZED.statusCode) {
    localStorage.clear()
    window.location.replace(`/Login?statusError=${statusCode}`)
  } else {
    const response = { errorMessage: error.message, status: error.response.status }
    return response
  }
}
