export const checkDuplicationTypes = [
  {
    label: 'Không kiểm tra trùng lặp',
    value: 0
  },
  {
    label: 'Kiểm tra trùng lặp trong chiến dịch',
    value: 1
  },
  {
    label: 'Kiểm tra trùng lặp trong hệ thống',
    value: 2
  }
]
