export const getStaticInfo = () => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  const userId = userInfo?.id
  const callSource = userInfo?.callSource
  const headers = {
    'x-access-site': siteId,
    Authorization: 'Bearer ' + jwtToken
  }

  return {
    jwtToken,
    siteId,
    userId,
    headers,
    callSource
  }
}

export const getCurrentUserId = () => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const userId = userInfo?.id

  return userId
}
