import React from 'react'
import { Breadcrumb } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

function CallStrategyBreadCrumb () {
  return (
    <Breadcrumb separator={<RightOutlined />}>
      <Breadcrumb.Item ><Link to="/">Trang chủ</Link></Breadcrumb.Item>
      <Breadcrumb.Item ><Link to="/strategy">Chiến dịch</Link></Breadcrumb.Item>
      <Breadcrumb.Item >Gọi ngay</Breadcrumb.Item>
    </Breadcrumb>
  )
}

export default CallStrategyBreadCrumb
