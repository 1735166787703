import React, { useEffect, useState } from 'react'
import { Select, Modal, Table, DatePicker, Button, Tag } from 'antd'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { getStrategyReport } from '../../../services/reportService'
import { useDispatch } from 'react-redux'
import { clearAndPushErrorMessage } from '../../../slices/errorMessageSlice'
import { reportStrategyServices } from '../../../constants/reportStrategy'
import { CaretDownOutlined, SyncOutlined, UsergroupAddOutlined, UserOutlined } from '@ant-design/icons'
import { getGroups } from '../../../services/groupUserService'
import { filterEmployees } from '../../../services/userInfoService'
import locale from 'antd/es/date-picker/locale/vi_VN'
import moment from 'moment'
import { isOnlyClient } from '../../../permissions'
const dateFormat = 'DD-MM-YYYY'
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/accessibility')(Highcharts)

function Report ({
  openReportModal,
  setOpenReportModal,
  strategyId,
  currentStrategyName
}) {
  const { Option } = Select
  const { RangePicker } = DatePicker
  const [reportChartOptions, setReportChartOptions] = useState()
  const dispatch = useDispatch()
  const [reportDataSource, setReportDataSource] = useState([])
  const DEFAULT_SERVICE_NAME = 'StrategyReportByDate'
  const [serviceName, setServiceName] = useState(DEFAULT_SERVICE_NAME)
  const [startDate, setStartDate] = useState(moment().add(-1, 'month').format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
  const [datePickerValue, setDatePickerValue] = useState([moment().add(-1, 'month'), moment()])
  const [groups, setGroups] = useState([])
  const [employees, setEmployees] = useState([])
  const [groupSelected, setGroupSelected] = useState([])
  const [employeeSelected, setEmployeeSelected] = useState([])
  const [reportColumns, setReportColumns] = useState([
    {
      title: '',
      dataIndex: 'label',
      className: 'whitespace-nowrap'
    },
    {
      title: 'Tổng số cuộc gọi',
      dataIndex: 'total',
      className: 'whitespace-nowrap'
    },
    {
      title: 'Đã kết nối thành công',
      dataIndex: 'connected',
      className: 'whitespace-nowrap'
    },
    {
      title: 'Zalo báo nhóm',
      dataIndex: 'approve',
      className: 'whitespace-nowrap'
    },
    {
      title: 'Không nhu cầu',
      dataIndex: 'reject',
      className: 'whitespace-nowrap'
    },
    // {
    //   title: 'Hẹn gọi lại',
    //   dataIndex: 'remainCall',
    //   className: 'whitespace-nowrap'
    // },
    {
      title: 'Thất bại',
      dataIndex: 'fail',
      className: 'whitespace-nowrap'
    }
  ])

  useEffect(() => {
    const isClient = isOnlyClient()
    if (isClient) {
      setReportColumns([
        {
          title: '',
          dataIndex: 'label',
          className: 'whitespace-nowrap'
        },
        {
          title: 'Đồng ý',
          dataIndex: 'approve',
          className: 'whitespace-nowrap'
        }
      ])
    }
  }, [])

  const buildTotalInfoCallTimes = (reportData) => {
    let totalCallTimes = 0
    let totalApproveTimes = 0
    let totalRejectTimes = 0
    let totalRemainCallTimes = 0
    let totalFailTimes = 0

    reportData.forEach((element) => {
      totalCallTimes += element.totalCount
      totalApproveTimes += element.approveCount
      totalRejectTimes += element.rejectCount
      totalRemainCallTimes += element.remainAgaintCount
      totalFailTimes += element.failCount
    })

    return {
      totalCallTimes,
      totalApproveTimes,
      totalRejectTimes,
      totalRemainCallTimes,
      totalFailTimes
    }
  }

  const buildTotalInfoCallReport = (reportData) => {
    const {
      totalCallTimes,
      totalApproveTimes,
      totalRejectTimes,
      totalRemainCallTimes,
      totalFailTimes
    } = buildTotalInfoCallTimes(reportData)

    return {
      key: 0,
      label: 'Tổng cộng',
      total: (
        <div>
          <span className="text-green-900">{totalCallTimes}</span>
          <span className="text-xs"> | 100%</span>
        </div>
      ),
      connected: (
        <div>
          <span className="text-green-900">{totalApproveTimes + totalRemainCallTimes + totalRejectTimes}</span>
          <span className="text-xs">
            {' | ' +
              ((totalApproveTimes + totalRemainCallTimes + totalRejectTimes) / totalCallTimes).toLocaleString('en', {
                style: 'percent'
              })}
          </span>
        </div>
      ),
      approve: (
        <div>
          <span className="text-green-900">{totalApproveTimes}</span>
          {
            isOnlyClient()
              ? <></>
              : <span className="text-xs">
            {' | ' +
              (totalApproveTimes / (totalApproveTimes + totalRemainCallTimes + totalRejectTimes)).toLocaleString('en', {
                style: 'percent'
              })}
          </span>
          }

        </div>
      ),
      reject: (
        <div>
          <span className="text-green-900">{totalRejectTimes}</span>
          <span className="text-xs">
            {' | ' +
              (totalRejectTimes / (totalApproveTimes + totalRemainCallTimes + totalRejectTimes)).toLocaleString('en', {
                style: 'percent'
              })}
          </span>
        </div>
      ),
      remainCall: (
        <div>
          <span className="text-green-900">{totalRemainCallTimes}</span>
          <span className="text-xs">
            {' | ' +
              (totalRemainCallTimes / (totalApproveTimes + totalRemainCallTimes + totalRejectTimes)).toLocaleString('en', {
                style: 'percent'
              })}
          </span>
        </div>
      ),
      fail: (
        <div>
          <span className="text-green-900">{totalFailTimes}</span>
          <span className="text-xs">
            {' | ' +
              (totalFailTimes / totalCallTimes).toLocaleString('en', {
                style: 'percent'
              })}
          </span>
        </div>
      )
    }
  }

  const buildAverageInfoCallTimes = (reportData) => {
    const {
      totalCallTimes,
      totalApproveTimes,
      totalRejectTimes,
      totalRemainCallTimes,
      totalFailTimes
    } = buildTotalInfoCallTimes(reportData)

    return {
      key: 0,
      label: 'Trung bình',
      total: (
        <div>
          <span className="text-green-900">
            {Number(totalCallTimes / reportData.length).toFixed(2)}
          </span>
        </div>
      ),
      connected: (
        <div>
          <span className="text-green-900">
            {Number((totalCallTimes - totalFailTimes) / reportData.length).toFixed(2)}
          </span>
        </div>
      ),
      approve: (
        <div>
          <span className="text-green-900">
            {Number(totalApproveTimes / reportData.length).toFixed(2)}
          </span>
        </div>
      ),
      reject: (
        <div>
          <span className="text-green-900">
            {Number(totalRejectTimes / reportData.length).toFixed(2)}
          </span>
        </div>
      ),
      remainCall: (
        <div>
          <span className="text-green-900">
            {Number(totalRemainCallTimes / reportData.length).toFixed(2)}
          </span>
        </div>
      ),
      fail: (
        <div>
          <span className="text-green-900">
            {Number(totalFailTimes / reportData.length).toFixed(2)}
          </span>
        </div>
      )
    }
  }

  const handleChangeServiceName = (newServiceName) => {
    setServiceName(newServiceName)
  }

  const handler = async () => {
    const requestFilter = {
      strategyId,
      serviceName,
      rangeDate: {
        startTime: startDate,
        endTime: endDate
      },
      employeeIds: employeeSelected,
      groupIds: groupSelected

    }
    const response = await getStrategyReport(requestFilter)
    if (response.success) {
      const reportData = response.result
      const tableData = reportData.map((item, key) => {
        return {
          key: key + 2,
          label: item.label,
          total: (
            <div>
              <span className="text-green-900">{item.totalCount}</span>
              <span className="text-xs"> | 100%</span>
            </div>
          ),

          connected: (
            <div>
              <span className="text-green-900">{item.approveCount + item.rejectCount + item.remainAgaintCount}</span>
              <span className="text-xs">
                {' | ' +
                  (1 - (item.failCount / item.totalCount)).toLocaleString('en', {
                    style: 'percent'
                  })}
              </span>
            </div>
          ),
          approve: (
            <div>
              <span className="text-green-900">{item.approveCount}</span>
             {
              isOnlyClient()
                ? <></>
                : <span className="text-xs">
              {' | ' +
                (item.approveCount / (item.approveCount + item.rejectCount + item.remainAgaintCount)).toLocaleString('en', {
                  style: 'percent'
                })}
            </span>
             }
            </div>
          ),
          reject: (
            <div>
              <span className="text-green-900">{item.rejectCount}</span>
              <span className="text-xs">
                {' | ' +
                  (item.rejectCount / (item.approveCount + item.rejectCount + item.remainAgaintCount)).toLocaleString('en', {
                    style: 'percent'
                  })}
              </span>
            </div>
          ),
          remainCall: (
            <div>
              <span className="text-green-900">{item.remainAgaintCount}</span>
              <span className="text-xs">
                {' | ' +
                  (item.remainAgaintCount / (item.approveCount + item.rejectCount + item.remainAgaintCount)).toLocaleString(
                    'en',
                    { style: 'percent' }
                  )}
              </span>
            </div>
          ),
          fail: (
            <div>
              <span className="text-green-900">{item.failCount}</span>
              <span className="text-xs">
                {' | ' +
                  (item.failCount / item.totalCount).toLocaleString('en', {
                    style: 'percent'
                  })}
              </span>
            </div>
          )
        }
      })

      if (!isOnlyClient()) {
        tableData.unshift(buildAverageInfoCallTimes(reportData))

        tableData.unshift(buildTotalInfoCallReport(reportData))
      }

      setReportDataSource(tableData)

      const revertReportData = reportData.reverse()

      const getChartTypeByServiceName = (currentServiceName) =>
        reportStrategyServices.find(
          (item) => item.serviceName === currentServiceName
        ).chartType

      const chartOptions = {
        chart: {
          type: getChartTypeByServiceName(serviceName),
          scrollablePlotArea: {
            minWidth: 700,
            scrollPositionX: 1
          }
        },
        title: {
          text: 'Báo cáo chiến dịch'
        },
        yAxis: {
          title: {
            text: 'Sô lượt gọi'
          }
        },
        xAxis: {
          categories: revertReportData.map((item) => item.label),
          crosshair: true
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            pointWidth: 50
          }
        },
        tooltip: {
          shared: true,
          crosshairs: true
        },
        series: [
          {
            name: 'Đồng ý',
            data: revertReportData.map((item) => item.approveCount),
            dashStyle: 'spline'
          },
          {
            name: 'Từ chối',
            data: revertReportData.map((item) => item.rejectCount),
            dashStyle: 'spline'
          },
          {
            name: 'Hẹn gọi lại',
            data: revertReportData.map((item) => item.remainAgaintCount),
            dashStyle: 'spline'
          },
          {
            name: 'Thất bại',
            data: revertReportData.map((item) => item.failCount),
            dashStyle: 'spline'
          }
        ]
      }
      setReportChartOptions(chartOptions)

      setOpenReportModal(true)
    } else {
      dispatch(clearAndPushErrorMessage(response.message))
    }
  }

  const getEmployees = async () => {
    const STATUS_DEFAULT = 1
    const response = await filterEmployees({ status: STATUS_DEFAULT }) // get all user
    if (response.success) {
      setEmployees(response.results)
    } else {
      dispatch(clearAndPushErrorMessage(response.message))
    }
  }

  const getGroupsDataSource = async () => {
    const STATUS_DEFAULT = -1
    const response = await getGroups(STATUS_DEFAULT) // get all group
    if (response.success) {
      const groups = response.results
      setGroups(groups)
    } else {
      dispatch(clearAndPushErrorMessage(response.message))
    }
  }

  useEffect(() => {
    if (openReportModal) {
      const handler = async () => {
        await getGroupsDataSource()
        await getEmployees()
      }

      handler()
    }
  }, [openReportModal])

  useEffect(() => {
    if (openReportModal) {
      handler()
    }
  }, [openReportModal, serviceName, startDate, endDate, groupSelected, employeeSelected])

  const onRangePickerChange = (date, dateString) => {
    setDatePickerValue(date)
    setStartDate(date[0].format('YYYY-MM-DD'))
    setEndDate(date[1].format('YYYY-MM-DD'))
  }

  const handleRefresh = () => {
    setStartDate(moment().add(-1, 'month').format('YYYY-MM-DD'))
    setEndDate(moment().format('YYYY-MM-DD'))
    setDatePickerValue([moment().add(-1, 'month'), moment()])
    setServiceName(DEFAULT_SERVICE_NAME)
    setEmployeeSelected([])
    setGroupSelected([])
  }

  function OptionService () {
    return (
      <Select
        className="tablet:ml-4 w-full mb-2 tablet:mb-0"
        defaultValue={serviceName}
        onChange={handleChangeServiceName}
        suffixIcon={<CaretDownOutlined />}
      >
        {reportStrategyServices.map((item) => (
          <Option key={item.serviceName} value={item.serviceName}>
            {item.title}
          </Option>
        ))}
      </Select>
    )
  }

  return (
    <Modal
      title={<h3>{'Báo cáo chiến dịch: ' + currentStrategyName}</h3>}
      closable={false}
      centered
      maskClosable={false}
      open={openReportModal}
      bodyStyle={{ maxHeight: '80vh', overflow: 'scroll' }}
      cancelButtonProps={{ style: { display: 'none' } }}
      okText="Đóng"
      onOk={() => {
        setOpenReportModal(false)
        handleRefresh()
      }}
      onCancel={() => {
        setOpenReportModal(false)
        handleRefresh()
      }}
      className="min-w-[80%] modal-report-strategy "
    >
      <div className="flex justify-end mb-4 tablet:flex-row flex-col items-end">
        <Select
          maxTagCount={1}
          className='tablet:mr-1 mb-2 tablet:mb-0 w-full tablet:w-[200px]'
          placeholder="Chọn nhóm"
          suffixIcon={<UsergroupAddOutlined />}
          mode="multiple"
          allowClear={true}
          value={groupSelected}
          showArrow={true}
          onChange={(value) => {
            setGroupSelected(value)
          }} >
          {
            groups?.map((item) => (
              <Option value={item.id} key={item.id}>
                <div className="">
                  <div className="">{item.name}</div>
                </div>
              </Option>
            ))
          }
        </Select>
        <Select
          maxTagCount={1}
          className='tablet:mr-1 mb-2 tablet:mb-0 w-full tablet:w-[175px]'
          placeholder="Chọn nhân viên"
          suffixIcon={<UserOutlined />}
          value={employeeSelected}
          mode="multiple"
          allowClear={true}
          showArrow={true}
          filterOption={(input, option) => (option?.key ?? '').includes(input)}
          onChange={(value) => {
            setEmployeeSelected(value)
          }}>
          {
            employees?.map((item) => (
              <Option value={item.id} key={item.name}>
                <div className="">
                  <div className="font-bold">{item.name}</div>
                  {item.roleInfo?.title && <Tag color="green" className='text-[12px]'>{item.roleInfo?.title}</Tag>}
                </div>
              </Option>
            ))
          }
        </Select>
        <div className="flex flex-col tablet:flex-row w-full tablet:w-max">
          <RangePicker
            className='w-full tablet:min-w-[250px] mb-2 tablet:mb-0'
            placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
            format={dateFormat}
            value={datePickerValue}
            onChange={onRangePickerChange} showToday={true} locale={locale} />
          <OptionService className="w-full " />
        </div>
        <Button onClick={() => handleRefresh()} className='ml-4' type="primary"><SyncOutlined /></Button>

      </div>

      <Table
        columns={reportColumns}
        dataSource={reportDataSource}
        pagination={false}
        scroll={{
          y: 350,
          x: 800
        }}
        rowClassName={(record, index) =>
          index === 0 ? 'bg-orange-200' : index === 1 ? 'bg-red-200' : ''
        }
      />
      <div className="mt-4">
        <HighchartsReact className="overflow-scroll" highcharts={Highcharts} options={reportChartOptions} />
      </div>
    </Modal>
  )
}

export default Report
