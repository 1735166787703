import React from 'react'
import { Breadcrumb } from 'antd'
import { RightOutlined } from '@ant-design/icons'

function StrategyDetailBreadCrumb () {
  return (
        <Breadcrumb separator={<RightOutlined />}>
            <Breadcrumb.Item href='/'>Trang chủ</Breadcrumb.Item>
            <Breadcrumb.Item href="/Strategy">Chiến dịch</Breadcrumb.Item>
            <Breadcrumb.Item href="">Chi tiết chiến dịch</Breadcrumb.Item>
        </Breadcrumb>
  )
}

export default StrategyDetailBreadCrumb
