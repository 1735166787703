import React, { useState, useEffect, useRef } from 'react'
import { Modal, Select, Card, Table, Button, Input } from 'antd'
import {
  distributeEmployee,
  getLinePhoneWithUser,
  revokeEmployee
} from '../../../../services/linePhoneService'
import { useDispatch } from 'react-redux'
import {
  clearAndPushErrorMessage,
  clearAndPushSuccessMessage
} from '../../../../slices/errorMessageSlice'
function EmployeeDistribution ({
  modalDistributionOpen,
  setModalDistributionOpen,
  reloadData
}) {
  const [linePhoneUserInfos, setLinePhoneUserInfos] = useState()
  const [datasource, setDatasource] = useState([])
  const idsDistribution = useRef([])
  const [idsRevoke, setIdsRevoke] = useState([])
  const [toggleData, setToggleData] = useState(false)
  const [freeUsers, setFreeUsers] = useState()
  const [freeLinePhone, setFreeLinePhone] = useState()
  const [selectedRowKeys, setRowKeys] = useState([])
  const dispatch = useDispatch()
  const columns = [
    {
      title: 'Tên nhân viên',
      dataIndex: 'fullName'
    },
    {
      title: 'Email',
      dataIndex: 'email'
    }
  ]

  const handleGetLinePhoneWithUser = async () => {
    const response = await getLinePhoneWithUser()
    if (response.success) {
      const linePhoneWithUser = response.result
      setFreeLinePhone(linePhoneWithUser?.remainLinePhoneCount)
      const recordsLinePhoneWithUser =
        linePhoneWithUser.linePhoneUserInfos?.map((linePhone) => {
          return { key: linePhone.linePhoneId, ...linePhone }
        })
      setLinePhoneUserInfos(recordsLinePhoneWithUser)
      setDatasource(recordsLinePhoneWithUser)
      const optionsFreeUser = linePhoneWithUser?.freeUsers?.map((user) => {
        return { value: user.id, label: user.fullName }
      })
      setFreeUsers(optionsFreeUser)
    } else {
      dispatch(clearAndPushErrorMessage(response.message))
    }
  }

  useEffect(() => {
    handleGetLinePhoneWithUser()
  }, [toggleData, reloadData])

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setRowKeys(selectedRowKeys)
    const userIds = selectedRows.map((selectedRow) => selectedRow.userId)
    setIdsRevoke(userIds)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const handleSelectFreeUser = (ids) => {
    idsDistribution.current = ids
  }

  const executeDistributeEmployee = async () => {
    const response = await distributeEmployee(idsDistribution.current)
    if (response.success) {
      idsDistribution.current = []
      dispatch(clearAndPushSuccessMessage('Phân bổ thành công'))
      setToggleData(!toggleData)
    } else {
      dispatch(clearAndPushErrorMessage(response.message))
    }
  }

  const handleDistributeEmployee = () => {
    if (idsDistribution.current && idsDistribution.current.length) {
      executeDistributeEmployee()
    } else {
      dispatch(
        clearAndPushErrorMessage('Vui lòng lựa chọn nhân viên cần phân bổ!')
      )
    }
  }

  const handleRevokeEmployee = async () => {
    const response = await revokeEmployee(idsRevoke)
    if (response.success) {
      setIdsRevoke([])
      dispatch(clearAndPushSuccessMessage('Thu hồi thành công'))
      setToggleData(!toggleData)
    } else {
      dispatch(clearAndPushErrorMessage(response.message))
    }
    setRowKeys([])
  }

  const closeModalDistribution = () => {
    idsDistribution.current = []
    setIdsRevoke([])
    setRowKeys([])
    setModalDistributionOpen(false)
  }

  function CardTitleDistribution () {
    return (
      <div className="flex flex-col">
        <label htmlFor="free-employee" className="">
          Phân bổ nhân viên còn trống
        </label>
        <div className="flex">
          <Select
            mode="multiple"
            id="free-employee"
            placeholder="Lựa chọn nhân viên ..."
            allowClear
            onChange={handleSelectFreeUser}
            defaultValue={idsDistribution.current}
            className="w-full mb-1"
            options={freeUsers}
            filterOption={(input, option) => (option?.label ?? '').includes(input)}

          />
        </div>
        <div key="freeLinePhone" className=' text-sm font-light tablet:block mb-2 tablet:mb-0'>
            Số đường mạng còn trống: {freeLinePhone}
          </div>
      </div>
    )
  }

  return (
    <Modal
      title={<h3>Danh sách phân bổ nhân viên gọi</h3>}
      className="tablet:w-[65%]"
      open={modalDistributionOpen}
      onCancel={() => {
        closeModalDistribution()
      }}
      footer={[
          <div className="" key="action">
            <Button
              key="close"
              type="ghost"
              onClick={() => {
                closeModalDistribution()
              }}
            >
              Đóng
            </Button>
            <Button
              key="revoke"
              type="ghost"
              danger
              disabled={idsRevoke?.length === 0}
              onClick={() => {
                handleRevokeEmployee()
              }}
            >
              Thu hồi
            </Button>
            <Button
              key="distribution"
              className="ml-2"
              type="primary"
              onClick={() => {
                handleDistributeEmployee()
              }}
            >
              Phân bổ
            </Button>
          </div>
      ]}
    >
      <Card title={<CardTitleDistribution />}>
        <Input className='mb-4 h-[35px]' placeholder='Tìm kiếm nhân viên'
          onChange={(e) => {
            const text = e.target.value.toLowerCase()
            const dataFilter = datasource.filter((item) => item.fullName.toLowerCase().includes(text) || item.email.toLowerCase().includes(text))
            setLinePhoneUserInfos(dataFilter)
          }}
        />
        <Table
          rowSelection={{
            ...rowSelection
          }}
          scroll={{
            y: 400,
            x: 800
          }}
          columns={columns}
          dataSource={linePhoneUserInfos}
          pagination={false}
        />
      </Card>
    </Modal>
  )
}

export default EmployeeDistribution
