import React from 'react'
import { UserProfileBreadCrumb } from '../BreadCrumbs'

function UserProfileTitle () {
  return (<div className='tablet:px-[24px] tablet:pt-[24px]'>
            <h2 >Quản lý người dùng</h2>
            <div>{<UserProfileBreadCrumb />}</div>
          </div>)
}

export default UserProfileTitle
