import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 0
}

export const currentStepSlice = createSlice({
  name: 'currentStep',
  initialState,
  reducers: {
    next: (state) => {
      state.value = state.value + 1
    },
    prev: (state) => {
      state.value = state.value - 1
    },
    setCurrent: (state, { payload }) => {
      state.value = payload
    }
  }
})

export const { next, prev, setCurrent } = currentStepSlice.actions

export const getCurrentStep = (state) => state.currentStepSlice.value

export default currentStepSlice.reducer
