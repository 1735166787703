import React, { useEffect } from 'react'
import { Card, notification } from 'antd'
import { checkLogin } from '../../../services/login'
import { useSelector } from 'react-redux'

import { getSuccessMessage, getErrorMessage, getNotifyMessage } from '../../../slices/errorMessageSlice'
import Header from '../../Header'
import CustomerSupport from '../../CustomerSupport'

function DefaultLayout ({ children, cardTitle }) {
  const errorMessages = useSelector(getErrorMessage)
  const successMessages = useSelector(getSuccessMessage)
  const notifyMessages = useSelector(getNotifyMessage)

  useEffect(() => {
    const isLogin = checkLogin()
    if (!isLogin) window.location.replace('/login')
  }, [])

  useEffect(() => {
    successMessages.forEach((message, key) => {
      notification.success({
        key,
        message: 'Thành công',
        description: message
      })
    })
  }, [successMessages])

  useEffect(() => {
    notifyMessages.forEach((message, key) => {
      notification.success({
        key,
        message: 'Thông báo',
        description: message
      })
    })
  }, [notifyMessages])

  useEffect(() => {
    errorMessages.forEach((message, key) => {
      notification.error({
        key,
        message: 'Cố lỗi xẩy ra',
        description: message
      })
    })
  }, [errorMessages])

  return (
    <div className='h-[100vh] flex flex-col'>
      <Header />
      <Card className='tablet:w-full flex-1  card-layout'
        title={
          cardTitle
        }>
        <div className="flex h-full">
          <div className="w-full tablet:grow">{children}</div>
        </div>
      </Card>
      <CustomerSupport />
    </div>
  )
}

export default DefaultLayout
