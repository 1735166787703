import {
  Input,
  Button,
  Modal,
  Alert,
  Table,
  Tag,
  Form,
  Tooltip,
  Pagination,
  Popconfirm
} from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import {
  PlusOutlined,
  DeleteOutlined,
  CheckOutlined,
  EditOutlined,
  DownloadOutlined,
  CloseOutlined
} from '@ant-design/icons'
import {
  clearCustomer,
  getSettings,
  searchCustomers
} from '../../../../services/strategyService'
import {
  createCustomerContactInfo,
  deleteCustomer,
  updateCustomerContactInfo,
  updateCustomerComplete
} from '../../../../services/customerService'
import { useDispatch } from 'react-redux'
import {
  clearAndPushErrorMessage,
  clearAndPushSuccessMessage
} from '../../../../slices/errorMessageSlice'
import ImportCustomer from './CustomerActions/ImportCustomer'
import { useMediaQuery } from 'react-responsive'
import FilterCustomer from './CustomerActions/FilterCustomer'
import screenSize from '../../../../constants/screenSize'
import { customer } from '../../../../constants/settingObjectType'
import { canClearCustomer, checkIsEmployee } from '../../../../permissions'
import { useHistory } from 'react-router'
import DeleteCustomerByFile from './CustomerActions/DeleteCustomerByFile'

function ListContactStep ({ strategyId }) {
  const history = useHistory()
  const [isModalCreateOrUpdateOpen, setIsModalCreateOrUpdateOpen] =
    useState(false)
  const [contactFields, setContactFields] = useState([])
  const [customerInfos, setCustomerInfos] = useState([])
  const [customerInfoExtend, setCustomerInfoExtend] = useState([])
  const [toggleData, setToggleData] = useState(false)
  const [form] = Form.useForm()
  const [modalDeleteState, setModalDeleteState] = useState(false)
  const [customerDelete, setCustomerDelete] = useState()
  const [isUpdate, setIsUpdate] = useState(false)
  const [customerUpdateInfo, setCustomerUpdateInfo] = useState()
  const [customerUpdateId, setCustomerUpdateId] = useState()
  const [isStrategyActive, setIsStrategyActive] = useState()
  const [modalCompleteState, setModalCompleteState] = useState(false)
  const [multipleAdd, setMultipleAdd] = useState(false)
  const [countCustomer, setCountCustomer] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(10)
  const [isLoading, setIsLoading] = useState(true)
  const [newStateCustomerComplete, setNewStateCustomerComplete] =
    useState(true)
  const [isClearCustomerLoading, setIsClearCustomerLoading] = useState(false)
  const [dataFilter, setDataFilter] = useState({
    isComplete: -1
  })
  const [isOpenDeleteCustomerByFile, setIsOpenDeleteCustomerByFile] = useState(false)
  const actionName = useRef('Cập nhật')

  const dispatch = useDispatch()
  const isTabletMiniOrMobile = useMediaQuery({ query: screenSize.TABLETMINI }) // tablet mini

  function mappingCustomerInfo () {
    const extendCustomerInfos = []
    customerInfos.forEach((customer) => {
      const extendFieldString = customer.extendInfo
      let extendField = JSON.parse(extendFieldString)
      extendField = { ...extendField, key: customer.id }
      const extendCustomerInfo = { ...customer, ...extendField }
      extendCustomerInfos.push(extendCustomerInfo)
    })

    setCustomerInfoExtend(extendCustomerInfos)
  }

  useEffect(() => {
    const handler = async () => {
      const response = await getSettings(strategyId, customer.value)
      if (response.success) {
        setContactFields(response.contactInfo)
      } else {
        dispatch(clearAndPushErrorMessage(response.errorMessage))
      }
    }

    handler()
  }, [])

  useEffect(() => {
    setDataFilter({
      ...dataFilter,
      limit: currentPageSize,
      page: currentPage
    })

    const handler = async () => {
      const response = await searchCustomers(strategyId, dataFilter)
      if (response.success) {
        const customerPagingModel = response?.customers?.customerPagingModel
        setIsStrategyActive(response?.customers?.isActive)
        setCustomerInfos(customerPagingModel?.customerModels)
        setCountCustomer(customerPagingModel?.count)
        setIsLoading(false)
      } else {
        dispatch(clearAndPushErrorMessage(response.errorMessage))
      }
    }

    handler()
  }, [strategyId, toggleData, currentPage, currentPageSize])

  useEffect(() => {
    if (
      contactFields &&
      contactFields.length &&
      customerInfos &&
      customerInfos.length
    ) {
      mappingCustomerInfo()
    } else {
      setCustomerInfoExtend(customerInfos)
    }
  }, [contactFields, customerInfos])

  const handleDelete = (customer) => {
    setCustomerDelete(customer)
    setModalDeleteState(true)
  }

  const handleUpdate = (customerInfo) => {
    actionName.current = 'Cập nhật'
    const customer = []
    for (const [key, value] of Object.entries(customerInfo)) {
      const fieldInfos = contactFields.filter((field) => field.code === key)
      if (fieldInfos.length) {
        const fieldInfo = fieldInfos[0]
        customer.push({ name: fieldInfo.code, value })
      }
    }
    setCustomerUpdateId(customerInfo.id)
    setIsUpdate(true)
    setCustomerUpdateInfo(customer)
    setIsModalCreateOrUpdateOpen(true)
  }

  const openModalComplete = (customerId) => {
    setModalCompleteState(true)
    setCustomerUpdateId(customerId)
  }

  const setCustomerCompleteStatus = (customerId) => {
    openModalComplete(customerId)
    setNewStateCustomerComplete(true)
  }
  const setCustomerInCompleteStatus = (customerId) => {
    openModalComplete(customerId)
    setNewStateCustomerComplete(false)
  }

  function ButtonToggleStateCustomerComplete ({ customer }) {
    if (customer.isCompleted) {
      return (
        <Tooltip title="Xác nhận chưa hoàn thành">
          <Button
            icon={<CloseOutlined />}
            onClick={() => setCustomerInCompleteStatus(customer.id)}
          ></Button>
        </Tooltip>
      )
    }
    return (
      <Tooltip title="Xác nhận đã hoàn thành">
        <Button
          icon={<CheckOutlined />}
          onClick={() => setCustomerCompleteStatus(customer.id)}
        ></Button>
      </Tooltip>
    )
  }

  const columns = [
    {
      title: 'Thông tin khách hàng',
      width: isTabletMiniOrMobile && 250,
      render: (customer) => {
        const htmlItems = []
        for (const [key, value] of Object.entries(customer)) {
          const fieldInfos = contactFields.filter(
            (field) => field.code === key
          )
          if (fieldInfos.length) {
            const fieldInfo = fieldInfos[0]
            const displayName = fieldInfo.displayName
            htmlItems.push(
              <div className="" key={key}>
                <span className="font-bold mr-2 inline-block">
                  {displayName + ':'}
                </span>
                {value}
              </div>
            )
          }
        }

        return <div>{htmlItems.map((item) => item)}</div>
      }
    },
    {
      title: 'Tình trạng',
      width: isTabletMiniOrMobile && 250,
      key: 'status',
      render: (customer) => {
        return (
          <div>
            <div className="">
              <div>
                <span className="font-bold mr-2 inline-block">
                  {'Tạo lúc' + ':'}
                </span>
                {customer.createdAt}
              </div>
            </div>
            <div className="">
              <div>
                <span className="font-bold mr-2 inline-block">
                  {'Cập nhật lúc' + ':'}
                </span>
                {customer.updatedAt}
              </div>
            </div>
            <div className="">
              <div>
                <span className="font-bold mr-2 inline-block">
                  {'Số lần gọi' + ':'}
                </span>
                {customer.callTimes}
              </div>
            </div>
            <Tag color="magenta">
              {customer.isCompleted ? 'Đã hoàn thành' : 'Chưa hoàn thành'}
            </Tag>
          </div>
        )
      }
    },
    {
      key: 'feature',
      width: '150px',
      render: (customer) => {
        return (
          <div className="justify-between flex">
            <Tooltip title="Liên hệ chỉ có thể được xoá nếu chưa tiến hành cuộc gọi nào và chiến dịch ở trạng thái dừng hoạt động">
              <Button
                icon={<DeleteOutlined />}
                disabled={!isStrategyActive && customer.callTimes === 0}
                danger
                onClick={() => handleDelete(customer)}
              ></Button>
            </Tooltip>
            <ButtonToggleStateCustomerComplete customer={customer} />
            <Tooltip title="Cập nhật khách hàng">
              <Button
                icon={<EditOutlined />}
                type="primary"
                ghost
                onClick={() => handleUpdate(customer)}
              ></Button>
            </Tooltip>
          </div>
        )
      }
    }
  ]

  const createCustomer = async (customerContactInfo) => {
    const response = await createCustomerContactInfo(
      strategyId,
      customerContactInfo
    )
    if (response.success) {
      dispatch(clearAndPushSuccessMessage('Thêm mới thành công'))
      setIsModalCreateOrUpdateOpen(false)
      setToggleData(!toggleData)
    } else {
      dispatch(clearAndPushErrorMessage(response.errorMessage))
    }
  }

  const updateCustomer = async (customerContactInfo) => {
    const response = await updateCustomerContactInfo(customerContactInfo)
    if (response.success) {
      dispatch(clearAndPushSuccessMessage('Cập nhật thành công'))
      setIsModalCreateOrUpdateOpen(false)
      setToggleData(!toggleData)
    } else {
      dispatch(clearAndPushErrorMessage(response.errorMessage))
    }
  }

  const addOrUpdateContactInfo = async (value, isCompleted = false) => {
    const fieldsDefault = contactFields
      .filter((field) => field.isDefault)
      .map((field) => field.code)
    let contactFieldsDefault = {}
    let contactFieldsExtend = {}
    for (const key in value) {
      if (fieldsDefault.includes(key)) {
        contactFieldsDefault = { ...contactFieldsDefault, [key]: value[key] }
      } else {
        contactFieldsExtend = { ...contactFieldsExtend, [key]: value[key] }
      }
    }
    const extendInfo = JSON.stringify(contactFieldsExtend)

    const customerContactInfo = {
      ...contactFieldsDefault,
      ExtendInfo: extendInfo
    }

    if (isUpdate) {
      const customerInfo = {
        ...customerContactInfo,
        id: customerUpdateId,
        isCompleted
      }
      updateCustomer(customerInfo)
    } else {
      createCustomer(customerContactInfo)
    }
  }

  const handleCancel = () => {
    setIsModalCreateOrUpdateOpen(false)
  }

  const message = (
    <ul>
      <li>
        Để đảm bảo hiệu suất hoạt động, CallMaster khuyến nghị chỉ nên có không
        quá 40,000 liên hệ trong một chiến dịch
      </li>
      <li>
        Dữ liệu liên hệ của chiến dịch sẽ tồn tại trên hệ thống trong vòng 3
        tháng (90 ngày) kể từ khi tạo
      </li>
    </ul>
  )

  const confirmDelete = async () => {
    const response = await deleteCustomer(strategyId, customerDelete.id)
    if (response.success) {
      dispatch(clearAndPushSuccessMessage('Xoá thành công'))
      setToggleData(!toggleData)
      setModalDeleteState(false)
    } else {
      dispatch(clearAndPushErrorMessage(response.errorMessage))
      setModalDeleteState(false)
    }
  }

  const confirmComplete = async () => {
    const response = await updateCustomerComplete(
      customerUpdateId,
      newStateCustomerComplete
    )
    if (response.success) {
      dispatch(clearAndPushSuccessMessage('Cập nhật dữ liệu mới thành công'))
      setModalCompleteState(false)
      setToggleData(!toggleData)
    } else {
      dispatch(clearAndPushErrorMessage(response.errorMessage))
    }
  }

  const handleCreate = () => {
    actionName.current = 'Thêm mới'
    form.resetFields()
    setIsUpdate(false)
    setIsModalCreateOrUpdateOpen(true)
  }

  const handlePagingChange = (page, pageSize) => {
    setIsLoading(true)
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }

  const onClickClearCustomer = () => {
    const handler = async () => {
      setIsClearCustomerLoading(true)
      const response = await clearCustomer(strategyId)
      if (!response.success) {
        dispatch(clearAndPushErrorMessage(response.message))
      } else {
        dispatch(clearAndPushSuccessMessage('Xóa toàn bộ khách hàng thành công'))
        setToggleData(!toggleData)
      }
      setIsClearCustomerLoading(false)
    }

    handler()
  }

  return (
    <>
      <Modal
        title="Thông báo!"
        open={modalDeleteState}
        onOk={confirmDelete}
        onCancel={() => setModalDeleteState(false)}
      >
        Bạn có chắc muốn xoá không?
      </Modal>
      <Modal
        title="Thông báo!"
        open={modalCompleteState}
        onOk={confirmComplete}
        onCancel={() => setModalCompleteState(false)}
      >
        Xác nhận {newStateCustomerComplete ? 'đã' : 'chưa'} hoàn thành?
      </Modal>
      <Alert message={message} type="warning" />
      <FilterCustomer
        strategyId={strategyId}
        setCustomerInfos={setCustomerInfos}
        setToggleData={setToggleData}
        toggleData={toggleData}
        setDataFilter={setDataFilter}
        dataFilter={dataFilter}
      />
      <div className="flex justify-end mt-5">
        {/* <Button
          type="primary"
          style={{
            backgroundColor: 'white',
            color: 'black',
            borderColor: '#ccc',
            marginRight: '10px'
          }}
          icon={<DownloadOutlined style={{ color: '#6B66C4' }} />}
        >
          Xuất dữ liệu
        </Button> */}
        <Button
          onClick={() => setMultipleAdd(true)}
          type="primary"
          style={{
            backgroundColor: 'white',
            color: 'black',
            borderColor: '#ccc'
          }}
          icon={<PlusOutlined style={{ color: '#6B66C4' }} />}
        >
          Thêm mới hàng loạt
        </Button>
        {
          !checkIsEmployee() && <Button className='ml-2' icon={<DownloadOutlined style={{ color: '#6B66C4' }} />} onClick={() => {
            const handler = async () => {
              window.location.href = `${process.env.REACT_APP_API_BASEURL}api/Strategies/${strategyId}/ExportCustomer?isComplete=${dataFilter.isComplete}&name=${dataFilter.name || ''}&phoneNumber=${dataFilter.phoneNumber || ''}`
            }

            handler()
          }}>Xuất liên hệ</Button>
        }
        {
          <div>
            {!checkIsEmployee() && <Button className='ml-2' icon={<DeleteOutlined style={{ color: '#6B66C4' }} />} onClick={() => {
              setIsOpenDeleteCustomerByFile(true)
            }}>Xóa bằng excel</Button>}

            <DeleteCustomerByFile isOpen={isOpenDeleteCustomerByFile} handleClosePopup={() => setIsOpenDeleteCustomerByFile(false)} handleRefresh={() => setToggleData(!toggleData)} strategyId={strategyId}/>
          </div>
        }
        <ImportCustomer
          toggleData={toggleData}
          setToggleData={setToggleData}
          strategyId={strategyId}
          multipleAdd={multipleAdd}
          setMultipleAdd={setMultipleAdd}
          contactFields={contactFields}
        />
      </div>
      <div className=" flex justify-end mt-2">
        {
          canClearCustomer() &&
          <Popconfirm
            disabled={!customerInfos || !customerInfos.length}
            title="Bạn có chắc chắn xóa toàn bộ khách hàng?"
            onConfirm={() => onClickClearCustomer()}
          >
            <Button
              className='mr-2'
              type='primary'
              disabled={!customerInfos || !customerInfos.length}
              loading={isClearCustomerLoading}
              style={{
                backgroundColor: 'red',
                border: 'none'
              }}>
              Xóa tất cả
            </Button>
          </Popconfirm>
        }

        <Button
          type="primary"
          onClick={handleCreate}
          style={{
            backgroundColor: '#52c41a',
            border: 'none'
          }}
        >
          Thêm mới
        </Button>
        <Modal
          title={<div>{actionName.current} thông tin liên hệ</div>}
          okText="Lưu lại"
          cancelText="Huỷ"
          open={isModalCreateOrUpdateOpen}
          onOk={() => form.submit()}
          onCancel={handleCancel}
        >
          <Form
            name="basic"
            layout="vertical"
            form={form}
            onFinish={addOrUpdateContactInfo}
            fields={customerUpdateInfo}
          >
            {contactFields.map((item) => (
              <Form.Item
                label={item?.displayName}
                key={item.code}
                name={item.code}
                rules={[
                  item.isDefault && {
                    required: true,
                    message: 'Không được để trống'
                  },
                  item.code === 'phoneNumber' && {
                    pattern: /^[0-9]{8,}$/,
                    message: 'Số điện thoại không hợp lệ'
                  }
                ]}
              >
                <Input type={item.type} />
              </Form.Item>
            ))}
          </Form>
        </Modal>
      </div>
      <div className="mt-4">
        <Table
          scroll={{
            y: 800,
            x: 500
          }}
          columns={columns}
          dataSource={customerInfoExtend}
          pagination={false}
          loading={isLoading}
        ></Table>
      </div>
      <Pagination
        className="text-right mt-8"
        total={countCustomer}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `Tổng ${total} khách hàng`}
        defaultCurrent={currentPage}
        defaultPageSize={currentPageSize}
        pageSize={currentPageSize}
        current={currentPage}
        onChange={handlePagingChange}
      />

      <div className='flex justify-end mt-10'>
        <Button type='primary' onClick={() => {
          history.push('/strategy')
        }}>Hoàn thành</Button>

      </div>

    </>
  )
}

export default ListContactStep
