import React, { useEffect, useRef, useState } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import Pages from './pages'
import { DefaultLayout } from './components/Layout'
import * as signalR from '@microsoft/signalr'
import { getStaticInfo } from './services/common'

const pageItem = Pages.map((item, key) => {
  const Layout = item.Layout || DefaultLayout
  const Page = item.page
  const CardTitle = item.cardTitle

  if (item.path === '/login') {
    return <Route key={key} path={item.path} ><Page /></Route>
  }
  if (CardTitle) {
    return <Route exact={item.exact} key={key} path={item.path} ><Layout cardTitle={<CardTitle />} ><Page /></Layout></Route>
  } else {
    return <Route exact={item.exact} key={key} path={item.path} ><Layout ><Page /></Layout></Route>
  }
})

export default function App () {
  const location = useLocation()
  const connectionHub = useRef()

  useEffect(() => {
    const handler = async () => {
      const { userId, jwtToken } = getStaticInfo()
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_API_BASEURL}usersHub?userId=${userId}`, { accessTokenFactory: () => jwtToken })
        .build()
      if (connection) {
        connectionHub.current = connection
        await connectionHub.current.start()
      }
    }

    handler()

    return async () => {
      await connectionHub?.current.stop()
    }
  }, [location])

  return (
    <Switch>
      {pageItem}
    </Switch>
  )
}
