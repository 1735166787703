import { ANHCAO, CLIENT, EMPLOYEE, MANAGER, SUPERADMIN, TECHNICAL } from '../constants/roles'
import { getStaticInfo } from '../services/common'
import parseJwt from '../utils/parseJwt'

const getRole = () => {
  const { jwtToken } = getStaticInfo()
  const token = parseJwt(jwtToken)
  const role = token['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']

  return role
}

export const canViewCreateStrategyButton = () => {
  // const role = getRole()
  // if (role === SUPERADMIN) return true
  // else return false

  const role = getRole()
  if (Array.isArray(role)) {
    if (role.includes(SUPERADMIN)) return true
  } else {
    if (role === SUPERADMIN) return true
  }
  return false
}

export const canViewSettingStrategyButton = () => {
  const role = getRole()
  if (role === EMPLOYEE) return false
  else return true
}

export const canViewContactListButton = () => {
  // const role = getRole()
  // if (role === EMPLOYEE) return false
  // else return true

  const role = getRole()
  if (Array.isArray(role)) {
    if (role.includes(EMPLOYEE)) return false
  } else {
    if (role === EMPLOYEE) return false
  }
  return true
}

export const canChangeStrategyStatus = () => {
  // const role = getRole()
  // if (role === EMPLOYEE) return false
  // else return true

  const role = getRole()
  if (Array.isArray(role)) {
    if (role.includes(EMPLOYEE)) return false
  } else {
    if (role === EMPLOYEE) return false
  }
  return true
}

export const canClearCustomer = () => {
  // const role = getRole()
  // if (role === SUPERADMIN) return true
  // else return false

  const role = getRole()
  if (Array.isArray(role)) {
    if (role.includes(SUPERADMIN)) return true
  } else {
    if (role === SUPERADMIN) return true
  }
  return false
}

export const canCopyCustomer = () => {
  const role = getRole()
  if (Array.isArray(role)) {
    if (role.includes(SUPERADMIN)) return true
  } else {
    if (role === SUPERADMIN) return true
  }
  return false
}

export const canCreateUser = () => {
  const role = getRole()
  if (Array.isArray(role)) {
    if (role.includes(ANHCAO)) return true
  } else {
    if (role === ANHCAO) return true
  }
  return false
}

export const checkIsTechnical = () => {
  const role = getRole()
  if (Array.isArray(role)) {
    if (role.includes(TECHNICAL)) return true
  } else {
    if (role === TECHNICAL) return true
  }
  return false
}

export const checkIsEmployee = () => {
  const role = getRole()
  if (Array.isArray(role)) {
    if (role.includes(EMPLOYEE)) return true
  } else {
    if (role === EMPLOYEE) return true
  }
  return false
}

export const checkIsSupperAdmin = () => {
  const roles = getRoles()
  if (roles.includes(SUPERADMIN)) return true

  return false
}

export const checkExport = () => {
  const roles = getRoles()
  if (roles.includes(SUPERADMIN) || roles.includes(MANAGER)) return true

  return false
}

export const getRoles = () => {
  const { jwtToken } = getStaticInfo()
  const token = parseJwt(jwtToken)
  const role = token['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']

  if (Array.isArray(role)) {
    return role
  } else {
    return [role]
  }
}

export const checkIsSuperAdminAndManager = () => {
  const roles = getRoles()
  if (roles.includes(SUPERADMIN)) return true

  return false
}

export const checkIsClient = () => {
  const roles = getRoles()
  if (roles.includes(CLIENT)) return true

  return false
}

export const checkIsManagerOrClient = () => {
  const roles = getRoles()
  if (roles.includes(CLIENT) || roles.includes(MANAGER)) return true

  return false
}

export const isOnlyClient = () => {
  const isClient = checkIsClient()
  const isAdminAndManager = checkIsSuperAdminAndManager()

  if (isClient && !isAdminAndManager) return true

  return false
}
