import request from '../utils/request'
import { getStaticInfo } from './common'
import { handleError } from './handleError'

export const getRankingByServiceName = async (dataFilter) => {
  const { headers, userId } = getStaticInfo()
  try {
    dataFilter.userId = userId
    const response = await request.post('/Report/Employee/Ranking', dataFilter, {
      headers
    })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const getRankingForMicrosip = async (dataFilter) => {
  const { headers, userId } = getStaticInfo()
  try {
    dataFilter.userId = userId
    const response = await request.post('/Microsip/Ranking', dataFilter, {
      headers
    })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const getStrategyReport = async (requestFiler) => {
  const { headers } = getStaticInfo()
  try {
    const response = await request.post('Report/Strategy', requestFiler, {
      headers
    })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const getExportCallInfo = async (requestFilter) => {
  const { headers } = getStaticInfo()
  try {
    const response = await request.post('Report/Export/CallHistory', requestFilter,
      { headers })
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const getEntryCallInfo = async (requestFilter) => {
  const { headers } = getStaticInfo()
  try {
    const response = await request.post('Report/Export/EntryCallInfo', requestFilter,
      { headers })
    return response.data
  } catch (error) {
    handleError(error)
  }
}
