import React from 'react'
import StrategyBreadCrumb from '../BreadCrumbs/StrategyBreadCrumb'
import { Button } from 'antd'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCurrent } from '../../slices/currentStepSlice'
import { canViewCreateStrategyButton } from '../../permissions'

function StrategyTitle () {
  const dispatch = useDispatch()
  const handleClickCreate = () => {
    dispatch(setCurrent(0))
  }
  return (
    <div className=' tablet:p-5 pb-0'>
      <div >
        <h2>Danh sách chiến dịch</h2>
        <div>{<StrategyBreadCrumb />}</div>
      </div>
      {
        canViewCreateStrategyButton() &&
        <div className='mb-2 flex justify-end'>
          <Button type='primary' onClick={handleClickCreate} >
            <Link to={{ pathname: '/strategy/strategyDetail' }}>
              <span className="">Tạo mới</span>
            </Link>

          </Button>
        </div>
      }

    </div>
  )
}

export default StrategyTitle
