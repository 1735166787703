import { Input, Select, Modal, Table, Alert, Button, Form, Popconfirm, Tag } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { EditOutlined, CaretDownOutlined, DeleteOutlined } from '@ant-design/icons'
import ActionButton from '../ActionButton'
import { next, prev } from '../../../../slices/currentStepSlice'
import { useDispatch } from 'react-redux'
import {
  createSettingItem,
  deleteSettingItems,
  getSettings,
  updateSettingItem
} from '../../../../services/strategyService'
import { report } from '../../../../constants/settingObjectType'
import { clearAndPushErrorMessage, clearAndPushSuccessMessage } from '../../../../slices/errorMessageSlice'
import { dataType } from '../../../../constants/dataType'
import { callStatuses } from '../../../../constants/callStatuses'
import { toLowerCaseNonAccentVietnamese } from '../../../../utils/nonAccentVietnamese'

function ReportStep ({ strategyId }) {
  const dispatch = useDispatch()
  const { Option } = Select
  const { Search } = Input

  const [currentId, setCurrentId] = useState('')
  const [settingData, setSettingData] = useState([])
  const [settingDataTable, setSettingDataTable] = useState([])
  const [fieldName, setFieldName] = useState('')
  const [fieldCode, setFieldCode] = useState('')
  const [fieldType, setFieldType] = useState('0')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [reloadData, setReloadData] = useState(false)
  const [textSelectValues, setTextSelectValues] = useState([])
  const [currentTextSelectValue, setCurrentTextSelectValue] = useState()
  const [showTextSelectValuesMessage, setShowTextSelectValuesMessage] = useState(false)
  const [showMessageSelectValueError, setShowMessageSelectValueError] = useState(false)
  const [selectRequiredCallStatus, setSelectRequiredCallStatus] = useState([])

  const action = useRef({
    title: 'Thêm mới'
  })
  const [form] = Form.useForm()

  useEffect(() => {
    const handler = async () => {
      const response = await getSettings(strategyId, report.value)
      if (!response.success) {
        dispatch(clearAndPushErrorMessage(response.message))
      }
      const settings = response.contactInfo
      setSettingData(settings)
      const dataSource = settings.map((item, key) => {
        return {
          key,
          fieldName: item.displayName,
          fieldType: dataType[item.fieldType]?.title,
          fieldCode: item.code,
          requiredCallStatus: item.requiredCallStatus,
          selectItems: item.selectItems,
          id: item.id
        }
      })
      setSettingDataTable(dataSource)
    }

    handler()
  }, [reloadData])

  const collumns = [
    {
      title: 'Tên trường dữ liệu',
      dataIndex: 'fieldName'
    },
    {
      title: 'Kiểu nhập liệu',
      dataIndex: 'fieldType'
    },
    {
      title: 'Mã trường',
      dataIndex: 'fieldCode'
    },
    {
      title: 'Bắt buộc nhập nếu có kết quả là',
      width: 200,
      key: 'requiredCallStatus',
      render: (record) => {
        return <div className="flex flex-wrap ">
          {record.requiredCallStatus?.map((item, key) => {
            return (
            <Tag className='mb-2' color='#48BC7F' key={key}>{callStatuses[item]?.title}</Tag>
            )
          })}
        </div>
      }
    },
    {
      title: 'Hành động',
      align: 'center',
      key: 'action',
      render: (record) => {
        if (!record.isDefault) {
          return (
            <div className="flex justify-center" key={record.id}>
              <Button
                className="edit-button"
                size="middle"
                icon={<EditOutlined />}
                onClick={() => handleUpdateOnClick(record.id)}
              />
              <Popconfirm
                title="Bạn có chắc chắn xóa trường này không?"
                onConfirm={() => handleDeleteConfirm(record.id)}
              >
                <Button
                  className="ml-2 delete-button"
                  size="middle"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </div>
          )
        }
      }
    }
  ]

  const nextAction = () => {
    dispatch(next())
  }

  const prevAction = () => {
    dispatch(prev())
  }
  const message = (
    <ul>
      <li><b>Thông tin báo cáo</b> là những dữ liệu mà tổng đài viên cần nhập sau khi kết thúc một cuộc gọi kết nối thành công</li>
      <li>Bạn có thể thêm các trường thông tin khác tuỳ theo nhu cầu và nội dung của chiến dịch</li>
      <li><b>Các trường và các mã tương ứng không được phép chứa đoạn mã _custom</b></li>
    </ul>
  )

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const clearData = () => {
    setFieldCode('')
    setFieldName('')
    setFieldType('')
    setSelectRequiredCallStatus([])
    setTextSelectValues([])
    setCurrentTextSelectValue('')
    setShowMessageSelectValueError(false)
    setShowTextSelectValuesMessage(false)
  }

  const setFormData = (data) => {
    setFieldName(data.displayName)
    setFieldCode(data.code)
    setFieldType(data.fieldType?.toString())
    setSelectRequiredCallStatus(data.requiredCallStatus?.map(item => item.toString()))
    setTextSelectValues(data.selectItems?.map(item => item.toString()))
  }

  const buildEntity = () => {
    const selectValues = textSelectValues.map(item => {
      const key = toLowerCaseNonAccentVietnamese(item)
      return {
        Key: key,
        Value: item
      }
    })

    const entity = {
      displayName: fieldName,
      code: fieldCode,
      fieldType,
      requiredCallStatus: selectRequiredCallStatus,
      objectType: report.value,
      strategyId,
      isDefault: false,
      selectItems: textSelectValues,
      selectValues
    }

    return entity
  }

  const onAfterCallAPIUpdateData = (response) => {
    if (!response.success) {
      dispatch(clearAndPushErrorMessage(response.message))
    } else {
      dispatch(clearAndPushSuccessMessage(`${action.current.title} dữ liệu thành công`))
      setReloadData(!reloadData)
      clearData()
      setIsModalOpen(false)
      action.current = {}
    }
  }

  const handleDeleteConfirm = (id) => {
    const handler = async () => {
      const response = await deleteSettingItems([id])
      if (!response.success) {
        dispatch(clearAndPushErrorMessage(response.message))
      } else {
        dispatch(clearAndPushSuccessMessage('Đã xóa thành công'))
        setReloadData(!reloadData)
      }
    }

    handler()
  }

  const validateForm = () => {
    if (!fieldName || !fieldCode) return false
    if (fieldType === '4' || fieldType === '5') {
      if (!textSelectValues?.length) {
        setShowMessageSelectValueError(true)
        return false
      }
    }

    return true
  }

  const handleUpdateOnClick = (id) => {
    action.current.title = 'Cập nhật'
    action.current.isUpdate = true
    action.current.isDisableFieldCode = true
    const record = settingData.find(item => item.id === id)
    setCurrentId(id)
    if (record) {
      setFormData(record)
    } else {
      dispatch(clearAndPushErrorMessage('Có lỗi xẩy ra'))
    }

    setIsModalOpen(true)
  }

  const handleUpdate = async () => {
    const isValid = validateForm()
    if (isValid) {
      const entity = buildEntity()
      entity.id = currentId
      const response = await updateSettingItem([entity])
      onAfterCallAPIUpdateData(response)
    }
  }

  const handleCreateOnClick = () => {
    action.current.isCreate = true
    action.current.title = 'Thêm mới'
    action.current.isDisableFieldCode = false
    clearData()
    setIsModalOpen(true)
  }

  const handleCreate = async () => {
    const isValid = validateForm()
    if (isValid) {
      const entity = buildEntity()
      const response = await createSettingItem([entity])
      onAfterCallAPIUpdateData(response)
    }
  }

  const handleAddExtendText = (value) => {
    if (!value) {
      setShowTextSelectValuesMessage(true)
    } else {
      if (!textSelectValues?.length) setTextSelectValues([value])
      else setTextSelectValues([...textSelectValues, value])
      setCurrentTextSelectValue('')
      setShowTextSelectValuesMessage(false)
    }
  }

  const handleRemoveExtendText = (value) => {
    const newList = textSelectValues.filter(item => item !== value)
    setTextSelectValues(newList)
  }

  return (
    <>
      <Alert message={message} type="info" style={{ borderRadius: '5px' }} />
      <div className='flex justify-end my-5'>
        <Button
          type="primary"
          onClick={() => {
            handleCreateOnClick()
          }}
          style={{ backgroundColor: '#48BC7F', border: 'none' }}
        >
          Thêm mới
        </Button>
      </div>
      <Table
        className='mt-4'
        columns={collumns}
        dataSource={settingDataTable}
        scroll={{
          y: 1200,
          x: 800
        }}
      >
      </Table>
      <Modal
        title={<div>{action.current.title} thông tin báo cáo</div>}
        okText="Lưu lại"
        cancelText="Huỷ"
        open={isModalOpen}
        onOk={() => {
          if (action.current.isCreate) {
            handleCreate()
          } else if (action.current.isUpdate) {
            handleUpdate()
          }
        }}
        onCancel={handleCancel}
      >

        <div>
          <div className='mb-4'>
            <label htmlFor="fieldName">Tên trường dữ liệu</label>
            <Input
              id='fieldName'
              value={fieldName}
              placeholder='Nhập tên trường dữ liệu'
              autoComplete='off'
              onChange={(e) => {
                if (!action.current.isDisableFieldCode) {
                  const code = toLowerCaseNonAccentVietnamese(e.target.value)
                  setFieldCode(code)
                }
                setFieldName(e.target.value)
              }}>
            </Input>
            {
              !fieldName &&
              <p className='text-red-600'>Đây là trường bắt buộc</p>
            }
            {
              (fieldName && !(/^((?!(_custom)).)*$/).test(fieldName)) && <p className='text-red-600'>Tên trường không được chứa &quot;_custom&quot;</p>
            }

          </div>
          <div className='mb-4'>
            <label htmlFor="fieldCode">Mã</label>
            <Input
              id='fieldCode'
              disabled={action.current.isDisableFieldCode}
              value={fieldCode}
              onChange={(e) => {
                const code = toLowerCaseNonAccentVietnamese(e.target.value)
                setFieldCode(code)
              }}
              placeholder='Nhập mã'>
            </Input>
            {
              !fieldCode &&
              <p className='text-red-600'>Đây là trường bắt buộc</p>
            }
            {
              (fieldCode && !(/^((?!(_custom)).)*$/).test(fieldCode)) && <p className='text-red-600'>Mã không được chứa &quot;_custom&quot;</p>
            }
          </div>
          <div className='mb-4'>
            <label htmlFor="fieldType">Kiểu nhập dữ liệu</label>
            <Select
              id="fieldType"
              suffixIcon={<CaretDownOutlined />}
              style={{ width: '100%' }}
              value={fieldType}
              onChange={(value) => {
                setFieldType(value)
              }}
            >
              {dataType.map((item) => (
                <Option key={item.value}>{item.title}</Option>
              ))}
            </Select>
          </div>

          {
            (fieldType === '4' || fieldType === '5') &&
            <div className='mb-4'>
              <div className='mb-1'>
                <label htmlFor="extendValue">Danh sách lựa chọn</label>
                <Search
                  id='extendValue' placeholder='Nhập một giá trị để thêm mới' enterButton="Thêm mới"
                  value={currentTextSelectValue}
                  onChange={(e) => setCurrentTextSelectValue(e.target.value)}
                  onSearch={(value) => handleAddExtendText(value)}></Search>
              </div>
              {
                showTextSelectValuesMessage &&
                <p className='text-red-600'>Bắt buộc nhập</p>
              }
              <div>
                {
                  textSelectValues &&
                  textSelectValues.map((item, index) => {
                    return (
                      <div key={index} className='mb-1 w-full p-2 flex justify-between items-center border border-solid border-[#d9d9d9]'>
                        <div>{item}</div>
                        <Button onClick={() => handleRemoveExtendText(item)} type="primary" shape="circle" icon={<DeleteOutlined />} />
                      </div>
                    )
                  })
                }
              </div>
              {
                showMessageSelectValueError &&
                <p className='text-red-600'>Danh sách lựa chọn phải có giá trị</p>
              }
            </div>
          }

          <div className='mb-4'>
            <label htmlFor="requiredCallStatus">Bắt buộc nhật nếu có kết quả là</label>
            <Select
              id="requiredCallStatus"
              mode="multiple"
              suffixIcon={<CaretDownOutlined />}
              style={{ width: '100%' }}
              value={selectRequiredCallStatus}
              onChange={(value) => {
                setSelectRequiredCallStatus(value)
              }}
            >
              {callStatuses.map((item) => (
                <Option key={item.value}>{item.title}</Option>
              ))}
            </Select>
          </div>
        </div>

      </Modal>
      <ActionButton next={nextAction} prev={prevAction} />
    </>
  )
}

export default ReportStep
