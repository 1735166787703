import request from '../utils/request'
import { handleError } from './handleError'

export const getGroups = async (status) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.get('Groups', {
      params: { status },
      headers: {
        'x-access-site': siteId,
        Authorization: 'Bearer ' + jwtToken
      }
    })
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const createGroups = async (group) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.post(
      'Groups',
      {
        name: group.name,
        description: group.description,
        isActive: group.isActive,
        userId: userInfo.id
      },
      {
        headers: {
          'x-access-site': siteId,
          Authorization: 'Bearer ' + jwtToken
        }
      }
    )
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const updateGroups = async (group) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.put(
      'Groups',
      {
        id: group.idGroupUpdate,
        name: group.name,
        description: group.description,
        isActive: group.isActive
      },
      {
        headers: {
          'x-access-site': siteId,
          Authorization: 'Bearer ' + jwtToken
        }
      }
    )
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const deleteMultipleGroups = async (ids) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.delete('Groups', {
      headers: {
        'x-access-site': siteId,
        Authorization: 'Bearer ' + jwtToken
      },
      data: ids
    })
    return response.data
  } catch (error) {
    handleError(error)
  }
}
