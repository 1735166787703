import React from 'react'

function Logo () {
  return (
    <div className="flex items-center border-r border-gray-400 pr-2">
      <img src="https://flowbite.com/docs/images/logo.svg" className="mr-3 h-6 sm:h-9" alt="Flowbite Logo" />
      <span className="self-center text-xl font-semibold whitespace-nowrap text-white dark:text-white">CallMaster</span>
    </div>
  )
}

export default Logo
