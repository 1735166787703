export const IsOrderByDescending = [
  {
    title: 'Mới đến cũ',
    value: true
  },
  {
    title: 'Cũ đến mới',
    value: false
  }
]
