import React from 'react'
import Ranking from './components/Ranking'
import { Tabs } from 'antd'
import MicrosipRanking from './components/MicrosipRanking'
import { checkIsSupperAdmin } from '../../permissions'
import { getStaticInfo } from '../../services/common'

function Report () {
  const staticInfo = getStaticInfo()
  const tabItems = []

  const callSource = staticInfo.callSource ?? 0

  if (callSource === 0) {
    tabItems.push({
      label: 'CallMaster',
      key: 1,
      children: <Ranking />
    })
  }

  if (checkIsSupperAdmin() || callSource === 1) {
    tabItems.push({
      label: 'Microsip',
      key: 2,
      children: <MicrosipRanking />
    })
  }

  return (
    <div>
      <Tabs
        type="card"
        items={tabItems}
      />

    </div>
  )
}
export default Report
