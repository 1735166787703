import React from 'react'
import { Breadcrumb } from 'antd'
import { RightOutlined } from '@ant-design/icons'

function ReportBreadCrumb () {
  return (
    <Breadcrumb separator={<RightOutlined />}>
      <Breadcrumb.Item href='/'>Home</Breadcrumb.Item>
      <Breadcrumb.Item href="">Báo cáo</Breadcrumb.Item>
    </Breadcrumb>
  )
}

export default ReportBreadCrumb
