import React from 'react'
import '../../../../User/User.css'
import { Button, Select, Input } from 'antd'
import { statusFilterCustomer } from '../../../../../constants/statusFilter'
import { CaretDownOutlined } from '@ant-design/icons'
function FilterCustomers ({
  setToggleData,
  toggleData,
  setDataFilter,
  dataFilter
}) {
  const STATUS_DEFAULT = -1
  const { Option } = Select

  const handleChangeCustomerName = (e) => {
    setDataFilter({ ...dataFilter, name: e.target.value })
  }

  const handleChangePhoneNumber = (e) => {
    setDataFilter({ ...dataFilter, phoneNumber: e.target.value })
  }

  const handleChangeStatus = (value) => {
    setDataFilter({ ...dataFilter, isComplete: value })
  }

  const handleFilterCustomer = async () => {
    setDataFilter({
      ...dataFilter
    })

    setToggleData(!toggleData)
  }

  const refresh = () => {
    setDataFilter({ isComplete: STATUS_DEFAULT })

    setToggleData(!toggleData)
  }

  return (
    <div className="mb-5 mt-5 filter-employees flex flex-col items-center  tablet:flex-row  tablet:flex-wrap tablet:justify-between">
      <div className="mb-[10px] mt-[10px] w-full tablet:m-0 tablet:w-[27%]">
        <label htmlFor="customerName">Tên khách hàng</label>
        <Input
          id="customerName"
          placeholder="Nhập tên khách hàng"
          value={dataFilter?.name}
          onChange={handleChangeCustomerName}
          className=""
        />
      </div>
      <div className="mb-[10px] tablet:m-0 w-full tablet:w-[27%]">
        <label htmlFor="phoneNumber">Số điện thoại</label>
        <Input
          id="phoneNumber"
          placeholder="Nhập số điện thoại"
          value={dataFilter?.phoneNumber}
          onChange={handleChangePhoneNumber}
        />
      </div>

      <div className="tablet:w-[27%] w-full">
        <label htmlFor="state">Trạng thái</label>
        <Select
          id="state"
          onChange={handleChangeStatus}
          className="w-full"
          value={dataFilter?.isComplete}
          suffixIcon={<CaretDownOutlined />}
        >
          {statusFilterCustomer.map((item) => (
            <Option value={item.isActive} key={item.isActive}>
              {item.title}
            </Option>
          ))}
        </Select>
      </div>
      <div className="tablet:w-max">
        <label>&nbsp;</label>
        <div className="flex">
          <Button type="primary" className="" onClick={handleFilterCustomer}>
            Tìm kiếm
          </Button>
          <Button type="primary" className="ml-[12px]" onClick={refresh}>
            Làm mới
          </Button>
        </div>
      </div>
    </div>
  )
}

export default FilterCustomers
