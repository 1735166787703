import React, { useState, useEffect } from 'react'
import { Button, message, Steps, Tooltip } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { getCurrentStep } from '../../../slices/currentStepSlice'

function ActionButton ({ prev, next, isDisable = false }) {
  const stepLength = 5
  const currentStep = useSelector(getCurrentStep)

  return (
    <div className="steps-action mt-6 text-right">
        {currentStep > 0 && (
          <Button
            style={{
              margin: '0 8px'
            }}
            onClick={() => prev()}
          >
            Quay lại
          </Button>
        )}
      {currentStep < stepLength - 1 && (
        <Tooltip title={isDisable && 'Vui lòng nhập đầy đủ thông tin mới có thể tiếp tục'}>
          <Button type="primary" onClick={() => next()} disabled={isDisable}>
            Lưu và tiếp tục
          </Button>
      </Tooltip>
      )}
        {currentStep === stepLength - 1 && (
          <Button type="primary" onClick={() => message.success('Processing complete!')}>
            Hoàn toàn
          </Button>
        )}

      </div>
  )
}

export default ActionButton
