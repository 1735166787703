import { getLinkRecordCallio, getLinkRecordDiginext } from '../services/callInfoService'

const getLinkRecord = async (siteInfo, callId) => {
  let url = ''
  switch (siteInfo.syncServiceName) {
    case 'CallioService':
    {
      const response = await getLinkRecordCallio(callId, siteInfo?.apiKey)
      url = response.url
      break
    }
    case 'DininextService':
    {
      const response = await getLinkRecordDiginext(callId, siteInfo?.apiKey)
      console.log('response', response)
      url = response.data
      break
    }
    default:
      break
  }

  return url
}

export { getLinkRecord }
