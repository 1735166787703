import React from 'react'
import { CallStrategyBreadCrumb } from '../BreadCrumbs'
function CallStrategyTitle () {
  return (
    <div className='tablet:p-5'>
        <h2>Chiến dịch gọi</h2>
        <div>{<CallStrategyBreadCrumb/>}</div>
    </div>
  )
}

export default CallStrategyTitle
