import React from 'react'
import HistoryBreadCrumb from '../BreadCrumbs/HistoryBreadCrumb'
function HistoryTitle () {
  return (
    <div className='p-5'>
        <h2>Lịch sử cuộc gọi</h2>
        <div>{<HistoryBreadCrumb/>}</div>
    </div>
  )
}

export default HistoryTitle
