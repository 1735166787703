import React, { useEffect, useState, useRef } from 'react'
import {
  Card,
  Table,
  Button,
  Modal,
  Form,
  Input,
  Popconfirm,
  Select,
  Badge,
  Tag
} from 'antd'
import {
  LockOutlined,
  EditOutlined,
  KeyOutlined,
  SearchOutlined,
  CaretDownOutlined,
  UnlockOutlined
} from '@ant-design/icons'
import {
  adminChangePassword,
  filterEmployees,
  getAllGroup,
  getAllRole,
  lockoutUser,
  unlockoutUser
} from '../../../services/userInfoService'
import EmployeeUpdate from './EmployeeActions/EmployeeUpdate'
import { statusFilterUserAccount } from '../../../constants/statusFilter'
import { useDispatch } from 'react-redux'
import {
  clearAndPushErrorMessage,
  clearAndPushSuccessMessage
} from '../../../slices/errorMessageSlice'
import { useMediaQuery } from 'react-responsive'
import screenSize from '../../../constants/screenSize'
import EmployeeDistribution from './EmployeeActions/EmployeeDistribution'
import { canCreateUser } from '../../../permissions'

function Employees ({ tabIndex, setTabIndex }) {
  const isTabletMiniOrMobile = useMediaQuery({ query: screenSize.TABLETMINI }) // tablet mini
  const dispatch = useDispatch()
  const [employeeData, setEmployeeData] = useState([])
  const [openChangePassword, setOpenChangePassword] = useState(false)
  const [groupInfoDefault, setGroupInfoDefault] = useState()
  const [formUpdateOrCreateUserState, setFormUpdateOrCreateUserState] =
    useState(false)
  const [reloadDistributeData, setReloadDistributeData] = useState(false)
  const [updatedState, setUpdatedState] = useState(false)
  const [titleButton, setTitleButton] = useState()
  const [selectedEmployee, setSelectedEmployee] = useState({
    id: '',
    email: ''
  })
  const [modalDistributionOpen, setModalDistributionOpen] = useState(false)
  const [toggleFetchData, setToggleFetchData] = useState([])
  const [groupInfos, setGroupInfos] = useState([])
  const [employeeIdUpdate, setEmployeeIdUpdate] = useState()
  const [roleInfos, setRoleInfos] = useState([])
  const STATUS_DEFAULT = 1
  const [dataFilter, setDataFilter] = useState({
    status: STATUS_DEFAULT
  })
  const { Option } = Select
  const [form] = Form.useForm()

  const handleFilter = async (dataSearch) => {
    dataSearch.roleId =
      dataSearch?.roleId === STATUS_DEFAULT ? null : dataSearch?.roleId
    dataSearch.groupId =
      dataSearch?.groupId === STATUS_DEFAULT ? null : dataSearch?.groupId
    const response = await filterEmployees(dataSearch)
    const employees = response.results?.map((item) => {
      item.key = item.id

      return item
    })
    setEmployeeData(employees)
  }
  const getGroupsActive = async () => {
    const response = await getAllGroup()
    if (response.success) {
      const groups = response.results
      setGroupInfos(groups)
      const groupDefault = groups.find(x => x.isDefault)
      setGroupInfoDefault(groupDefault)
    } else {
      dispatch(clearAndPushErrorMessage(response.message))
    }
  }

  const getRoles = async () => {
    const response = await getAllRole()
    if (response.success) {
      setRoleInfos(response.results)
    } else {
      dispatch(clearAndPushErrorMessage(response.message))
    }
  }

  useEffect(() => {
    const handler = async () => {
      await getGroupsActive()
      await getRoles()
    }

    handler()
  }, [])

  useEffect(() => {
    handleFilter(dataFilter)
  }, [updatedState])

  const confirmLogoutUser = (userId) => {
    const handler = async () => {
      const response = await lockoutUser(userId)
      if (!response.success) {
        dispatch(clearAndPushErrorMessage(response.message))
      } else {
        setReloadDistributeData(!reloadDistributeData)
        dispatch(clearAndPushSuccessMessage('Khóa tài khoản người dùng thành công'))
        setUpdatedState(!updatedState)
      }
    }

    handler()
  }

  const confirmUnlock = (userId) => {
    const handler = async () => {
      const response = await unlockoutUser(userId)
      if (!response.success) {
        dispatch(clearAndPushErrorMessage(response.message))
      } else {
        setReloadDistributeData(!reloadDistributeData)
        dispatch(clearAndPushSuccessMessage('Mở khóa tài khoản người dùng thành công'))
        setUpdatedState(!updatedState)
      }
    }

    handler()
  }

  const handleChangePassword = (value) => {
    const handleFilter = async () => {
      const response = await adminChangePassword({
        id: selectedEmployee.id,
        password: value.password
      })
      if (response.success) {
        dispatch(clearAndPushSuccessMessage('Thay đổi mật khẩu thành công'))
      } else {
        dispatch(clearAndPushErrorMessage(response.errorMessage))
      }

      setOpenChangePassword(false)
    }

    handleFilter()
  }

  const handleSelectStatus = (value) => {
    setDataFilter({ ...dataFilter, status: value })
  }

  const handleSelectRole = (value) => {
    setDataFilter({ ...dataFilter, roleId: value })
  }

  const handleSelectGroup = (value) => {
    setDataFilter({ ...dataFilter, groupId: value })
  }

  const handleNameFilter = (value) => {
    setDataFilter({ ...dataFilter, nameFilter: value.target.value })
  }

  const handleUpdateUser = (user) => {
    setTitleButton('Cập nhật')
    setEmployeeIdUpdate(user.id)
    setFormUpdateOrCreateUserState(true)
  }

  const handleRefresh = () => {
    const dataDefault = { status: STATUS_DEFAULT }
    setDataFilter(dataDefault)
    handleFilter(dataDefault)
  }

  const handleCreate = () => {
    setTitleButton('Thêm mới')
    setEmployeeIdUpdate()
    setFormUpdateOrCreateUserState(true)
  }

  const columns = [
    {
      title: 'Online',
      key: 'isOnline',
      render: (value) => {
        return (
          <div>
            <Tag color={value.isOnline ? 'green' : 'red'} className="pr-2">
              {value.isOnline ? 'Đang online' : 'Đã offline'}
            </Tag>
          </div>
        )
      }
    },
    {
      title: 'Tên người dùng',
      width: isTabletMiniOrMobile && 200,
      key: 'statusUser',
      render: (value) => {
        return (
          <div>
            <span>{value.name}</span>
          </div>
        )
      }
    },
    {
      title: 'Line',
      width: isTabletMiniOrMobile && 300,
      dataIndex: 'linePhone',
      key: 'linePhone'
    },
    {
      title: 'Email',
      width: isTabletMiniOrMobile && 300,
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    },
    {
      title: 'Quyền',
      dataIndex: 'roleInfo',
      key: 'roleInfo',
      render: (value) => {
        if (value?.id) {
          return (
            <Tag key={value?.id} color="red">
              {value?.title}
            </Tag>
          )
        }
      }
    },
    {
      title: 'Nhóm người dùng',
      dataIndex: 'groupInfos',
      key: 'groupInfos',
      render: (value) =>
        value?.map((item) => (
          <Tag color="blue" key={item.id}>
            {item.name}
          </Tag>
        ))
    },
    {
      title: 'Trạng thái',
      key: 'statusUser',
      render: (value) => {
        return (
          <div>
            <Tag color={value.isActive ? 'green' : 'red'} className="pr-2">
              {value.isActive ? 'Đang hoạt động' : 'Đã dừng hoạt động'}
            </Tag>
          </div>
        )
      }
    },
    {
      title: 'Hành động',
      width: isTabletMiniOrMobile && 200,
      align: 'center',
      key: 'action',
      render: (value) => {
        return (
          <div className="flex justify-center ">
            <Button
              className="edit-button"
              size="middle"
              icon={<KeyOutlined />}
              onClick={() => {
                setSelectedEmployee(value)
                setOpenChangePassword(true)
              }}
            />
            {
              value.isActive

                ? <Popconfirm
                  title="Bạn có chắc chắn muốn dừng hoạt động tài khoản này không?"
                  onConfirm={() => confirmLogoutUser(value.id)}
                  okText="Xác nhận"
                  cancelText="Hủy"
                >
                  <Button
                    className="ml-5 delete-button"
                    size="middle"
                    icon={<LockOutlined />}
                  />
                </Popconfirm>

                : <Popconfirm
                  title="Bạn có chắc chắn muốn mở khóa tài khoản này không?"
                  onConfirm={() => confirmUnlock(value.id)}
                  okText="Xác nhận"
                  cancelText="Hủy"
                >
                  <Button
                    className="ml-5 delete-button"
                    size="middle"
                    icon={<UnlockOutlined />}
                  />
                </Popconfirm>
            }
            <Button
              className="ml-5 edit-button"
              size="middle"
              icon={<EditOutlined />}
              onClick={() => {
                handleUpdateUser(value)
                setToggleFetchData(!toggleFetchData)
              }}
            />
          </div>
        )
      }
    }
  ]

  const handleShowModalContribution = () => {
    setModalDistributionOpen(true)
  }

  return (
    <div className="employee-screen">
      <EmployeeDistribution
        modalDistributionOpen={modalDistributionOpen}
        setModalDistributionOpen={setModalDistributionOpen}
        reloadData={reloadDistributeData}
      />
      <EmployeeUpdate
        groupInfoDefaultId={groupInfoDefault?.id}
        toggleFetchData={toggleFetchData}
        employeeId={employeeIdUpdate}
        groupInfos={groupInfos}
        titleButton={titleButton}
        roleInfos={roleInfos}
        formUpdateOrCreateUserState={formUpdateOrCreateUserState}
        setFormUpdateOrCreateUserState={setFormUpdateOrCreateUserState}
        setUpdatedState={setUpdatedState}
        updatedState={updatedState}
      />
      <Card
        size='small'
        title={
          <div className="flex flex-wrap tablet:justify-between card-header">
            <h3>Danh sách người dùng</h3>
            <div className="flex  flex-1 justify-end mb-2">
              <Button type="primary" className="mr-3" onClick={handleShowModalContribution}>
                Phân bổ
              </Button>
              {/* {canCreateUser()
                ? <Button
                  type="primary"
                  className="bg-[#52c41a] border-0"
                  onClick={() => {
                    handleCreate()
                    setToggleFetchData(!toggleFetchData)
                  }}
                >
                  Thêm mới
                </Button>
                : <></>} */}
            </div>
          </div>
        }
      >
        <div className="mb-5 mt-5 filter-employees flex flex-col items-center  tablet:flex-row  tablet:flex-wrap tablet:justify-between">
          <div className="mb-[10px] mt-[10px] w-full tablet:m-0 tablet:w-[20%]">
            <label htmlFor="word-search">Từ khóa</label>
            <Input
              className="w-full"
              placeholder="Từ khoá ..."
              value={dataFilter?.nameFilter}
              id="word-search"
              prefix={<SearchOutlined />}
              onChange={handleNameFilter}
            />
          </div>
          <div className="mb-[10px] tablet:m-0 w-full tablet:w-[20%]">
            <label htmlFor="status">Tình trạng</label>
            <Select
              className="w-full"
              placeholder="Tình trạng"
              onChange={handleSelectStatus}
              id="status"
              value={dataFilter?.status}
              suffixIcon={<CaretDownOutlined />}
            >
              {statusFilterUserAccount.map((item) => (
                <Option value={item.isActive} key={item.isActive}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </div>
          <div className="mb-[10px] tablet:m-0 w-full tablet:w-[20%]">
            <label htmlFor="role">Quyền</label>
            <Select
              className="w-full"
              placeholder="Tất cả quyền"
              id="role"
              value={dataFilter?.roleId}
              onChange={handleSelectRole}
              suffixIcon={<CaretDownOutlined />}
            >
              <Option value={STATUS_DEFAULT}>Tất cả quyền</Option>
              {roleInfos?.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </div>
          <div className="mb-[10px] tablet:m-0 w-full tablet:w-[20%]">
            <label htmlFor="group">Nhóm</label>
            <Select
              placeholder="Tất cả nhóm"
              id="group"
              className="w-full"
              suffixIcon={<CaretDownOutlined />}
              value={dataFilter?.groupId}
              onChange={handleSelectGroup}
            >
              <Option value={STATUS_DEFAULT}>Tất cả nhóm</Option>
              {groupInfos?.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>

          <div className="w-max">
            <label>&nbsp;</label>
            <div className="">
              <Button type="primary" onClick={() => handleFilter(dataFilter)}>
                Tìm kiếm
              </Button>
              <Button
                type="primary"
                className="ml-[12px]"
                onClick={handleRefresh}
              >
                Làm mới
              </Button>
            </div>
          </div>
        </div>
        <Table
          scroll={{
            y: 700,
            x: 1200
          }}
          dataSource={employeeData}
          columns={columns}
        />
      </Card>

      <Modal
        title="Đổi mật khẩu!"
        forceRender
        open={openChangePassword}
        footer={null}
        onCancel={() => setOpenChangePassword(false)}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 18 }}
          onFinish={handleChangePassword}
          fields={[
            {
              name: ['email'],
              value: selectedEmployee?.email
            },
            {
              name: ['password'],
              value: ''
            }
          ]}
        >
          <Form.Item name="email" label="Email">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item
            name="password"
            label="Mật khẩu mới"
            rules={[
              {
                required: true,
                message: 'Không được để trống',
                min: 8
              }
              // {
              //   pattern:
              //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              //   message: 'Mật khẩu không hợp lệ'
              // }
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item className="flex justify-end">
            <Button
              danger
              className="mr-5"
              onClick={() => {
                form.resetFields()
                setOpenChangePassword(false)
              }}
            >
              Huỷ
            </Button>
            <Button type="primary" htmlType="submit">
              Lưu lại
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default Employees
