import request from '../utils/request'
import { getStaticInfo } from './common'
import { handleError } from './handleError'

export const getAllStrategy = async (status) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.get(`Strategies?status=${status}`, {
      headers: {
        'x-access-site': siteId,
        Authorization: 'Bearer ' + jwtToken
      }
    })
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const getStrategyByUserId = async (userId, params) => {
  const { headers } = getStaticInfo()
  try {
    const response = await request.get(`/Strategies/Employee/${userId}`, {
      params,
      headers
    })
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const updateStrategy = async (id, strategyInfo) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.put(
      `Strategies/${id}`, strategyInfo,
      {
        headers: {
          'x-access-site': siteId,
          Authorization: 'Bearer ' + jwtToken
        }
      }
    )

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const getStrategyById = async (id) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.get(`Strategies/${id}`, {
      headers: {
        'x-access-site': siteId,
        Authorization: 'Bearer ' + jwtToken
      }
    })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const createStrategy = async (name, description, strategyType) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const userId = userInfo?.id
    if (userId) {
      const response = await request.post(
        'Strategies',
        {
          Name: name,
          Description: description,
          CreatedBy: userId,
          StrategyType: strategyType
        },
        {
          headers: {
            'x-access-site': siteId,
            Authorization: 'Bearer ' + jwtToken
          }
        }
      )
      return response.data
    }
  } catch (error) {
    handleError(error)
  }
}

export const getAssignmentData = async (strategyId) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.get(`Strategies/AssignmentData/${strategyId}`, {
      headers: {
        'x-access-site': siteId,
        Authorization: 'Bearer ' + jwtToken
      }
    })

    return response.data
  } catch (error) {
    handleError(error)
  }
}
export const getAssignmentStrategyById = async (strategyId) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.get(`Strategies/AssignmentData/${strategyId}`, {
      headers: {
        'x-access-site': siteId,
        Authorization: 'Bearer ' + jwtToken
      }
    })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const updateAssignmentForStrategy = async (strategyInfo, employeeIds) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const postData = {
      StrategyInfo: strategyInfo,
      EmployeeIds: employeeIds
    }
    const response = await request.put('Strategies/AssignmentData', postData, {
      headers: {
        'x-access-site': siteId,
        Authorization: 'Bearer ' + jwtToken
      }
    })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const getExtendInfo = async (strategyId) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.get(`Strategies/${strategyId}/ExtendInfo`, {
      headers: {
        'x-access-site': siteId,
        Authorization: 'Bearer ' + jwtToken
      }
    })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const getSettings = async (strategyId, objectType) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.get(`Strategies/${strategyId}/Settings?objectType=${objectType}`, {
      headers: {
        'x-access-site': siteId,
        Authorization: 'Bearer ' + jwtToken
      }
    })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const updateContactInfo = async (strategyId, extendFields) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.put(`Strategies/${strategyId}/ContactInfo`, extendFields, {
      headers: {
        'x-access-site': siteId,
        Authorization: 'Bearer ' + jwtToken
      }
    })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const createExtendField = async (strategyId, extendFields) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.post(`Strategies/${strategyId}/ExtendField`, extendFields, {
      headers: {
        'x-access-site': siteId,
        Authorization: 'Bearer ' + jwtToken
      }
    })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const searchCustomers = async (strategyId, dataFilter) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.get(`Strategies/${strategyId}/Customers`, {
      params: dataFilter,
      headers: {
        'x-access-site': siteId,
        Authorization: 'Bearer ' + jwtToken
      }
    })
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const getStrategyCallHistory = async (dataFilter) => {
  const { headers } = getStaticInfo()
  try {
    const response = await request.post('v2/CallInfoV2/History', dataFilter,
      { headers })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const updateActiveStatus = async (strategyId, isActive) => {
  const { headers } = getStaticInfo()
  try {
    const response = await request.put(`Strategies/${strategyId}/Status?isActive=${isActive}`, {},
      { headers })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const createSettingItem = async (entities) => {
  const { headers } = getStaticInfo()
  try {
    const response = await request.post('Strategies/Settings', entities,
      { headers })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const updateSettingItem = async (entities) => {
  const { headers } = getStaticInfo()
  try {
    const response = await request.put('Strategies/Settings', entities,
      { headers })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const deleteSettingItems = async (ids) => {
  const { headers } = getStaticInfo()

  try {
    const response = await request.delete('Strategies/Settings', {
      headers,
      data: ids
    })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const clearCustomer = async (id) => {
  const { headers } = getStaticInfo()

  try {
    const response = await request.delete(`Strategies/${id}/Customers/Clear`, {
      headers
    })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const copyStrategy = async (id) => {
  const { headers } = getStaticInfo()
  try {
    const response = await request.put(`Strategies/${id}/Clone`, {}, { headers })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const deleteStrategies = async (ids) => {
  const { headers } = getStaticInfo()

  try {
    const response = await request.delete(
      'Strategies',
      {
        headers,
        data: { ids }
      }
    )

    return response.data
  } catch (error) {
    handleError(error)
  }
}
