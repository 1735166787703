import { MailOutlined, ManOutlined, NumberOutlined, PhoneOutlined, RightOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import React from 'react'

export default function CustomerInfo ({ customerInfo, customerInfoExtend, customerStrategySettings, isLoadingCustomer }) {
  const renderCustomerCallInfo = () => {
    const htmlItems = []
    for (const [key, value] of Object.entries(customerInfoExtend)) {
      const fieldInfos = customerStrategySettings?.filter((field) => field.code === key)
      if (fieldInfos && fieldInfos.length) {
        const fieldInfo = fieldInfos[0]
        const displayName = fieldInfo.displayName
        htmlItems.push(
          <div className="" key={key}>
            <div>
              <RightOutlined className="w-[5%]" />
              <span className="">{displayName + ':'}</span> {value}
            </div>
          </div>
        )
      }
    }

    return <div>{htmlItems.map((item) => item)}</div>
  }

  return (
    <div>
      {
        isLoadingCustomer
          ? <Spin className='w-full mt-2' tip="Loading" size="small">
          </Spin>
          : <div>
            {customerInfo && (
              <div className="mt-5">
                <div className="">
                  <div>
                    <PhoneOutlined className="w-[5%]" />
                    {customerInfo?.phoneNumber}
                  </div>
                  <div>
                    <RightOutlined className="w-[5%]" />
                    Tên: {customerInfo?.fullName}
                  </div>
                  <div>
                    <RightOutlined className="w-[5%]" />
                    Mô tả: {customerInfo?.description}
                  </div>
                  {
                    customerInfo?.email &&
                    <div>
                      <MailOutlined className="w-[5%]" />
                      Email: {customerInfo?.email}
                    </div>
                  }
                  {
                    customerInfo?.age &&
                    <div>
                      <NumberOutlined className="w-[5%]" />
                      Tuổi: {customerInfo?.age}
                    </div>
                  }
                  {
                    customerInfo?.gender &&
                    <div>
                      <ManOutlined className="w-[5%]" />
                      Giới tính: {customerInfo?.gender}
                    </div>
                  }
                  {/* {
                    customerInfo?.age &&
                    <div>
                      <PhoneOutlined className="w-[5%]" />
                      Địa chỉ: {customerInfo?.email}
                    </div>
                  } */}
                </div>
                {renderCustomerCallInfo()}
              </div>
            )}
            {!customerInfo && (
              <div className="mt-5 text-center text-[#94a3b8]">
                Không có khách hàng{' '}
              </div>
            )}
          </div>
      }

    </div>
  )
}
