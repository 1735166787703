export const dataType = [
  {
    title: 'Nhập chữ',
    type: 'text',
    value: 0
  },
  {
    title: 'Nhập số',
    type: 'number',
    value: 1
  },
  {
    title: 'Nhập ngày',
    type: 'date',
    value: 2
  },
  {
    title: 'Nhập ngày giờ',
    type: 'dateTime',
    value: 3
  },
  {
    title: 'Chọn nhiều',
    type: 'multiChoise',
    selectMode: 'multiple',
    value: 4
  },
  {
    selectMode: '',
    title: 'Chọn một',
    type: 'oneChoise',
    value: 5
  }
]

export const MULTICHOISE = {
  title: 'Chọn nhiều',
  type: 'multiChoise',
  selectMode: 'multiple',
  value: 4
}

export const CHOISE = {
  title: 'Chọn một',
  type: 'oneChoise',
  selectMode: 'oneChoise',
  value: 5
}

export const DATE = {
  title: 'Nhập ngày',
  type: 'date',
  value: 2
}

export const DATETIME = {
  title: 'Nhập ngày giờ',
  type: 'dateTime',
  value: 3
}
