import request from '../utils/request'
import { handleError } from './handleError'

export const getSiteInfo = async () => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.get(`Sites/${siteId}`, {
      headers: {
        Authorization: 'Bearer ' + jwtToken
      }
    })

    return response.data
  } catch (error) {
    handleError(error)
  }
}
