import React, { useState } from 'react'
import { Tabs, Card } from 'antd'
import AccountInfo from './Tabs/AccountInfo'
import GroupUser from './Tabs/GroupUser'
import Permissions from './Tabs/Permissions'
import Employees from './Tabs/Employees'
import { checkIsEmployee } from '../../permissions'
function Profile () {
  const [tabIndex, setTabIndex] = useState()
  const onChange = (key) => {
    setTabIndex(key)
  }

  const buildItem = () => {
    if (checkIsEmployee()) {
      return [
        {
          label: 'Thông tin tài khoản',
          key: 1,
          children: <AccountInfo tabIndex={tabIndex} setTabIndex={setTabIndex} />
        }
      ]
    } else {
      return [
        {
          label: 'Thông tin tài khoản',
          key: 1,
          children: <AccountInfo tabIndex={tabIndex} setTabIndex={setTabIndex} />
        },
        {
          label: 'Nhóm người dùng',
          key: 2,
          children: <GroupUser tabIndex={tabIndex} setTabIndex={setTabIndex} />
        },
        // {
        //   label: 'Phân quyền',
        //   key: 3,
        //   children: <Permissions tabIndex={tabIndex} setTabIndex={setTabIndex}/>
        // },
        {
          label: 'Tài khoản người dùng',
          key: 4,
          children: <Employees tabIndex={tabIndex} setTabIndex={setTabIndex}/>
        }
      ]
    }
  }

  return (
    <div className='tab-user-settings'>
        <Card size='small' >
        <Tabs

            onChange={onChange}
            items={buildItem()}
          />
        </Card>

    </div>
  )
}
export default Profile
