export const CallDirection = [
  {
    title: 'Cuộc gọi đi',
    value: 0
  },
  {
    title: 'Cuộc gọi tới',
    value: 1
  }
]

export const OUTBOUND = {

  title: 'Cuộc gọi đi',
  value: 0
}

export const INCOMMING = {
  title: 'Cuộc gọi tới',
  value: 1
}
