export const CallDirection = [
  {
    title: 'Mặc định',
    value: 0
  },
  {
    title: 'AUTO',
    value: 1
  },
  {
    title: 'GENLEAD',
    value: 1
  }
]

export const NORMAL = {

  title: 'Mặc định',
  value: 0
}

export const AUTO = {
  title: 'AUTO',
  value: 1
}

export const GENLEAD = {
  title: 'GENLEAD',
  value: 2
}
