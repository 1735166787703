import { Input, Radio } from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { pushSuccessMessage, pushErrorMessage, clearErrorMessage, clearSuccessMessage } from '../../../../slices/errorMessageSlice'
import ActionButton from '../ActionButton'
import { getCurrentStep, next, prev } from '../../../../slices/currentStepSlice'
import { createStrategy, updateStrategy, getStrategyById } from '../../../../services/strategyService'
import { formats, toolbarOptions } from '../../../../constants/quillConfig'
import ReactQuill from 'react-quill'
import { INCOMMING, OUTBOUND } from '../../../../constants/callDirection'
import 'react-quill/dist/quill.snow.css'
import { GENLEAD } from '../../../../constants/strategyType'

function ScriptStep ({ strategyId, setStrategyId }) {
  const dispatch = useDispatch()
  const [strategyInfo, setStrategyInfo] = useState()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [strategyType, setStrategyType] = useState(0)
  useEffect(() => {
    async function getData () {
      if (strategyId) {
        const response = await getStrategyById(strategyId)
        if (response.success) {
          const strategy = response.data
          setStrategyInfo(strategy)
          setName(strategy.name)
          setDescription(strategy.description)
          setStrategyType(strategy.strategyType)
        }
      }
    }
    getData()
  }, [strategyId])

  function createOrUpdateStrategy () {
    const strategyName = name.trim()
    const asyncAction = async () => {
      if (strategyId) {
        // Update
        strategyInfo.name = strategyName
        strategyInfo.description = description
        strategyInfo.strategyType = strategyType
        console.log('strategyInfo', strategyInfo)
        const response = await updateStrategy(strategyId, strategyInfo)
        if (response.success) {
          dispatch(pushSuccessMessage('Lưu thành công!'))
          nextStep()
        }
      } else {
        if (strategyName) {
          const response = await createStrategy(strategyName, description, strategyType)
          if (response.success) {
            const newStrategyId = response.id
            setStrategyId(newStrategyId)
            dispatch(clearSuccessMessage())
            dispatch(pushSuccessMessage('Tạo mới chiến dịch thành công'))
            nextStep()
          } else {
            dispatch(clearErrorMessage())
            dispatch(pushErrorMessage(response.errorMessage))
          }
        }
      }
    }

    asyncAction()
  }

  const nextStep = () => {
    dispatch(next())
  }

  return (
    <div>
      <div>
        {
          strategyId && <div className='mb-4'>
            <label htmlFor="strategyId">Mã chiến dịch</label>
            <Input id='strategyId' value={strategyId} disabled />
          </div>
        }

        <div className='mb-4'>
          <label htmlFor="strategyNameInput">Tên chiến dịch</label>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className='mb-4'>
          <label className='block'>Loại chiến dịch</label>
          <Radio.Group onChange={(e) => {
            setStrategyType(e.target.value)
            console.log('e.target.value', e.target.value)
          }} value={strategyType}>
            <Radio value={OUTBOUND.value}>Mặc Định</Radio>
            <Radio value={INCOMMING.value}>AUTO</Radio>
            <Radio value={GENLEAD.value}>LEAD GEN</Radio>
          </Radio.Group>
        </div>
        <div>
          <label htmlFor="strategyScrip">Kịch bản</label>
          <ReactQuill className='' theme="snow" modules={{ toolbar: toolbarOptions }
          } formats={formats} value={description} onChange={setDescription} />
        </div>
      </div>

      <ActionButton next={createOrUpdateStrategy} isDisable={!name || !description} />
    </div>
  )
}

export default ScriptStep
