import request from '../utils/request'
import { getStaticInfo } from './common'
import { handleError } from './handleError'

export const getCustomerInfo = async (strategyId) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  const userId = userInfo?.id
  try {
    const response = await request.get(`CallStrategy/${strategyId}/Customer?employeeId=${userId}`, {
      headers: {
        'x-access-site': siteId,
        Authorization: 'Bearer ' + jwtToken
      }
    })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const updateCallStrategyFail = async (callStrategy) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.put('CallStrategy/Faily', callStrategy, {
      headers: {
        'x-access-site': siteId,
        Authorization: 'Bearer ' + jwtToken
      }
    })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const createCallStrategySuccess = async (callStrategy) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.post(
      'CallStrategy/Successfully',
      callStrategy,
      {
        headers: {
          'x-access-site': siteId,
          Authorization: 'Bearer ' + jwtToken
        }
      }
    )

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const updateCallInfo = async (callInfoId, callInfo) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.put(`/CallInfo/${callInfoId}`, callInfo, {
      headers: {
        'x-access-site': siteId,
        Authorization: 'Bearer ' + jwtToken
      }
    })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const updateFailedCallInfo = async (callInfoId, failedReason) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.put('/CallInfo/FailedReason',
      {
        CallId: callInfoId,
        FailedReason: failedReason
      }, {
        headers: {
          'x-access-site': siteId,
          Authorization: 'Bearer ' + jwtToken
        }
      })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const updateCallInfoFreedom = async (callInfoId, callInfo) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.put(`/CallInfo/Freedom/${callInfoId}`, callInfo, {
      headers: {
        'x-access-site': siteId,
        Authorization: 'Bearer ' + jwtToken
      }
    })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const getCallHistory = async (customerId) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.get(`Customers/${customerId}/CallInfo`, {
      headers: {
        'x-access-site': siteId,
        Authorization: 'Bearer ' + jwtToken
      }
    })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const getStrategySettingInfo = async (strategyId) => {
  const { headers } = getStaticInfo()

  try {
    const response = await request.get(`CallStrategy/${strategyId}/Settings`, {
      headers
    })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const getCallResult = async (callId) => {
  const { headers } = getStaticInfo()

  try {
    const response = await request.get(`CallInfo/Result/${callId}`, {
      headers
    })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const updateCallResult = async (callId, requestBody) => {
  const { headers } = getStaticInfo()

  try {
    const response = await request.put(`CallInfo/Result/${callId}`, requestBody, {
      headers
    })

    return response.data
  } catch (error) {
    handleError(error)
  }
}
