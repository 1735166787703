import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loginMessages: [],
  errorMessage: [],
  successMessage: [],
  notifyMessage: []
}

export const errorMessageSlice = createSlice({
  name: 'errorMessage',
  initialState,
  reducers: {
    pushMessage: (state, { payload }) => {
      state.loginMessages.push(payload)
    },
    pushMessages: (state, { payload }) => {
      state.loginMessages = state.loginMessages.concat(payload)
    },
    clearMessage: (state) => {
      state.loginMessages = []
    },
    clearSuccessMessage: (state) => {
      state.successMessage = []
    },
    clearErrorMessage: (state) => {
      state.errorMessage = []
    },
    clearEntryMessage: (state) => {
      state.successMessage = []
      state.errorMessage = []
      state.notifyMessage = []
    },
    pushSuccessMessages: (state, { payload }) => {
      state.successMessage = state.successMessage.concat(payload)
    },
    pushErrorMessages: (state, { payload }) => {
      state.errorMessage = state.errorMessage.concat(payload)
    },
    pushSuccessMessage: (state, { payload }) => {
      state.successMessage.push(payload)
    },
    pushErrorMessage: (state, { payload }) => {
      state.errorMessage.push(payload)
    },
    clearAndPushErrorMessage: (state, { payload }) => {
      state.errorMessage = []
      state.errorMessage.push(payload)
    },
    clearAndPushSuccessMessage: (state, { payload }) => {
      state.successMessage = []
      state.successMessage.push(payload)
    },
    clearAndPushNotifyMessage: (state, { payload }) => {
      state.notifyMessage = []
      state.notifyMessage.push(payload)
    }
  }
})

export const {
  pushMessage,
  pushMessages,
  clearMessage,
  clearErrorMessage,
  clearSuccessMessage,
  pushErrorMessage,
  pushErrorMessages,
  pushSuccessMessage,
  pushSuccessMessages,
  clearAndPushErrorMessage,
  clearAndPushSuccessMessage,
  clearEntryMessage,
  clearAndPushNotifyMessage
} = errorMessageSlice.actions

export const getLoginMessages = (state) => state.errorMessageSlice.loginMessages
export const getErrorMessage = (state) => state.errorMessageSlice.errorMessage
export const getSuccessMessage = (state) => state.errorMessageSlice.successMessage
export const getNotifyMessage = (state) => state.errorMessageSlice.notifyMessage

export default errorMessageSlice.reducer
