import { Link } from 'react-router-dom'
import {
  AppstoreOutlined,
  SettingOutlined,
  LogoutOutlined,
  MenuOutlined,
  HomeOutlined,
  BarChartOutlined
} from '@ant-design/icons'
import Navigation from './Navigation'
import Logo from '../Common/Logo'
import UserInfo from '../Common/UserInfo'
import React, { useEffect, useState } from 'react'
import { Button, Drawer, Menu } from 'antd'
import { useMediaQuery } from 'react-responsive'
import screenSize from '../../constants/screenSize'

function Header () {
  const [visible, setVisible] = useState(false)
  const [loginState, setLogInState] = useState(false)
  const [current, setCurrent] = useState('home')
  const isTabletMiniOrMobile = useMediaQuery({ query: screenSize.TABLETMINI }) // tablet mini
  const [fullName, setFullName] = useState('')
  const redirectToLogIn = () => {
    localStorage.removeItem('jwtToken')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('siteInfo')
    setVisible(false)
    window.location.replace('/login')
  }

  const checkLogin = () => {
    const token = localStorage.getItem('jwtToken')
    const userInfo = localStorage.getItem('userInfo')
    if (!token || !userInfo) {
      setLogInState(false)
    } else {
      setLogInState(true)
    }
  }

  const items = [
    {
      label: <Link to="/">Trang chủ</Link>,
      key: 'home',
      icon: <HomeOutlined />
    },
    {
      label: <Link to="/strategy">Chiến dịch</Link>,
      key: 'strategy',
      icon: <AppstoreOutlined />
    },
    // {
    //   label: 'Danh bạ',
    //   key: 'phonelist',
    //   icon: <MailOutlined />
    // },
    {
      label: <Link to="/report">Báo cáo</Link>,
      key: 'report',
      icon: <BarChartOutlined />
    },
    // {
    //   label: 'Liên hệ',
    //   key: 'contact',
    //   icon: <AppstoreOutlined />
    // },
    {
      label: <Link to="/User/Profile">Cài Đặt</Link>,
      key: 'settings',
      icon: <SettingOutlined />
    }
  ]
  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const onClick = (e) => {
    setVisible(false)
    setCurrent(e.key)
  }

  useEffect(() => {
    checkLogin()
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo) {
      // setEmail(userInfo.email)
      setFullName(userInfo.fullName)
    }
  }, [])

  const renderUserInfo = () => {
    return (
        <div>
            <div className="">
              <div>
                <h4 className="">{fullName}</h4>
              </div>
            </div>
        </div>
    )
  }

  return (
    <div className = "" >
      <header className=" sticky top-0 z-10 dark-background">
        <div className="px-2 tablet:px-4 py-2.5 flex items-center">
          <div className="grid grid-cols-12 items-center w-full">
            <div className="col-start-2 col-span-2">
              <Logo />
            </div>
            <div className=" hidden tablet:block tablet:col-start-4 tablet:col-end-10  col-start-7 col-end-10">
               <Navigation loginState={loginState} />
            </div>
            <div className="col-end-12 hidden tablet:block col-span-2 right-3 relative">
              <UserInfo />
            </div>
          </div>
          {loginState &&
            (
              <Button
                className="open-drawer-btn"
                type="primary"
                onClick={showDrawer}
              >
                <MenuOutlined />
              </Button>
            )}
        </div>
      </header>

  {
    isTabletMiniOrMobile && (
      <Drawer
            title={
              renderUserInfo()
    }
        width={'80%'}
        closable={false}
        onClose={onClose}
        open={visible}
        className="grid"
      >
        <Menu onClick={onClick} selectedKeys={[current]} items={items} />
        <div className="absolute bottom-0 border-t border-solid border-[#e8e8e8] w-full text-right bg-[#fff] left-0 rounded-[0 0 4px 4px] px-[16px] py-[10px]">
          <Button
            style={{
              marginRight: 8
            }}
            onClick={onClose}
          >
            Đóng
          </Button>
          <Button
            hidden={!loginState}
            icon={<LogoutOutlined />}
            danger
            onClick={redirectToLogIn}
          >
            Đăng xuất
          </Button>
        </div>
      </Drawer>
    )
  }
    </div >
  )
}

export default Header
