import React from 'react'
import { Card } from 'antd'
import parse from 'html-react-parser'

export default function StrategyScrip ({ callStrategyInfo }) {
  return (
    <Card
      className="col-span-7 mb-12 tablet:mb-0 pb-2"
      title={
        <div className="flex justify-between flex-wrap">
          <h3 style={{ whiteSpace: 'break-spaces' }} className="mb-0">Chiến dịch gọi: {callStrategyInfo?.name}</h3>
        </div>
      }
    >
      <h2 className="">Kịch bản</h2>
      <div className="">
        {callStrategyInfo?.description &&
          <div>
            {parse(callStrategyInfo?.description)}
          </div>}
      </div>
    </Card>
  )
}
