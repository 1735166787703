import { Button, Modal } from 'antd'
import React from 'react'
import StopWatch from '../../../components/Common/Stopwatch'

export default function IncomingCallModal ({ openIncommingCall, incomingPhoneNumber, handleHangupIncoming, isResetInCommingStartWatch, isStartInCommingStartWatch }) {
  return (
    <div>
      <Modal
        maskClosable={false}
        closable={false}
        footer={<></>}
        title={<h2><span>Cuộc gọi đến </span> <span>{incomingPhoneNumber}</span></h2>}
        open={openIncommingCall}>
        {
          <div>
            <div className='flex justify-center'>
              <Button
                danger
                type="text"
                onClick={handleHangupIncoming}>
                Kết thúc cuộc gọi
              </Button>
            </div>
            <div className="text-center mt-4">
              <StopWatch isReset={isResetInCommingStartWatch} isStart={isStartInCommingStartWatch} />
            </div>
          </div>
        }
      </Modal>
    </div>
  )
}
