import React, { useEffect, useState, useRef } from 'react'
import { Modal, Button, Checkbox, Upload, Progress, Select } from 'antd'
import { FileExcelOutlined, UploadOutlined } from '@ant-design/icons'
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'
import * as signalR from '@microsoft/signalr'
import fileFormat from '../../../../../constants/excelFileFormat'
import { importCustomer } from '../../../../../services/customerService'
import { useDispatch } from 'react-redux'
import {
  clearAndPushErrorMessage,
  clearAndPushSuccessMessage
} from '../../../../../slices/errorMessageSlice'
import { checkDuplicationTypes } from '../../../../../constants/checkDuplicationType'

function ImportCustomer ({
  multipleAdd,
  setMultipleAdd,
  contactFields,
  strategyId,
  setToggleData,
  toggleData
}) {
  const dispatch = useDispatch()
  const [isFileUploadNull, setIsFileUploadNull] = useState(false)
  const [fileImport, setFileImport] = useState([])
  const [isImportUpdate, setIsImportUpdate] = useState(false)
  const [fileErrorMessage, setFileErrorMessage] = useState()
  const [loadingImport, setLoadingImport] = useState(false)
  const [disableUpload, setDisableUpload] = useState(false)
  const [invalidPhoneNumbers, setInvalidPhoneNumbers] = useState([])
  const [duplicatePhoneNumbers, setDuplicatePhoneNumbers] = useState([])
  const [numberOfEmptyPhoneNumber, setNumberOfEmptyPhoneNumber] = useState(0)
  const [importProcessPercentage, setImportProcessPercentage] = useState(0)
  const [hubId, setHubId] = useState()
  const [selectedCheckDuplicationType, setSelectedCheckDuplicationType] = useState(2)
  const connectionHub = useRef()

  useEffect(() => {
    const handler = async () => {
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_API_BASEURL}importCustomerHub`)
        .build()
      if (connection) {
        connectionHub.current = connection
        await connection.start()
        setHubId(connection.connectionId)
        console.log('connection.connectionId', connection.connectionId)
        connection.on('SendCurrentImportProcessInfo', data => {
          const { percentage } = data
          setImportProcessPercentage(percentage)
        })
      }
    }

    handler()

    return async () => {
      await connectionHub.current.stop()
    }
  }, [])

  const exportToCSV = (xlsxData, fileName) => {
    const workSheet = XLSX.utils.json_to_sheet(xlsxData)
    const workBook = { Sheets: { data: workSheet }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(workBook, {
      bookType: 'xlsx',
      type: 'array'
    })
    const data = new Blob([excelBuffer], { type: fileFormat.FILE_TYPE })
    FileSaver.saveAs(data, fileName + fileFormat.FILE_EXTENSION)
  }

  const getDataFile = async (file) => {
    setFileImport([file])
  }

  const handleChangeUpdate = (e) => {
    const statusUpdate = e.target.checked
    setIsImportUpdate(statusUpdate)
  }

  const handleCloseModalMultipleAdd = () => {
    setFileImport()
    setMultipleAdd(false)
    setToggleData(!toggleData)
    setDisableUpload(false)
    setInvalidPhoneNumbers([])
    setDuplicatePhoneNumbers([])
    setNumberOfEmptyPhoneNumber(0)
    setImportProcessPercentage(0)
    setHubId()
    setSelectedCheckDuplicationType(2)
  }

  const downloadTemplate = () => {
    let templateContact = {}
    for (const item of contactFields) {
      templateContact = { ...templateContact, [item.code]: item.displayName }
    }
    exportToCSV([templateContact], 'contact-template')
  }

  const downloadFaildImportData = () => {
    const duplicateData = [
      ['Số điện thoại bị trùng'],
      duplicatePhoneNumbers
    ]
    const ws = XLSX.utils.aoa_to_sheet(duplicateData)

    const invalidData = [
      ['Số điện thoại sai định dạng'],
      invalidPhoneNumbers
    ]
    const ws2 = XLSX.utils.aoa_to_sheet(invalidData)

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'SheetJS1')
    XLSX.utils.book_append_sheet(wb, ws2, 'SheetJS2')

    // XLSX.writeFile(wb, 'sheetjs.xlsx')
    const excelBuffer = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array'
    })
    const data = new Blob([excelBuffer], { type: fileFormat.FILE_TYPE })
    FileSaver.saveAs(data, 'file lỗi' + fileFormat.FILE_EXTENSION)
  }

  const importData = async () => {
    if (fileImport) {
      const MAX_SIZE = 1 * 1024 * 1024 // 1mb
      if (fileImport[0].size > MAX_SIZE) {
        setFileErrorMessage(
          'Dung lượng file quá lớn,vui lòng chọn file bé hơn 1MB'
        )
        setIsFileUploadNull(true)
      } else {
        setLoadingImport(true)
        const formData = new FormData()
        formData.append('file', fileImport[0])
        const response = await importCustomer(
          strategyId,
          formData,
          isImportUpdate,
          hubId,
          selectedCheckDuplicationType
        )
        if (response.success) {
          dispatch(clearAndPushSuccessMessage('Thêm mới thành công'))
          const { invalidCustomerImport } = response
          const { invalidPhoneNumbers, duplicatePhoneNumbers, numberOfEmptyPhoneNumber } = invalidCustomerImport
          setInvalidPhoneNumbers(invalidPhoneNumbers)
          setDuplicatePhoneNumbers(duplicatePhoneNumbers)
          setNumberOfEmptyPhoneNumber(numberOfEmptyPhoneNumber)
        } else {
          dispatch(clearAndPushErrorMessage(response.message))
        }
        setLoadingImport(false)
        setDisableUpload(true)
        handleCloseModalMultipleAdd()
      }
    } else {
      setFileErrorMessage('Bạn cần chọn file tải lên')
      setIsFileUploadNull(true)
    }
  }

  return (
    <div>
      {
        multipleAdd && <Modal
          title="Thêm mới hàng loạt"
          okText="Tải lên"
          okButtonProps={{ disabled: disableUpload }}
          cancelText
          onOk={importData}
          onCancel={handleCloseModalMultipleAdd}
          maskClosable={false}
          open={multipleAdd}
          confirmLoading={loadingImport}
        >
          <div className="">
            <div className="pb-5">
              <div className="">Tải xuống file mẫu</div>
              <div className="">
                <Button
                  block
                  onClick={downloadTemplate}
                  style={{
                    width: 'max-content',
                    backgroundColor: '#6C757D',
                    color: 'white'
                  }}
                >
                  <FileExcelOutlined />
                  callmaster-contact-template.xlsx
                </Button>
              </div>

            </div>
            <div className="pb-5">
              <div className="">Chọn file</div>
              <div className="">
                <div className="w-full">
                  <Upload
                    className="w-full"
                    multiple={false}
                    fileList={fileImport}
                    beforeUpload={getDataFile}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  >
                    <Button className="w-full" icon={<UploadOutlined />}>
                      Click to Upload
                    </Button>
                  </Upload>
                  <div className="text-red-600 mt-1">
                    Lưu ý: Người dùng chỉ upload file excel có dung lượng dưới 1mb
                  </div>
                  {
                    (invalidPhoneNumbers.length || duplicatePhoneNumbers.length)
                      ? <Button
                        block
                        onClick={downloadFaildImportData}
                        style={{
                          width: 'max-content',
                          backgroundColor: '#6C757D',
                          color: 'white'
                        }}
                      >
                        <FileExcelOutlined />
                        File_loi.xlsx
                      </Button>
                      : <></>

                  }
                  {
                    !!numberOfEmptyPhoneNumber && <p>Có {numberOfEmptyPhoneNumber} số điện thoại rỗng và đã được bỏ qua</p>
                  }
                  {isFileUploadNull && (
                    <div
                      className="fixed top-0 left-0 right-0 bottom-0 z-[1000]"
                      style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}
                    >
                      <div
                        id="popup-modal"
                        className=" absolute bottom-[50%] ml-[50%] translate-x-[-50%] translate-y-[-50%] z-[1000]"
                      >
                        <div className="relative p-4 w-full   max-h-28">
                          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <div className="p-6 tablet:px-20 grid text-center">
                              <svg
                                aria-hidden="true"
                                className="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                ></path>
                              </svg>
                              <h3 className="mb-5 w-max text-sm font-normal text-gray-500 dark:text-gray-400">
                                {fileErrorMessage}
                              </h3>
                              <button
                                type="button"
                                onClick={() => setIsFileUploadNull(false)}
                                className="border-none hover:cursor-pointer text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm  px-5 py-2.5 text-center mr-2"
                              >
                                OK
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Checkbox onChange={handleChangeUpdate} checked={isImportUpdate}>
              Cập nhật liên hệ đã tồn tại
            </Checkbox>
            <div className='mt-2 '>
              <Select
                size="middle"
                defaultValue={selectedCheckDuplicationType}
                onChange={(value) => {
                  setSelectedCheckDuplicationType(value)
                }}
                options={checkDuplicationTypes}
                style={{
                  width: '60%'
                }}
              />
            </div>
            <Progress percent={importProcessPercentage} />

          </div>
        </Modal>
      }
    </div>

  )
}

export default ImportCustomer
