import { FileExcelOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Modal, Upload } from 'antd'
import React, { useState } from 'react'
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'
import excelFileFormat from '../../../../../constants/excelFileFormat'
import { useDispatch } from 'react-redux'
import { deleteCustomerByFile } from '../../../../../services/customerService'
import { clearAndPushErrorMessage, clearAndPushSuccessMessage } from '../../../../../slices/errorMessageSlice'

export default function DeleteCustomerByFile ({ isOpen, handleClosePopup, strategyId, handleRefresh }) {
  const [isFileUploadNull, setIsFileUploadNull] = useState(false)
  const [fileErrorMessage, setFileErrorMessage] = useState()
  const [loading, setLoading] = useState(false)
  const [fileImport, setFileImport] = useState([])
  const dispatch = useDispatch()

  const downloadTemplate = () => {
    const template = {
      phoneNumber: '0989888832'
    }

    const workSheet = XLSX.utils.json_to_sheet([template])
    const workBook = { Sheets: { data: workSheet }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(workBook, {
      bookType: 'xlsx',
      type: 'array'
    })
    const data = new Blob([excelBuffer], { type: excelFileFormat.FILE_TYPE })
    FileSaver.saveAs(data, 'template' + excelFileFormat.FILE_EXTENSION)
  }

  const importData = async () => {
    if (fileImport) {
      const MAX_SIZE = 1 * 1024 * 1024 // 1mb
      if (fileImport[0].size > MAX_SIZE) {
        setFileErrorMessage(
          'Dung lượng file quá lớn,vui lòng chọn file bé hơn 1MB'
        )
        setIsFileUploadNull(true)
      } else {
        setLoading(true)
        const formData = new FormData()
        formData.append('file', fileImport[0])

        // Do call api
        const response = await deleteCustomerByFile(strategyId, formData)
        if (!response.success) {
          dispatch(clearAndPushErrorMessage(response.message))
        } else {
          dispatch(clearAndPushSuccessMessage('Xóa danh sách khách hàng thành công'))
          handleRefresh()
          closePopup()
        }
      }
    } else {
      setFileErrorMessage('Bạn cần chọn file tải lên')
      setIsFileUploadNull(true)
    }
  }

  const closePopup = () => {
    setFileErrorMessage()
    setFileImport([])
    setLoading(false)
    setFileErrorMessage()
    handleClosePopup()
  }

  return (
    <Modal
      title="Thêm mới hàng loạt"
      okText="Xóa hàng loạt"
      cancelText
      onOk={importData}
      onCancel={closePopup}
      maskClosable={false}
      open={isOpen}
      confirmLoading={loading}
    >
      <div className="">
        <div className="pb-5">
          <div className="">Tải xuống file mẫu</div>
          <div className="">
            <Button
              block
              onClick={downloadTemplate}
              style={{
                width: 'max-content',
                backgroundColor: '#6C757D',
                color: 'white'
              }}
            >
              <FileExcelOutlined />
              file mẫu.xlsx
            </Button>
          </div>

        </div>
        <div className="pb-5">
          <div className="">Chọn file</div>
          <div className="">
            <div className="w-full">
              <Upload
                className="w-full"
                multiple={false}
                fileList={fileImport}
                beforeUpload={(file) => setFileImport([file])}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              >
                <Button className="w-full" icon={<UploadOutlined />}>
                  Click to Upload
                </Button>
              </Upload>
              <div className="text-red-600 mt-1">
                Lưu ý: Người dùng chỉ upload file excel có dung lượng dưới 1mb
              </div>
              {isFileUploadNull && (
                <div
                  className="fixed top-0 left-0 right-0 bottom-0 z-[1000]"
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}
                >
                  <div
                    id="popup-modal"
                    className=" absolute bottom-[50%] ml-[50%] translate-x-[-50%] translate-y-[-50%] z-[1000]"
                  >
                    <div className="relative p-4 w-full   max-h-28">
                      <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="p-6 tablet:px-20 grid text-center">
                          <svg
                            aria-hidden="true"
                            className="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            ></path>
                          </svg>
                          <h3 className="mb-5 w-max text-sm font-normal text-gray-500 dark:text-gray-400">
                            {fileErrorMessage}
                          </h3>
                          <button
                            type="button"
                            onClick={() => setIsFileUploadNull(false)}
                            className="border-none hover:cursor-pointer text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm  px-5 py-2.5 text-center mr-2"
                          >
                            OK
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
