import { CaretDownOutlined, DownloadOutlined, SearchOutlined, SortAscendingOutlined, SortDescendingOutlined, SyncOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Dropdown, DatePicker, Input, Pagination, Select, Table } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import CallStatusTag from '../../components/Common/Tags/CallStatusTag'
import { callStatuses } from '../../constants/callStatuses'
import { IsOrderByDescending } from '../../constants/sortType'
import { getAllCallHistory } from '../../services/callInfoService'
import { getStaticInfo } from '../../services/common'
import { currentUserGetStrategies, filterEmployees, managerGetUser } from '../../services/userInfoService'
import { clearAndPushErrorMessage, clearEntryMessage } from '../../slices/errorMessageSlice'
import locale from 'antd/es/date-picker/locale/vi_VN'
import { checkExport, checkIsEmployee, checkIsSupperAdmin } from '../../permissions'
import { getSiteInfo } from '../../services/siteService'
import { getLinkRecord } from '../../utils/linkRecords'
import { CallSource } from '../../constants/callSource'
export default function History () {
  const { Option } = Select
  const { RangePicker } = DatePicker

  const dispatch = useDispatch()
  const dateFormat = 'DD-MM-YYYY'
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
  const [datePickerValue, setDatePickerValue] = useState([moment(), moment()])
  const [toggle, setToggle] = useState(false)
  const [dataTable, setDataTable] = useState([])
  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentSize, setCurrentSize] = useState(20)
  const [searchPhoneNumber, setSearchPhoneNumber] = useState('')
  const [employeeSelected, setEmployeeSelected] = useState([])
  const [employees, setEmployees] = useState([])
  const [sortTypeSelected, setSortTypeSelected] = useState(true)
  const [selectedCallStatus, setSelectedCallStatus] = useState([])
  const [selectedStrategies, setSelectedStrategies] = useState([])
  const [strategies, setStrategies] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isExportLoading, setIsExportLoading] = useState(false)
  const [siteInfo, setSiteInfo] = useState()
  const [selectedCallSource, setSelectedCallSource] = useState(0)
  const [tableColumns, settableColumns] = useState([
    {
      title: 'Tên chiến dịch',
      key: 'strategyName',
      render: (record) => {
        return <b>{record.strategyName ? record.strategyName : '---'}</b>
      }
    },
    {
      title: 'Tên nhân viên',
      dataIndex: 'employeeName',
      key: 'employeeName'
    },
    {
      title: 'Số nhánh',
      dataIndex: 'userLinePhone',
      key: 'userLinePhone'
    },
    {
      title: 'Số điện thoại',
      key: 'phoneNumber',
      render: (record) => {
        let phoneNumber = record.phoneNumber
        if (!phoneNumber) {
          phoneNumber = record.customerPhoneNumber
        }

        return <p>{phoneNumber}</p>
      }
    },
    {
      title: 'Trạng thái',
      key: 'status',
      render: (record) => {
        return <CallStatusTag callStatusValue={record.status} />
      }
    },
    {
      title: 'Thời gian đàm thoại',
      key: 'durations',
      render: (record) => {
        if (record.durations < 3600) {
          return new Date(record.durations * 1000).toISOString().substring(14, 19)
        } else { return <p>{new Date(record.durations * 1000).toISOString().slice(11, 19)}</p> }
      }
    },
    {
      title: 'Ghi âm cuộc gọi',
      key: 'linkRecord',
      render: (record) => {
        return record.callioId && record.callioId !== '_'
          ? <div>
            <div>
              <audio style={{ width: '550px' }} controls src={record.linkRecord}></audio>

            </div>
            <a onClick={() => {
              const handler = async () => {
                const url = await getLinkRecord(siteInfo, record.callioId)
                if (url) { window.open(url, '_blank').focus() }
              }
              handler()
            }}>
              Tải file ghi âm: <DownloadOutlined />
            </a>
          </div>
          : <p>---</p>
      }
    },
    {
      title: 'Thời gian gọi',
      dataIndex: 'createdAt',
      key: 'createdAt'
    }
  ]
  )

  useEffect(() => {
    const { callSource } = getStaticInfo()
    setSelectedCallSource(callSource ?? 0)
  }, [])

  useEffect(() => {
    if (checkIsSupperAdmin()) {
      settableColumns((old) => {
        old.push(
          {
            title: 'Nguyên nhân lỗi',
            key: 'errorCode',
            render: (record) => {
              return <b className='text-red-600'>{record.errorMessage}</b>
            }
          }
        )

        return old
      })
    }
  }, [])

  useEffect(() => {
    const handler = async () => {
      const response = await currentUserGetStrategies()
      const { strategies } = response
      setStrategies(strategies)
    }

    handler()
  }, [])

  useEffect(() => {
    const handler = async () => {
      const response = await getSiteInfo()
      const { data } = response
      setSiteInfo(data)
    }

    handler()
  }, [])

  useEffect(() => {
    const handler = async () => {
      const response = await managerGetUser()
      if (response.success) {
        setEmployees(response.result)
      } else {
        dispatch(clearAndPushErrorMessage(response.message))
      }
    }

    handler()
  }, [toggle])

  useEffect(() => {
    const handler = async () => {
      setIsLoading(true)
      const filter = buildFilter()
      const response = await getAllCallHistory(filter)
      const siteRespose = await getSiteInfo()
      const localSiteInfo = siteRespose?.data
      if (!response.success) {
        dispatch(clearAndPushErrorMessage(response.message))
      } else {
        const data = response.result?.data
        for await (const element of data) {
          element.createdAt = moment.utc(element.createdAt).local().format('YYYY-MM-DD HH:mm:ss')
          if (element.callioId) {
            element.linkRecord = await getLinkRecord(localSiteInfo, element.callioId)
          }
        }
        setDataTable(data)
        setCount(response.result?.count)
      }
      setIsLoading(false)
    }

    handler()

    return () => {
      dispatch(clearEntryMessage())
    }
  }, [currentPage, currentSize, selectedCallSource, searchPhoneNumber, sortTypeSelected, employeeSelected, startDate, endDate, selectedCallStatus, selectedStrategies])

  const buildFilter = () => {
    const { siteId, userId } = getStaticInfo()

    return {
      siteId,
      isOrderByDescending: sortTypeSelected,
      callStatus: selectedCallStatus,
      limit: currentSize,
      page: currentPage,
      phoneNumber: searchPhoneNumber,
      employeeIds: employeeSelected,
      rangeDate: {
        startTime: startDate,
        endTime: endDate
      },
      strategyIds: selectedStrategies,
      callSource: selectedCallSource,
      EmployeeId: userId
    }
  }

  const handlePagingChange = (page, pageSize) => {
    setCurrentPage(page)
    setCurrentSize(pageSize)
  }
  const onRangePickerChange = (date) => {
    setDatePickerValue(date)
    setStartDate(date[0].format('YYYY-MM-DD'))
    setEndDate(date[1].format('YYYY-MM-DD'))
  }

  const refresh = () => {
    setSearchPhoneNumber('')
    setEmployeeSelected([])
    setSelectedCallStatus([])
    setSortTypeSelected(true)
    setSelectedCallSource(-1)
    setCurrentPage(1)
    setCurrentSize(20)
    setStartDate(moment().format('YYYY-MM-DD'))
    setEndDate(moment().format('YYYY-MM-DD'))
    setDatePickerValue([moment(), moment()])
  }

  const exportData = () => {
    const handler = () => {
      const dateDiff = datePickerValue[1].diff(datePickerValue[0], 'days')
      if (dateDiff > 15) {
        dispatch(clearAndPushErrorMessage('Chỉ xuất dữ liệu trong khoảng 16 ngày, vui lòng chọn lại khoảng ngày'))
      } else {
        const { siteId, userId } = getStaticInfo()
        window.location.href = `${process.env.REACT_APP_API_BASEURL}api/ExportData/CallInfo?SiteId=${siteId}&StartTime=${startDate}&EndTime=${endDate}&Status=${selectedCallStatus.join(',')}&StrategyIds=${selectedStrategies.join(',')}&UserId=${userId}&CallSource=${selectedCallSource}`
      }
    }

    handler()
  }

  return (
    <div>
      <div className="flex flex-col flex-end items-end mb-2">
        <div className="tablet:mb-1  w-full flex flex-col tablet:justify-end tablet:flex-row tablet:items-start">
          <Input
            value={searchPhoneNumber}
            placeholder='Nhập số điện thoại cần tìm kiếm ...'
            suffix={<SearchOutlined />}
            className='mb-2 tablet:mr-1 tablet:w-[250px]'
            onChange={(e) => setSearchPhoneNumber(e.target.value)} />
          <Select
            maxTagCount={2}
            className='tablet:mr-1 mb-2 tablet:min-w-[250px]'
            placeholder="Chọn chiến dịch"
            value={selectedStrategies}
            mode="multiple"
            allowClear={true}
            filterOption={(input, option) => (option?.lable.toUpperCase() ?? '').includes(input.toUpperCase())}
            showArrow={true}
            onChange={(value) => {
              setSelectedStrategies(value)
            }}>
            {
              strategies?.map((item) => (
                <Option lable={item.name} value={item.id} key={item.id}>
                  <div className="">
                    <div className="font-bold">{item.name}</div>
                  </div>
                </Option>
              ))
            }
          </Select>
          {
            checkIsEmployee()
              ? <></>
              : <Select
                className='tablet:mr-1 mb-2 tablet:min-w-[250px]'
                placeholder="Tất cả nhân viên"
                suffixIcon={<UserOutlined />}
                value={employeeSelected}
                mode="multiple"
                allowClear={true}
                showArrow={true}
                filterOption={(input, option) => (option?.lable ?? '').includes(input)}
                onChange={(value) => {
                  setEmployeeSelected(value)
                }}>
                {
                  employees?.map((item) => (
                    <Option lable={item.name} value={item.id} key={item.id}>
                      <div className="">
                        <div className="font-bold">{item.name}</div>
                        {/* {item.roleInfo?.title && <Tag color="green" className='text-[12px]'>{item.roleInfo?.title}</Tag>} */}
                      </div>
                    </Option>
                  ))
                }
              </Select>
          }
          <Select
            className='mb-2 tablet:mr-1 tablet:min-w-[250px]'
            value={selectedCallStatus}
            placeholder="Tất cả trạng thái"
            mode="multiple"
            allowClear={true}
            suffixIcon={<CaretDownOutlined />}
            onChange={(value) => {
              setSelectedCallStatus(value)
            }}>
            {
              callStatuses.map((item) => (
                <Option value={item.value} key={item.value}>{item.title}</Option>
              ))
            }
          </Select>
        </div>
        <div className="flex w-full tablet:w-auto flex-col tablet:flex-row tablet:items-center items-end">
          {
            checkIsSupperAdmin() &&
            <Select
              placeholder="Chọn nguồn cuộc gọi"
              className='mr-1'
              value={selectedCallSource}
              options={CallSource}
              onChange={(e) => {
                setSelectedCallSource(e)
              }}
            />
          }

          <RangePicker
            className='w-full tablet:w-auto'
            placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
            format={dateFormat}
            value={datePickerValue}
            onChange={onRangePickerChange} showToday={true} locale={locale} />

          <div className="mt-2 tablet:mt-0 w-full tablet:w-auto flex justify-end">
            <Select
              className='tablet:ml-1 mr-1 tablet:min-w-[150px] grow '
              value={sortTypeSelected}
              suffixIcon={sortTypeSelected ? <SortDescendingOutlined /> : <SortAscendingOutlined />}
              onChange={(value) => {
                setSortTypeSelected(value)
              }}>
              {
                IsOrderByDescending.map((item, key) => (
                  <Option value={item.value} key={key}>{item.title}</Option>
                ))
              }
            </Select>

            <Button type='primary' onClick={() => refresh()} >
              <SyncOutlined />
            </Button>
            {
              checkExport()
                ? <Button className='ml-1' loading={isExportLoading} type='primary' onClick={() => exportData()} >
                  <DownloadOutlined />
                </Button>
                : <></>
            }

          </div>
        </div>

      </div>
      <Pagination
        className="text-right mb-8"
        total={count}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `Tổng ${total} cuộc gọi`}
        defaultCurrent={currentPage}
        defaultPageSize={currentSize}
        pageSize={currentSize}
        current={currentPage}
        onChange={handlePagingChange}
      />
      <h3>Tổng đài: {siteInfo?.uriSuffix}</h3>
      <Table
        loading={isLoading}
        pagination={false}
        dataSource={dataTable}
        columns={tableColumns}
      />
      <Pagination
        className="text-right mt-8"
        total={count}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `Tổng ${total} cuộc gọi`}
        defaultCurrent={currentPage}
        defaultPageSize={currentSize}
        pageSize={currentSize}
        current={currentPage}
        onChange={handlePagingChange}
      />
    </div>
  )
}
