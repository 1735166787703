export const callStatuses = [
  {
    title: 'Thất bại',
    value: 0,
    tagColor: 'default'
  },
  {
    title: 'Không nhu cầu',
    value: 1,
    tagColor: 'error'
  },
  {
    title: 'Zalo báo nhóm',
    value: 2,
    tagColor: 'success'
  }
  // {
  //   title: 'Hẹn gọi lại',
  //   value: 3,
  //   tagColor: 'processing'
  // },
  // {
  //   title: 'Trạng thái khác',
  //   value: 4,
  //   tagColor: 'warning'
  // }
]

export const RECALL = {
  title: 'Gọi lại',
  value: 3,
  tagColor: 'processing'
}

export const OTHER = {
  title: 'Trạng thái khác',
  value: 4,
  tagColor: 'warning'
}

export const FAILD = {
  title: 'Thất bại',
  value: 0,
  tagColor: 'default'
}

export const REJECT = {
  title: 'Từ chối',
  value: 1,
  tagColor: 'error'
}

export const SUCCESS = {
  title: 'Chấp thuận',
  value: 2,
  tagColor: 'success'
}
