import axios from 'axios'
const request = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL + 'api/'
})

const callioRequest = axios.create({
  baseURL: 'https://clientapi.phonenet.io/'
})

const diginextRequest = axios.create({
  baseURL: 'https://voice.diginext.com.vn/'
})

export default request

export { callioRequest, diginextRequest }
