import React from 'react'
import { Breadcrumb } from 'antd'
import { RightOutlined } from '@ant-design/icons'

function UserProfileBreadScrumb () {
  return (
    <Breadcrumb separator={<RightOutlined />}>
      <Breadcrumb.Item href='/'>Trang chủ</Breadcrumb.Item>
      <Breadcrumb.Item href="">Cài đặt</Breadcrumb.Item>
    </Breadcrumb>
  )
}

export default UserProfileBreadScrumb
