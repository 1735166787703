const FILES = require('../assets/sounds/sounds.json')

const SOUNDS = new Map(
  [
    ['ringback', { audio: new Audio(FILES.ringback), volume: 0.5 }],
    ['ringing', { audio: new Audio(FILES.ringing), volume: 0.5 }],
    ['answered', { audio: new Audio(FILES.answered), volume: 0.5 }],
    ['rejected', { audio: new Audio(FILES.rejected), volume: 0.5 }]
  ])

let initialized = false

export default {
  initialize () {
    if (initialized) { return }

    for (const sound of SOUNDS.values()) {
      sound.audio.volume = 0

      try {
        sound.audio.play()
      } catch (error) {}
    }

    initialized = true
  },

  play (name, relativeVolume) {
    this.initialize()

    if (typeof relativeVolume !== 'number') { relativeVolume = 1.0 }

    console.debug('play() [name:%s, relativeVolume:%s]', name, relativeVolume)

    const sound = SOUNDS.get(name)

    if (!sound) { throw new Error(`unknown sound name "${name}"`) }

    try {
      sound.audio.pause()
      sound.audio.currentTime = 0.0
      sound.audio.volume = (sound.volume || 1.0) * relativeVolume
      sound.audio.loop = true
      sound.audio.play()
    } catch (error) {
      console.warn('play() | error: %o', error)
    }
  },

  stop (name) {
    console.debug('stop() [name:%s]', name)

    const sound = SOUNDS.get(name)

    if (!sound) { throw new Error(`unknown sound name "${name}"`) }

    sound.audio.pause()
    sound.audio.currentTime = 0.0
  }
}
