import request from '../utils/request'
import { getStaticInfo } from './common'
import { handleError } from './handleError'

export const getUserInfo = async () => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  const userId = userInfo?.id
  try {
    const response = await request.get(`User/${userId}`, {
      headers: {
        'x-access-site': siteId,
        Authorization: 'Bearer ' + jwtToken
      }
    })
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const changePassword = async (data) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  const userId = userInfo?.id
  try {
    const response = await request.put(
      'User/ResetPassword',
      {
        id: userId,
        ...data
      },
      {
        headers: {
          'x-access-site': siteId,
          Authorization: 'Bearer ' + jwtToken
        }
      }
    )
    return response
  } catch (error) {
    handleError(error)
  }
}

export const adminChangePassword = async (data) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  const userId = userInfo?.id
  try {
    const response = await request.put(
      'User/Admin/ResetPassword',
      {
        id: userId,
        ...data
      },
      {
        headers: {
          'x-access-site': siteId,
          Authorization: 'Bearer ' + jwtToken
        }
      }
    )
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const updateUserInfo = async (dataUpdate) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  const userId = userInfo?.id
  try {
    const response = await request.put(
      'User',
      {
        id: userId,
        ...dataUpdate
      },
      {
        headers: {
          'x-access-site': siteId,
          Authorization: 'Bearer ' + jwtToken
        }
      }
    )
    return response
  } catch (error) {
    handleError(error)
  }
}

export const getFullInfo = async (id) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.get(`User/FullInfo/${id}`, {
      headers: {
        'x-access-site': siteId,
        Authorization: 'Bearer ' + jwtToken
      }
    })
    return response.data
  } catch (error) {
    handleError(error)
  }
}
export const getAllRole = async () => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.get('Roles', {
      headers: {
        'x-access-site': siteId,
        Authorization: 'Bearer ' + jwtToken
      }
    })
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const getAllGroup = async () => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.get('Groups', {
      params: { status: 1 },
      headers: {
        'x-access-site': siteId,
        Authorization: 'Bearer ' + jwtToken
      }
    })
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const updateUserFullInfo = async (userInfoUpdate) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.put('User/FullInfo', userInfoUpdate, {
      headers: {
        'x-access-site': siteId,
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken
      }
    })
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const createEmployee = async (userInfo) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const siteId = JSON.parse(localStorage.getItem('userInfo'))?.siteId
  try {
    const response = await request.post('User/Employee', userInfo, {
      headers: {
        'x-access-site': siteId,
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken
      }
    })
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const filterEmployees = async (dataFilter) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.get('User', {
      params: dataFilter,
      headers: {
        'x-access-site': siteId,
        Authorization: 'Bearer ' + jwtToken
      }
    })
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const managerGetUser = async () => {
  const { headers, userId } = getStaticInfo()
  try {
    const response = await request.get(`User/ByManager/${userId}`, { headers })
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const lockoutUser = async (userId) => {
  const { headers } = getStaticInfo()
  try {
    const response = await request.put(`User/Lockout/${userId}`, {}, { headers })
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const unlockoutUser = async (userId) => {
  const { headers } = getStaticInfo()
  try {
    const response = await request.put(`User/Unlockout/${userId}`, {}, { headers })
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const currentUserGetStrategies = async () => {
  const { headers, userId } = getStaticInfo()
  try {
    const response = await request.get(`User/${userId}/Strategy`, { headers })
    return response.data
  } catch (error) {
    handleError(error)
  }
}
