import React from 'react'
import { Breadcrumb } from 'antd'

function StrategyBreadCrumb () {
  return (
    <Breadcrumb separator=">">
    <Breadcrumb.Item href='/'>Trang chủ</Breadcrumb.Item>
    <Breadcrumb.Item href="/Strategy">Chiến dịch</Breadcrumb.Item>
  </Breadcrumb>
  )
}

export default StrategyBreadCrumb
