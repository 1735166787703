import { CaretDownOutlined, DownloadOutlined, SearchOutlined, SortAscendingOutlined, SortDescendingOutlined, SyncOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Card, Table, Pagination, Tag, Select, Input, DatePicker } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { callStatuses } from '../../constants/callStatuses'
import { IsOrderByDescending } from '../../constants/sortType'
import { filterEmployees } from '../../services/userInfoService'
import { clearAndPushErrorMessage } from '../../slices/errorMessageSlice'
import locale from 'antd/es/date-picker/locale/vi_VN'
import { getHistoryByCurrentSite } from '../../services/callInfoService'
import { getStaticInfo } from '../../services/common'
import CallStatusTag from '../../components/Common/Tags/CallStatusTag'
import { getLinkRecord } from '../../utils/linkRecords'
import { getSiteInfo } from '../../services/siteService'
const { RangePicker } = DatePicker

function CallHistory ({ toggleReset }) {
  const [searchPhoneNumber, setSearchPhoneNumber] = useState('')
  const [employees, setEmployees] = useState([])

  const dateFormat = 'DD-MM-YYYY'
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
  const [datePickerValue, setDatePickerValue] = useState([moment(), moment()])
  const [employeeSelected, setEmployeeSelected] = useState([])
  const [selectedCallStatus, setSelectedCallStatus] = useState([])
  const [callInfoCount, setCallInfoCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(10)
  const [isLoading, setIsLoading] = useState(false)
  const [isExportLoading, setIsExportLoading] = useState(false)
  const [sortTypeSelected, setSortTypeSelected] = useState(true)
  const [dataTable, setDataTable] = useState([])
  const { Option } = Select
  const { siteId } = getStaticInfo()
  const dispatch = useDispatch()

  useEffect(() => {
    const handler = async () => {
      const STATUS_DEFAULT = -1
      const response = await filterEmployees({ status: STATUS_DEFAULT }) // get all user
      if (response.success) {
        setEmployees(response.results)
      } else {
        dispatch(clearAndPushErrorMessage(response.message))
      }
    }

    handler()
  }, [toggleReset])

  const searchCallHistory = async (filter) => {
    setIsLoading(true)
    const response = await getHistoryByCurrentSite(filter)
    if (!response.success) {
      dispatch(clearAndPushErrorMessage(response.message))
    } else {
      const { data, count } = response.result
      const siteRespose = await getSiteInfo()
      const localSiteInfo = siteRespose?.data
      // callInfoHistories = callInfoHistories.map(item => {
      //   item.isPlayAudio = false

      //   return item
      // })

      // data.forEach(element => {
      //   element.updatedAt = moment.utc(element.updatedAt).local().format('DD-MM-YYYY HH:mm:ss')
      //   element.createdAt = moment.utc(element.createdAt).local().format('DD-MM-YYYY HH:mm:ss')
      //   if (element.callioId) {
      //     element.linkRecord = await getLinkRecord(localSiteInfo, element.callioId)
      //   }
      // })

      for await (const element of data) {
        element.updatedAt = moment.utc(element.updatedAt).local().format('DD-MM-YYYY HH:mm:ss')
        element.createdAt = moment.utc(element.createdAt).local().format('DD-MM-YYYY HH:mm:ss')
        if (element.callioId) {
          element.linkRecord = await getLinkRecord(localSiteInfo, element.callioId)
        }
      }

      setCallInfoCount(count)
      setDataTable(data)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    const filter = {
      siteId,
      callStatus: selectedCallStatus,
      employeeIds: employeeSelected,
      isOrderByDescending: sortTypeSelected,
      phoneNumber: searchPhoneNumber,
      page: currentPage,
      limit: currentPageSize,
      rangeDate: {
        startTime: startDate,
        endTime: endDate
      },
      IsAnonymousCallFilter: true
    }
    searchCallHistory(filter)
  }, [searchPhoneNumber, employeeSelected, selectedCallStatus, sortTypeSelected, currentPage, currentPageSize, startDate, endDate])

  const handlePagingChange = (page, pageSize) => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }

  const onRangePickerChange = (date) => {
    setDatePickerValue(date)
    setStartDate(date[0].format('YYYY-MM-DD'))
    setEndDate(date[1].format('YYYY-MM-DD'))
  }

  const refresh = () => {
    setSearchPhoneNumber('')
    setEmployeeSelected([])
    setSelectedCallStatus([])
    setSortTypeSelected(true)
    setCurrentPage(1)
    setCurrentPageSize(10)
    setStartDate(moment().format('YYYY-MM-DD'))
    setEndDate(moment().format('YYYY-MM-DD'))
    setDatePickerValue([moment(), moment()])
  }

  const callHistoryTableColumns = [
    {
      title: 'Số điện thoại',
      key: 'phoneNumber',
      render: (record) => {
        let phoneNumber = record.phoneNumber
        if (!phoneNumber) {
          phoneNumber = record.customerPhoneNumber
        }

        return <p>{phoneNumber}</p>
      }
    },
    {
      title: 'Trạng thái',
      render: (record) => {
        return (
          <CallStatusTag callStatusValue={record.status} />
        )
      },
      key: 'callStatus'
    },
    {
      title: 'Thời gian đàm thoại',
      key: 'durations',
      render: (record) => {
        if (record.durations < 3600) {
          return new Date(record.durations * 1000).toISOString().substring(14, 19)
        } else { return <p>{new Date(record.durations * 1000).toISOString().slice(11, 19)}</p> }
      }
    },
    {
      title: 'Thời gian gọi',
      dataIndex: 'createdAt',
      key: 'createdAt'
    },
    {
      title: 'Nhân viên gọi',
      dataIndex: 'employeeName',
      key: 'employeeName'
    },
    {
      title: 'Ghi âm cuộc gọi',
      key: 'linkRecord',
      render: (record) => {
        return record.linkRecord && record.linkRecord !== '_'
          ? <div>
            Tải file ghi âm: <a href={record.linkRecord} target='blank' rel="noreferrer" ><DownloadOutlined /></a>
            <audio className='w-full' autoPlay={false} src={record.linkRecord} controls />
          </div>
          : <p>---</p>
      }
    },
    {
      title: 'Chú ý',
      dataIndex: 'note',
      key: 'note'
    }
  ]

  return (

    <div>
      <Card title="Lịch sử cuộc gọi">

        <div className="">
          <div className="flex flex-col flex-end items-end mb-2">
            <div className="tablet:mb-1  w-full flex flex-col tablet:justify-end tablet:flex-row tablet:items-start">
              <Input
                value={searchPhoneNumber}
                placeholder='Nhập số điện thoại cần tìm kiếm ...'
                suffix={<SearchOutlined />}
                className='mb-2 tablet:mr-1 tablet:w-[250px]'
                onChange={(e) => setSearchPhoneNumber(e.target.value)} />
              <Select
                maxTagCount={1}
                className='tablet:mr-1 mb-2 tablet:min-w-[250px]'
                placeholder="Tất cả nhân viên"
                suffixIcon={<UserOutlined />}
                value={employeeSelected}
                mode="multiple"
                allowClear={true}
                showArrow={true}
                onChange={(value) => {
                  setEmployeeSelected(value)
                }}>
                {
                  employees?.map((item) => (
                    <Option value={item.id} key={item.id}>
                      <div className="">
                        <div className="font-bold">{item.name}</div>
                        {item.roleInfo?.title && <Tag color="green" className='text-[12px]'>{item.roleInfo?.title}</Tag>}
                      </div>
                    </Option>
                  ))
                }
              </Select>
              <Select
                className='mb-2 tablet:mr-1 tablet:min-w-[250px]'
                value={selectedCallStatus}
                placeholder="Tất cả trạng thái"
                mode="multiple"
                allowClear={true}
                suffixIcon={<CaretDownOutlined />}
                onChange={(value) => {
                  setSelectedCallStatus(value)
                }}>
                {
                  callStatuses.map((item) => (
                    <Option value={item.value} key={item.value}>{item.title}</Option>
                  ))
                }
              </Select>
            </div>
            <div className="flex w-full tablet:w-auto flex-col tablet:flex-row tablet:items-center items-end">
              <RangePicker
                className='w-full tablet:w-auto'
                placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                format={dateFormat}
                value={datePickerValue}
                onChange={onRangePickerChange} showToday={true} locale={locale} />

              <div className="mt-2 tablet:mt-0 w-full tablet:w-auto flex justify-end">
                <Select
                  className='tablet:ml-1 mr-1 tablet:min-w-[150px] grow '
                  value={sortTypeSelected}
                  suffixIcon={sortTypeSelected ? <SortDescendingOutlined /> : <SortAscendingOutlined />}
                  onChange={(value) => {
                    setSortTypeSelected(value)
                  }}>
                  {
                    IsOrderByDescending.map((item, key) => (
                      <Option value={item.value} key={key}>{item.title}</Option>
                    ))
                  }
                </Select>

                <Button type='primary' onClick={() => refresh()} >
                  <SyncOutlined />
                </Button>
                {/* <Button loading={isExportLoading} type='primary' onClick={() => { }} className="ml-1">
                  <DownloadOutlined />
                </Button> */}
              </div>
            </div>

          </div>
          <Table
            columns={callHistoryTableColumns}
            dataSource={dataTable}
            loading={isLoading}
            // scroll={{
            //   x: '80vh',
            //   y: '62vh'
            // }}
            pagination={false}
          />

          <Pagination
            className="text-right mt-8"
            total={callInfoCount}

            showSizeChanger
            showQuickJumper
            showTotal={(total) => `Tổng ${total} cuộc gọi`}
            defaultCurrent={currentPage}
            defaultPageSize={currentPageSize}
            pageSize={currentPageSize}
            current={currentPage}
            onChange={handlePagingChange}
          />
        </div>

      </Card>
    </div>
  )
}

export default CallHistory
