import React, { useEffect } from 'react'
import { Card, Alert } from 'antd'

function Permissions ({ tabIndex, setTabIndex }) {
  useEffect(() => {
    if (tabIndex === 3) { // 3 is index of this tag
      setTabIndex()
    }
  })

  return (
    <Card title={<h3>Nhóm quyền người dùng</h3>}>
      <div>
        <Alert type='info'
          message={
            <>
              <p>Thiết lập quyền, sau đó gán quyền cho người dùng để cho phép những người dùng nào được thực hiện hành động gì.</p>
              <p>CallMaster đã có sẵn 3 quyền mặc định Chủ tài khoản, Trưởng nhóm và Nhân viên.</p>
              <p>Bạn có thể thiết lập các quyền tùy biến khác để phù hợp với mô hình và cách hoạt động của doanh nghiệp bạn</p>
              <p>Bấm vào biểu tượng sao chép ở một trong 3 quyền mặc định dưới đây để tiến hành thêm mới quyền theo ý bạn
              </p>
            </>
          } />
      </div>
      <h2 className='mt-8'>Danh sách quyền</h2>
    </Card>
  )
}

export default Permissions
