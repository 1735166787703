import React, { useEffect, useRef, useState } from 'react'
import FormLogin from './formLogin'
import httpResponseStatus from '../../constants/httpResponseStatus'
import { useLocation } from 'react-router-dom'
import { notification } from 'antd'

function Login () {
  // const [searchParams, setSearchParams] = useSearchParams()
  // const [statusError, setStatusError] = useState(searchParams.get('statusError'))

  function useQuery () {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  const query = useQuery()

  const openErrorNotification = (description, key) => {
    notification.error({
      key,
      message: 'Có lỗi xẩy ra',
      description
    })
  }

  useEffect(() => {
    const statusError = query.get('statusError')
    if (statusError) {
      for (const [key, value] of Object.entries(httpResponseStatus)) {
        if (value.statusCode.toString() === statusError) {
          const message = value.message
          openErrorNotification(message)
        }
      }
    }
  }, [])

  return (
    <div>
      <section className="h-screen flex justify-center items-center">
        <div className="container px-6 py-12 h-full ">
          <div className="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">
            <div className="md:w-8/12 tablet:w-6/12 mb-12 md:mb-0">
              <img
                src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
                className="w-full"
                alt="Phone image"
              />
            </div>
            <div className=" w-full  md:w-8/12 tablet:w-5/12 tablet:ml-20">
              <FormLogin />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Login
