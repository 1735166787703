export default {
  USER: 'User',
  MANAGER: 'Manager',
  EMPLOYEE: 'Employee',
  CLIENT: 'Client',
  SUPERADMIN: 'SuperAdmin'
}

export const SUPERADMIN = 'SuperAdmin'
export const MANAGER = 'Manager'
export const EMPLOYEE = 'Employee'
export const CLIENT = 'Client'
export const ANHCAO = 'anh.cao'
export const TECHNICAL = 'Technical'
