import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Col, Row, Card, Input, Button, Modal, Form } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import * as userService from '../../../services/userInfoService'
import { useDispatch } from 'react-redux'

import { clearAndPushErrorMessage } from '../../../slices/errorMessageSlice'
import { checkIsEmployee } from '../../../permissions'
function AccountInfo ({ tabIndex, setTabIndex }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const [isEditUserInfo, setIsEditUserInfo] = useState(false)
  const [isEditCompany, setIsEditCompany] = useState(false)
  const [isModalState, setIsModalChangePasswordState] = useState(false)
  const [userInfo, setUserInfo] = useState()
  const [isModalChangePasswordSuccess, setIsModalChangePasswordSuccess] =
    useState(false)

  const [form] = Form.useForm()

  const [companyInfo, setCompanyInfo] = useState({
    name: '',
    email: '',
    phoneNumber: ''
  })

  const getUserInfo = async () => {
    const response = await userService.getUserInfo()
    const userInfo = response?.userInfo
    const companyInfo = response?.companyInfo
    setUserInfo(userInfo)
    setCompanyInfo(companyInfo)
  }

  useEffect(() => {
    getUserInfo()
  }, [isEditUserInfo])

  useEffect(() => {
    if (tabIndex === 1) { // 1 is index of this tag
      console.log(1111)
      getUserInfo()
      setTabIndex()
    }
  })

  const updateUserInfo = async (values) => {
    const response = await userService.updateUserInfo(values)
    if (response.status === 200) {
      if (response.data.isSuccess) {
        setIsEditUserInfo(false)
      } else {
        dispatch(clearAndPushErrorMessage(response.errorMessage))
      }
    } else {
      setIsEditUserInfo(false)
      dispatch(clearAndPushErrorMessage(response.errorMessage))
    }
  }
  const updateCompanyInfo = (values) => {
    setCompanyInfo(values)
    setIsEditCompany(false)
  }

  const changePassword = async (value) => {
    const data = {
      password: value.password,
      currentPassword: value.currentPassword
    }
    const response = await userService.changePassword(data)

    if (response.status === 200) {
      if (response.data.success) {
        setIsModalChangePasswordSuccess(true)
        setIsModalChangePasswordState(false)
      } else {
        dispatch(clearAndPushErrorMessage(response.errorMessage))
      }
    } else {
      setIsEditUserInfo(false)
      dispatch(clearAndPushErrorMessage(response.errorMessage))
    }
  }

  const redirectLoginPage = () => {
    localStorage.removeItem('jwtToken')
    localStorage.removeItem('userInfo')
    history.push('/login')
  }

  const showUserInfo = () => (
    <Row className='p-2 pt-4'>
      <Col xs={10} lg={4}>
        <p className="laptop:pb-4">Tên</p>
        <p className="laptop:pb-4">Mật khẩu</p>
        <p className="laptop:pb-4">Email</p>
        <p className="laptop:pb-4">Số điện thoại</p>
      </Col>
      <Col xs={13} lg={20}>
        <p className="laptop:pb-4">{userInfo?.fullName}</p>
        <p className="laptop:pb-4">
          <a onClick={() => setIsModalChangePasswordState(true)}>
            Đổi mật khẩu
          </a>
        </p>
        <Modal
          title="Đổi mật khẩu!"
          forceRender
          open={isModalState}
          footer={null}
          onCancel={() => setIsModalChangePasswordState(false)}
        >
          <Form
            name="basic"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 18 }}
            onFinish={changePassword}
            autoComplete="on"
          >
            <Form.Item
              label="Mật khẩu cũ"
              name="currentPassword"
              rules={[
                { required: true, message: 'Mật khẩu không hợp lệ', min: 8 }
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="password"
              label="Mật khẩu mới"
              rules={[
                {
                  required: true,
                  message: 'Không được để trống',
                  min: 8
                }
                // {
                //   pattern:
                //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                //   message: 'Mật khẩu không hợp lệ'
                // }
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="current"
              label="Nhập lại mật khẩu"
              dependencies={['password']}
              rules={[
                {
                  required: true
                },
                ({ getFieldValue }) => ({
                  validator (rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error('Mật khẩu không trùng khớp')
                    )
                  }
                })
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item className="flex justify-end">
              <Button
                danger
                className="mr-5"
                onClick={() => setIsModalChangePasswordState(false)}
              >
                Huỷ
              </Button>
              <Button type="primary" htmlType="submit">
                Lưu lại
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <p className="laptop:pb-4">{userInfo?.email}</p>
        <p className="laptop:pb-4">{userInfo?.phoneNumber}</p>
      </Col>
    </Row>
  )
  const showFormEditUserInfo = () => (
    <Form
      form={form}
      name="control-hooks"
      initialValues={userInfo}
      onFinish={updateUserInfo}
    >
      <Form.Item
        name="fullName"
        label="Tên"
        labelAlign="left"
        labelCol={{ span: 5, offset: 0 }}
        rules={[{ required: true, message: 'Tên không được để trống!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        labelAlign="left"
        labelCol={{ span: 5, offset: 0 }}
        rules={[{ required: true, message: 'Email không được để trống!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="phoneNumber"
        label="Số điện thoại"
        labelAlign="left"
        labelCol={{ span: 5, offset: 0 }}
        rules={[
          { required: true, message: 'Số điện thoại không được để trống!' }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item className="flex justify-center">
        <Button type="primary" htmlType="submit">
          Lưu lại
        </Button>
        <Button
          className="ml-8"
          type="primary"
          danger
          htmlType="button"
          onClick={() => setIsEditUserInfo(false)}
        >
          Huỷ
        </Button>
      </Form.Item>
    </Form>
  )

  const editModeUserInfo = () => {
    if (!isEditUserInfo) {
      return showUserInfo()
    } else {
      return showFormEditUserInfo()
    }
  }

  const showCompanyInfo = () => (
    <Row>
          <Col xs={10} lg={4}>
            <p className="laptop:pb-4">Tên công ty</p>
            <p className="laptop:pb-4">Email</p>
            <p className="laptop:pb-4">Số điện thoại</p>
          </Col>
          <Col xs={13} lg={21}>
            <p className="laptop:pb-4">{companyInfo?.name}</p>
            <p className="laptop:pb-4">{companyInfo?.email}</p>
            <p className="laptop:pb-4">{companyInfo?.phoneNumber}</p>
          </Col>
        </Row>
  )
  const showFormEditCompanyInfo = () => (<Form
    form={form}
    initialValues={companyInfo}
    onFinish={updateCompanyInfo}
  >
    <Form.Item
      name="name"
      label="Tên"
      labelAlign="left"
      labelCol={{ span: 5, offset: 0 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="email"
      label="Email"
      labelAlign="left"
      labelCol={{ span: 5, offset: 0 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="phoneNumber"
      label="Số điện thoại"
      labelAlign="left"
      labelCol={{ span: 5, offset: 0 }}
    >
      <Input />
    </Form.Item>
    <Form.Item className="flex justify-center">
      <Button type="primary" htmlType="submit">
        Lưu lại
      </Button>
      <Button
        className="ml-8"
        type="primary"
        danger
        htmlType="button"
        onClick={() => setIsEditCompany(false)}
      >
        Huỷ
      </Button>
    </Form.Item>
  </Form>)
  const editModeCompany = () => {
    if (!isEditCompany) {
      return showCompanyInfo()
    } else {
      return showFormEditCompanyInfo()
    }
  }
  return (
    <div>
      <Modal
        title="Thông báo!"
        onOk={redirectLoginPage}
        open={isModalChangePasswordSuccess}
        footer={[
          <Button key="submit" type="primary" onClick={redirectLoginPage}>
            Xác nhận
          </Button>
        ]}
      >
        <p>Đổi mật khẩu thành công, vui lòng đăng nhập lại</p>
      </Modal>
      <div className="tablet:grid tablet:grid-cols-2 tablet:gap-4">
        <div className="mb-4 h-full">
          <Card
            size='small'
            className="tablet:h-full"
            title="Thông tin cá nhân"
            extra={
              !isEditUserInfo && (
                <div className="text-white">
                  <EditOutlined
                    className=" p-1 bg-gradient-to-r from-[#1990FF]  to-[#001628] cursor-pointer  edit-outline rounded-[50%]"
                    onClick={() => {
                      if (!checkIsEmployee()) setIsEditUserInfo(true)
                    }}
                  />
                </div>
              )
            }
          >
            {editModeUserInfo()}
          </Card>
        </div>
        {/* <div className="mb-4 h-full">
          <Card
            className="tablet:h-full"
            title="Thông tin công ty"
            extra={
              !isEditCompany && (
                <div className="text-white">
                  <EditOutlined
                    className=" p-1 bg-gradient-to-r from-[#1990FF]  to-[#001628] cursor-pointer  edit-outline rounded-[50%]"
                    onClick={() => setIsEditCompany(true)}
                  />
                </div>
              )
            }
          >
            {editModeCompany()}
          </Card>
        </div> */}
      </div>
    </div>
  )
}

export default AccountInfo
