import React, { useEffect, useState, useRef } from 'react'
import JsSIP from 'jssip'
import { Button, Modal, Form, Input, Select, DatePicker, Col, Row } from 'antd'
import { useDispatch } from 'react-redux'
import { clearAndPushErrorMessage, clearAndPushSuccessMessage, pushErrorMessage } from '../../slices/errorMessageSlice'
import moment from 'moment'
import { Prompt } from 'react-router'
import { updateCallStrategyFail, updateCallInfoFreedom } from '../../services/callStrategyService'
import StopWatch from '../../components/Common/Stopwatch'
import { getLinePhoneByCurrentUser } from '../../services/linePhoneService'
import { callStatuses, FAILD, OTHER, RECALL, REJECT } from '../../constants/callStatuses'
import CallHistory from './CallHistory'
import * as signalR from '@microsoft/signalr'
import { getCurrentUserId, getStaticInfo } from '../../services/common'
import { OUTBOUND } from '../../constants/callDirection'
import { createCallInfo } from '../../services/callInfoService'

function Home () {
  const [linePhone, setLinePhone] = useState()
  const [phoneNumber, setPhoneNumber] = useState('')
  const [remoteAudio] = useState(new Audio())
  const [modalState, setModalState] = useState(false)
  const [callInfoId, setCallInfoId] = useState()
  const [callState, setCallState] = useState(true)
  const [callDuration, setCallDuration] = useState(0)
  const [defaultCallStatus, setDefaultCallState] = useState(0)
  const [readyCall, setReadyCall] = useState(false)
  const [isCallBtnLoading, setIsCallBtnLoading] = useState(false)
  const [callInfoCreated, setCallInfoCreated] = useState()
  const [isLoadingUpdateCallInfo, setIsLoadingUpdateCallInfo] = useState(false)
  const [toggleReset, setToggleReset] = useState(false)
  const [isStartStopWatch, setIsStartStopWatch] = useState(false)
  const [isResetStopWatch, setIsResetStopWatch] = useState(false)
  const sessionRef = useRef()
  const startCallTime = useRef()
  const isDontPickUp = useRef()
  const uaRef = useRef()
  const dispatch = useDispatch()
  const { Option } = Select
  const [form] = Form.useForm()
  const { TextArea } = Input
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const employeeId = userInfo?.id
  const waitingToReport = useRef(false)
  const [triggerCallFaild, setTriggerCallFaild] = useState(false)
  const [trigglerCallEnd, setTrigglerCallEnd] = useState(false)
  const isFirstTimeFaildRef = useRef(true)
  const isFirstTimeEndRef = useRef(true)
  const [faildData, setFaildData] = useState({})
  const MAX_HANGUP_TIME = 22 // durations

  useEffect(() => {
    if (!isFirstTimeFaildRef.current) {
      const handler = async () => {
        const data = faildData
        console.log('failed data', data)

        let status = OTHER.value
        let faildCause = data.cause

        if (data.cause === JsSIP.C.causes.CANCELED && data.originator === 'local') { // Nhân viên tắt máy
          status = FAILD.value
          faildCause = 'BUSY'
        }

        await updateCallStrategyFail({
          phoneNumber,
          userId: getCurrentUserId(),
          status,
          faildCause,
          callInfoId
        })

        setCallState(true)
        setIsCallBtnLoading(false)

        setIsStartStopWatch(false)
        setIsResetStopWatch(true)

        // setToggleReset(!toggleReset)
      }

      handler()
    } else {
      isFirstTimeFaildRef.current = false
    }
  }, [triggerCallFaild])

  // useEffect(() => {
  //   if (!isFirstTimeEndRef.current) {
  //     console.log('endddd')
  //     const handler = async () => {
  //       setIsStartStopWatch(false)
  //       setIsResetStopWatch(true)
  //       waitingToReport.current = true

  //       const userId = getCurrentUserId()

  //       if (isDontPickUp.current) {
  //         console.log('Cuộc gọi kết thúc, khách hàng không bắt máy')
  //         dispatch(clearAndPushNotifyMessage('Cuộc gọi kết thúc, khách hàng không bắt máy'))

  //         await createCallStrategyFail({
  //           userId,
  //           phoneNumber,
  //           status: FAILD.value,
  //           faildCause: 'CUSTOMER_DONT_PICK_UP'
  //         })
  //       } else {
  //         console.log('Cuộc gọi kết thúc')

  //         const durations = (sessionRef.current.end_time - sessionRef.current.start_time) / 1000
  //         setCallDuration(durations)
  //         const response = await createCallStrategySuccess({
  //           phoneNumber,
  //           userId,
  //           durations
  //         })
  //         if (response.success) {
  //           const callInfo = response.callInfo
  //           setCallInfoId(callInfo?.callId)
  //           setCallInfoCreated(moment(Date.parse(callInfo?.createdAt)).add(-1, 'minutes').format('DD/MM/yyyy hh:mm:ss'))
  //           dispatch(clearAndPushSuccessMessage('Cuộc gọi kết thúc'))
  //           setDefaultCallState(REJECT.value)
  //           setModalState(true)
  //         } else {
  //           dispatch(clearAndPushErrorMessage(`Có lỗi xẩy ra khi tạo thông tin cuộc gọi cho ${phoneNumber}`))
  //         }
  //       }
  //       setCallState(true)
  //       // setReadyCall(true)
  //       setIsCallBtnLoading(false)
  //       // setToggleReset(!toggleReset)
  //     }

  //     handler()
  //   } else {
  //     isFirstTimeEndRef.current = false
  //   }
  // }, [trigglerCallEnd])

  useEffect(() => {
    const handler = async () => {
      const response = await getLinePhoneByCurrentUser()
      if (!response.success) {
        dispatch(clearAndPushErrorMessage(response.message))
      } else {
        const { linePhone } = response
        if (linePhone) {
          setLinePhone(linePhone)
          const siteInfo = JSON.parse(localStorage.getItem('siteInfo'))

          const socket = new JsSIP.WebSocketInterface(siteInfo?.socketServer)
          try {
            socket.via_transport = 'WSS'

            const uaConfig = {
              uri: `sip:${linePhone?.extension}@${siteInfo?.uriSuffix}`,
              password: `${linePhone?.secret}`
            }

            if (siteInfo?.uriSuffix === 'cloud.telebot.vn') {
              socket.via_transport = 'WS'
              uaConfig.contact_uri = `sip:${linePhone?.extension}@${siteInfo?.uriSuffix}`
              uaConfig.registrar_server = siteInfo?.uriSuffix
              uaConfig.authorization_user = linePhone?.extension
              uaConfig.session_timers = false
            }

            uaConfig.sockets = [socket]
            const ua = new JsSIP.UA(uaConfig)

            uaRef.current = ua
          } catch (error) {
            dispatch(clearAndPushErrorMessage('Lỗi kết nối sip' + JSON.stringify(error)))
          }

          uaRef.current.on('connecting', () => {
            console.log('Ua connecting...')
          })

          uaRef.current.on('connected', () => {
            console.log('Ua connected')
          })

          uaRef.current.on('disconnected', () => {
            console.log('Ua home disconnected...')
            setReadyCall(false)
          })

          uaRef.current.on('registered', () => {
            console.log('Ua registered!!!')
            setCallState(true)
            setReadyCall(true)
          })

          uaRef.current.on('unregistered', () => {
            console.log('Ua home unregistered!!!')
          })

          uaRef.current.on('registrationFailed', (data) => {
            setReadyCall(false)

            console.log('registrationFailed', data)
          })

          uaRef.current.on('newRTCSession', (data) => {
            console.log('newRTCSession', data)
          })

          uaRef.current.start()
        }
      }
    }

    handler()

    return () => {
      uaRef.current?.stop()
    }
  }, [])

  const eventHandlers = {
    progress: function (e) {
      startCallTime.current = moment()
      setCallState(false)
      setIsCallBtnLoading(false)
      console.log('call is in progress', e)
    },
    failed: function (data) {
      setFaildData(data)
      setTriggerCallFaild(!triggerCallFaild)
    },
    ended: function (e) {
      setIsStartStopWatch(false)
      setIsResetStopWatch(true)
      const durations = (sessionRef.current.end_time - sessionRef.current.start_time) / 1000
      setCallDuration(durations)
      dispatch(clearAndPushSuccessMessage('Cuộc gọi kết thúc'))
      setDefaultCallState(REJECT.value)
      setModalState(true)
      setCallState(true)
      setIsCallBtnLoading(false)
      // setTrigglerCallEnd(!trigglerCallEnd)
    },
    confirmed: function (e) {
      // console.log('confirmed data', e)

      // const hangupTime = moment().diff(startCallTime.current, 'seconds')
      // console.log('hangupTime', hangupTime)
      // if (hangupTime < MAX_HANGUP_TIME) {
      //   console.log('Đã kết nối tới số máy khách hàng')
      //   isDontPickUp.current = false
      // } else {
      //   isDontPickUp.current = true
      //   endTheCall()
      // }
    },
    accepted: function (e) {
      setIsStartStopWatch(true)
      setIsResetStopWatch(false)
    },
    peerconnection: function (e) {
      const peerconnection = e.peerconnection
      const remoteStream = new MediaStream()

      peerconnection.ontrack = function (e) {
        peerconnection.getReceivers().forEach(function (receiver) {
          remoteStream.addTrack(receiver.track)
        })
        remoteAudio.volume = 1
        remoteAudio.srcObject = remoteStream
        remoteAudio.play()
      }
    }
  }

  const callCustomer = async () => {
    const handler = async () => {
      setIsCallBtnLoading(true)
      const siteInfo = JSON.parse(localStorage.getItem('siteInfo'))
      const { siteId, userId } = getStaticInfo()

      const callReq = {
        EmployeeId: userId,
        siteId,
        callDirection: OUTBOUND.value,
        phoneNumber
      }

      const response = await createCallInfo(callReq)
      if (!response.success) {
        dispatch(pushErrorMessage(response.message))

        return
      }

      const { callId } = response
      setCallInfoId(callId)

      const RTCsession = uaRef.current.call(`sip:${phoneNumber}@${siteInfo?.uriSuffix}`,
        {
          mediaConstraints: { audio: true, video: false },
          sessionTimersExpires: 3600,
          eventHandlers
        })

      RTCsession.on('connecting', (data) => {
        console.log('connecting...', data)
        sessionRef.current = RTCsession
      })
    }

    handler()
  }

  const endTheCall = () => {
    sessionRef.current.terminate()
  }

  const handleUpdateCallInfo = async (callInfo) => {
    setIsLoadingUpdateCallInfo(true)

    if (callInfo.status === RECALL.value && !callInfo.callLaterTime) {
      dispatch(clearAndPushErrorMessage('Vui lòng chọn thời gian khách hẹn gọi lại'))
    } else {
      const callFullInfo = {
        employeeId,
        phoneNumber,
        startTime: sessionRef.current.start_time,
        endTime: sessionRef.current.end_time,
        ...callInfo
      }
      const response = await updateCallInfoFreedom(callInfoId, callFullInfo)

      if (response.success) {
        dispatch(clearAndPushSuccessMessage('Cập nhật thành công'))
        form.resetFields()
      } else {
        dispatch(clearAndPushErrorMessage(response.message))
      }
      setIsCallBtnLoading(false)
      setModalState(false)
      setIsLoadingUpdateCallInfo(false)

      if (!readyCall) {
        waitingToReport.current = false
        uaRef.current.register()
      }
    }
  }

  return (
    <div>
      <Modal
        title="Thông tin cuộc gọi"
        open={modalState}
        closable={false}
        cancelText={false}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        maskClosable={false}
      >
        <Form
          name="Basic"
          layout="vertical"
          form={form}
          onFinish={handleUpdateCallInfo}
          fields={[
            {
              name: ['durations'],
              value: callDuration
            }
          ]}
        >
          <Form.Item
            label="Kết quả"
            name="status"
            rules={[
              {
                required: true,
                message: 'Đây là trường bắt buộc' // thêm rule cho type
              }
            ]}>
            <Select onChange={(value) => {
              setDefaultCallState(value)
            }}>
              {callStatuses.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {
            defaultCallStatus === RECALL.value
              ? <Form.Item label="Thời gian khách hẹn gọi lại" name="callLaterTime">
                <DatePicker />
              </Form.Item>
              : <></>
          }
          <Form.Item label="Thời gian gọi" name="durations">
            <Input readOnly />
          </Form.Item>

          <Form.Item label="Ghi chú" name="note">
            <TextArea />
          </Form.Item>

          <Form.Item >
            <Button loading={isLoadingUpdateCallInfo} type="primary" htmlType="submit">
              Hoàn thành
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Row gutter={10}>
        <Col span={4}>
          <Input type='number' className='mb-2' placeholder='Nhập số điện thoại để gọi' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
          {
            callState
              ? <div>
                <Button
                  type="primary"
                  loading={isCallBtnLoading}
                  onClick={callCustomer}
                  disabled={!readyCall || !phoneNumber}
                >
                  Gọi
                </Button>

              </div>
              : <Button
                type="primary"
                onClick={() => endTheCall(480)}
              >
                Kết thúc cuộc gọi
              </Button>
          }

          <StopWatch isReset={isResetStopWatch} isStart={isStartStopWatch} />
          <Prompt
            when={!callState}
            message="Cuộc gọi đang diễn ra, bạn vẫn muốn rời đi?"
          />

        </Col>
        <Col span={20}><CallHistory toggleReset={toggleReset} /></Col>
      </Row>

    </div>
  )
}

export default Home
