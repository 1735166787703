import React, { useEffect, useState } from 'react'
import { Menu, Dropdown, Button, Avatar } from 'antd'
import { Link } from 'react-router-dom'

function UserInfo () {
  const [email, setEmail] = useState('')
  const [fullName, setFullName] = useState('')
  const [isLogin, setIsLogin] = useState(false)

  function logoutHandle () {
    localStorage.removeItem('jwtToken')
    localStorage.removeItem('userInfo')
    window.location.replace('/login')
  }

  const menu = (
    <Menu>
      {/* <p>Version: 110301</p> */}
      <Menu.Item>
        <Link to="/User/Profile">Cài đặt</Link>
      </Menu.Item>
      <Menu.Item onClick={logoutHandle}>Đăng xuất</Menu.Item>
    </Menu>
  )

  const checkLogin = () => {
    const token = localStorage.getItem('jwtToken')
    const userInfo = localStorage.getItem('userInfo')
    if (!token || !userInfo) {
      setIsLogin(false)
    } else {
      setIsLogin(true)
    }
  }

  useEffect(() => {
    checkLogin()
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo) {
      setEmail(userInfo.email)
      setFullName(userInfo.fullName)
    }
  })

  const authenLoginUI = () => {
    if (isLogin) {
      return (
        <div>
          <Dropdown overlay={menu} className="w-full flex">
            <div className=" hidden tabletmini:block">
              <div>
                <h4 className="text-white">{fullName}</h4>
              </div>
              <div>
                <div className="prose-sm text-white">{email}</div>
              </div>
            </div>
          </Dropdown>
        </div>
      )
    }
  }
  return <>{authenLoginUI()}</>
}

export default UserInfo
