import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import * as strategyService from '../../services/strategyService'
import { Table, Pagination, Tag, Button, Switch, Popconfirm, Spin } from 'antd'
import { useDispatch } from 'react-redux'
import { clearAndPushErrorMessage, clearAndPushSuccessMessage, clearEntryMessage } from '../../slices/errorMessageSlice'
import { setCurrent } from '../../slices/currentStepSlice'
import FilterStrategy from './Components/FilterStrategy'
import Report from './Components/Report'
import CallHistory from './Components/CallHistory'
import { canChangeStrategyStatus, canCopyCustomer, canViewContactListButton, canViewSettingStrategyButton, checkIsEmployee, checkIsSuperAdminAndManager, checkIsSupperAdmin } from '../../permissions'
import moment from 'moment'

function Strategy () {
  const [dataSource, setDataSource] = useState([])
  const [countStrategy, setCountStrategy] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(10)
  const [currentStrategyName, setCurrentStrategyName] = useState('')
  const [currentStrategyId, setCurrentStrategyId] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [openReportModal, setOpenReportModal] = useState(false)
  const [toggleReload, setToggleReload] = useState(false)
  const [openCallHistoryModal, setOpenCallHistoryModal] = useState(false)
  const [isLoadingCopy, setIsCopyLoading] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const userId = JSON.parse(localStorage.getItem('userInfo'))?.id
  const dispatch = useDispatch()
  const didMountOnloadStrategyRef = useRef(false)

  const DEFAULT_STATUS = 1
  const [textSearch, setTextSearch] = useState()
  const [statusSearch, setStatusSearch] = useState(DEFAULT_STATUS)
  const [toggleData, setToggleData] = useState(false)
  const [strategyType, setStrategyType] = useState()

  useEffect(() => {
    return () => {
      dispatch(clearEntryMessage())
    }
  }, [])

  useEffect(() => {
    if (didMountOnloadStrategyRef.current) {
      getStrategy()
    }

    didMountOnloadStrategyRef.current = true
  }, [currentPage, currentPageSize, toggleReload])

  const onClickSetting = () => {
    dispatch(setCurrent(0))
  }

  const onClickCustomerList = () => {
    dispatch(setCurrent(4))
  }

  const getStrategy = async () => {
    setIsLoading(true)
    const params = {
      limit: currentPageSize,
      page: currentPage,
      name: textSearch,
      status: statusSearch
    }
    const response = await strategyService.getStrategyByUserId(userId, params)
    if (response.success) {
      setCountStrategy(response.data?.count)
      const isUserHaveLinePhone = response.data?.isUserHasLinePhone

      const data = response.data?.strategyGeneralInfos?.map((item) => {
        const strategyCurrentCallInfo = item?.strategyCurrentCallInfo

        return {
          key: item.id,
          switch: <Switch disabled={!canChangeStrategyStatus()} defaultChecked checked={item.isActive} onChange={(checked) => statusSwitchOnChange(checked, item.id)} />,
          name: item?.name,
          createdAt: moment.utc(item?.createdAt).local().format('DD-MM-YYYY HH:mm:ss'),
          operation: (
            <div className="flex justify-end flex-wrap">
              {
                canViewSettingStrategyButton() &&
                <Button type="primary" className="ml-1 mb-1">
                  <Link
                    to={{
                      pathname: '/strategy/strategyDetail',
                      search: `?strategyId=${item?.id}`
                    }}
                    onClick={onClickSetting}
                  >
                    Cài đặt
                  </Link>
                </Button>
              }

              {isUserHaveLinePhone && item.isActive && (
                <Button type="primary" className="ml-1 mb-1">
                  <Link
                    to={{
                      pathname: '/strategy/callStrategy',
                      search: `?strategyId=${item?.id}`
                    }}
                  >
                    Gọi ngay
                  </Link>
                </Button>
              )}
              <Button
                type="primary"
                className="ml-1 mb-1"
                onClick={() => {
                  setCurrentStrategyName(item?.name)
                  handleStrategyReport(item?.id)
                }}
              >
                Báo cáo
              </Button>
              <Button
                type="primary"
                className="ml-1 mb-1"
                onClick={() => {
                  const handler = async () => {
                    setCurrentStrategyName(item?.name)
                    handleStrategyHistory(item?.id)
                    setStrategyType(item.strategyType)
                  }

                  handler()
                }}
              >
                Lịch sử gọi
              </Button>
              {
                canViewContactListButton() &&
                <Button type="primary" className="ml-1 mb-1">
                  <Link
                    to={{
                      pathname: '/strategy/strategyDetail',
                      search: `?strategyId=${item?.id}`
                    }}
                    onClick={onClickCustomerList}
                  >
                    Danh sách liên hệ
                  </Link>
                </Button>
              }
              {
                canCopyCustomer() &&
                <Popconfirm title='Bạn có muốn sao chép chiến dịch này không?' onConfirm={() => onCopyStrategy(item.id)}>
                  <Button loading={isLoadingCopy} type="primary" className="ml-1 mb-1">
                    Sao chép
                  </Button>
                </Popconfirm>

              }
            </div>
          ),
          status: item.isActive
            ? (
              <Tag color="success">Đang hoạt động</Tag>
              )
            : (
              <Tag color="error">Đã dừng hoạt động</Tag>
              ),
          generalInfo: (
            <div>
              {
                !checkIsEmployee() &&
                <>
                  <div>
                    Tổng số liên hệ: {strategyCurrentCallInfo?.totalContact}
                  </div>

                  <div>Đã hoàn thành: {strategyCurrentCallInfo?.completed}</div>

                  <div>Chưa hoàn thành: {strategyCurrentCallInfo?.inCompleted}</div>
                </>
              }

              <div>
                Sẵn sàng để gọi: {strategyCurrentCallInfo?.countReadyCall}
              </div>

              {
                !checkIsEmployee() &&
                <>
                  <div>
                    Đợi đến lượt gọi lại:{' '}
                    {strategyCurrentCallInfo?.waitingRecallCount}
                  </div>
                  <div>
                    Đang trong cuộc gọi hoặc chưa báo cáo:{' '}
                    {strategyCurrentCallInfo?.busyCount}
                  </div>
                  <div>
                    Số tổng đài viên: {strategyCurrentCallInfo?.countEmployee}
                  </div>
                </>
              }

            </div>
          )
        }
      })

      setIsLoading(false)
      setDataSource(data)
    } else {
      dispatch(clearAndPushErrorMessage(response.errorMessage))
    }
  }

  useEffect(() => {
    if (didMountOnloadStrategyRef.current) {
      getStrategy()
    }

    didMountOnloadStrategyRef.current = true
  }, [currentPage, currentPageSize, toggleData])

  const columns = [
    {
      title: 'Trạng thái',
      dataIndex: 'switch',
      width: 100
    },
    {
      title: 'Tên chiến dịch',
      dataIndex: 'name'
    },
    {
      title: 'Thông tin chiến dịch',
      dataIndex: 'generalInfo'
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'createdAt',
      width: 200
    },
    {
      title: 'Hành động',
      dataIndex: 'operation',
      align: 'center'
    }
  ]

  const onCopyStrategy = (id) => {
    const handler = async () => {
      setIsCopyLoading(true)
      const response = await strategyService.copyStrategy(id)
      if (!response.success) {
        dispatch(clearAndPushErrorMessage(response.message))
      } else {
        setToggleReload(!toggleReload)
        dispatch(clearAndPushSuccessMessage('Sao chép chiến dịch thành công'))
      }
      setIsCopyLoading(false)
    }

    handler()
  }

  const statusSwitchOnChange = (checked, strategyId) => {
    const handler = async () => {
      const response = await strategyService.updateActiveStatus(strategyId, checked)
      if (!response.success) {
        dispatch(clearAndPushErrorMessage('Có lỗi xẩy ra khi cập nhật trạng thái chiến dịch'))
      }
      setToggleReload(!toggleReload)
      dispatch(clearAndPushSuccessMessage('Cập nhật trạng thái chiến dịch thành công'))
    }

    handler()
  }

  const handlePagingChange = (page, pageSize) => {
    setIsLoading(true)
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }

  const handleStrategyReport = (strategyId) => {
    setCurrentStrategyId(strategyId)
    setOpenReportModal(true)
  }

  const handleStrategyHistory = (strategyId) => {
    setCurrentStrategyId(strategyId)
    setOpenCallHistoryModal(true)
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  return (
    <div className='tablet:p-5'>
      <FilterStrategy
        textSearch={textSearch}
        setTextSearch={setTextSearch}
        statusSearch={statusSearch}
        setStatusSearch={setStatusSearch}
        toggleData={toggleData}
        setToggleData={setToggleData}
        defaultStatus={DEFAULT_STATUS}
        selectedStrategies={selectedRowKeys}
        setSelectedStrategies={setSelectedRowKeys}
      />
      <Table
        scroll={{
          y: 700,
          x: 900
        }}
        rowSelection={checkIsSupperAdmin() ? rowSelection : null}
        columns={columns}
        dataSource={dataSource}
        loading={isLoading}
        pagination={false}
      />
      <Pagination
        className="text-right mt-8"
        total={countStrategy}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `Tổng ${total} chiến dịch`}
        defaultCurrent={currentPage}
        defaultPageSize={currentPageSize}
        pageSize={currentPageSize}
        current={currentPage}
        onChange={handlePagingChange}
      />

      <Report
        openReportModal={openReportModal}
        setOpenReportModal={setOpenReportModal}
        strategyId={currentStrategyId}
        currentStrategyName={currentStrategyName}
      />

      {
        openCallHistoryModal && <CallHistory
          currentStrategyName={currentStrategyName}
          strategyId={currentStrategyId}
          openCallHistoryModal={openCallHistoryModal}
          setOpenCallHistoryModal={setOpenCallHistoryModal}
          strategyType={strategyType}
        />
      }

    </div>
  )
}

export default Strategy
