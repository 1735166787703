import request from '../utils/request'
import { handleError } from './handleError'

export const createCustomerContactInfo = async (strategyId, contactInfo) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.post(
      `Strategies/${strategyId}/Customer`,
      contactInfo,
      {
        headers: {
          'x-access-site': siteId,
          Authorization: 'Bearer ' + jwtToken
        }
      }
    )

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const updateCustomerContactInfo = async (contactInfo) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.put('Customers', contactInfo, {
      headers: {
        'x-access-site': siteId,
        Authorization: 'Bearer ' + jwtToken
      }
    })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const updateCustomerComplete = async (customerId, isComplete) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.put(
      `Customers/${customerId}/Status?isComplete=${isComplete}`,
      {},
      {
        headers: {
          'x-access-site': siteId,
          Authorization: 'Bearer ' + jwtToken
        }
      }
    )

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const importCustomer = async (strategyId, formData, isUpdateRecord, hubUserId, checkDuplicationType) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.post(
      `Strategies/${strategyId}/ImportCustomer`,
      formData,
      {
        params: {
          isUpdateRecord,
          hubUserId,
          checkDuplicationType
        },
        headers: {
          'x-access-site': siteId,
          Authorization: 'Bearer ' + jwtToken
        }
      }
    )

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const exportCustomer = async (strategyId, filter) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.post(
      `Strategies/${strategyId}/ExportCustomer`,
      filter,
      {
        headers: {
          'x-access-site': siteId,
          Authorization: 'Bearer ' + jwtToken
        }
      }
    )

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const deleteCustomer = async (strategyId, customerId) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.delete(
      `Strategies/${strategyId}/Customers`,
      {
        headers: {
          'x-access-site': siteId,
          Authorization: 'Bearer ' + jwtToken
        },
        data: { ids: [customerId] }
      }
    )

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const updateCustomerBusy = async (customerId, isBusy) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.put(
      `Customers/${customerId}/BusyStatus`,
      {},
      {
        params: {
          isBusy
        },
        headers: {
          'x-access-site': siteId,
          Authorization: 'Bearer ' + jwtToken
        }
      }
    )

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const deleteCustomerByFile = async (strategyId, formData) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const siteId = userInfo?.siteId
  try {
    const response = await request.post(
      `Strategies/${strategyId}/DeleteCustomers`,
      formData,
      {
        headers: {
          'x-access-site': siteId,
          Authorization: 'Bearer ' + jwtToken
        }
      }
    )

    return response.data
  } catch (error) {
    handleError(error)
  }
}
