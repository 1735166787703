import request, { callioRequest, diginextRequest } from '../utils/request'
import { getStaticInfo } from './common'
import { handleError } from './handleError'

export const getHistoryByCurrentSite = async (filter) => {
  const { headers, userId } = getStaticInfo()
  try {
    filter.userId = userId
    const response = await request.post('CallInfo/EntryCallInfo', filter, {
      headers
    })
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const createCallInfo = async (callInfoRequest) => {
  const { headers } = getStaticInfo()
  try {
    const response = await request.post('CallInfo', callInfoRequest, {
      headers
    })
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const updateBasicCallInfo = async (callInfoRequest) => {
  const { headers } = getStaticInfo()
  try {
    const response = await request.put('CallInfo/BasicInfo', callInfoRequest, {
      headers
    })
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const getAllCallHistory = async (filter) => {
  const { headers, userId } = getStaticInfo()
  try {
    filter.userId = userId
    const response = await request.post('CallInfo/EntryCallInfo', filter, {
      headers
    })
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const syncLinkRecordForStrategyService = async (strategyId) => {
  const { headers } = getStaticInfo()
  try {
    const response = await request.put(`CallInfo/Sync/LinkRecord?strategyId=${strategyId}`, {}, {
      headers
    })
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const getLinkRecordCallio = async (callioId, token) => {
  try {
    const response = await callioRequest.get(`call/${callioId}/recording`, {
      headers: {
        token,
        'Content-Type': 'application/json'
      }
    })
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const getLinkRecordDiginext = async (callioId, token) => {
  try {
    const response = await diginextRequest.post('api/get/url/recording',
      {
        record_file: callioId
      },
      {
        headers: {
          'Secret-Key': token,
          'Content-Type': 'application/json'
        }
      })
    return response.data
  } catch (error) {
    handleError(error)
  }
}
