import { Input, Select, Modal, Alert, Table, Button, Popconfirm, Form } from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import {
  CaretDownOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import {
  getSettings,
  createSettingItem,
  updateSettingItem,
  deleteSettingItems
} from '../../../../services/strategyService'
import ActionButton from '../ActionButton'
import { next, prev } from '../../../../slices/currentStepSlice'
import {
  clearAndPushErrorMessage,
  clearAndPushSuccessMessage
} from '../../../../slices/errorMessageSlice'
import { dataType } from '../../../../constants/dataType'
import { toLowerCaseNonAccentVietnamese } from '../../../../utils/nonAccentVietnamese'
import { customer } from '../../../../constants/settingObjectType'

function ContactStep ({ strategyId }) {
  const dispatch = useDispatch()
  const { Option } = Select

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [fieldName, setFieldName] = useState('')
  const [contactCode, setContactCode] = useState('')
  const [dataTypeSelected, setDataTypeSelected] = useState('0')
  const [statusInputFieldName, setStatusInputFieldName] = useState('')
  const [statusInputCode, setStatusInputCode] = useState('')
  const [warningFieldMessage, setWarningFieldMessage] = useState(false)
  const [warningCodeMessage, setWarningCodeMessage] = useState(false)
  const [extendFields, setExtendFields] = useState([])
  const [dataSource, setDataSource] = useState([])
  const [isCreate, setIsCreate] = useState(true)
  const [toggleLoadData, setToggleLoadData] = useState(false)
  const [disableChangeCode, setDisableChangeCode] = useState(false)
  const [settingItemId, setSettingItemId] = useState()
  const actionName = useRef('Cập nhật')

  useEffect(() => {
    const handler = async () => {
      const response = await getSettings(strategyId, customer.value)
      if (response.success) {
        const contactFields = response.contactInfo
        const dataSource = contactFields.map((item, key) => {
          const fieldType = dataType.find(x => x.value === item.fieldType)
          return {
            key,
            displayName: item.displayName,
            fieldType: fieldType ? fieldType.title : 'Không rõ',
            fieldTypeValue: fieldType.value,
            code: item.code,
            isDefault: item.isDefault,
            id: item.id
          }
        })
        setDataSource(dataSource)
        const extendFieldResponse = []
        contactFields.forEach((element) => {
          if (!element.isDefault) extendFieldResponse.push(element)
        })
        setExtendFields(extendFieldResponse)
      } else {
        dispatch(clearAndPushErrorMessage(response.message))
      }
    }

    handler()
  }, [toggleLoadData])

  const openModal = () => {
    setIsModalOpen(true)
  }

  const handleUpdate = (record) => {
    actionName.current = 'Cập nhật'
    setDisableChangeCode(true)
    setFieldName(record.displayName)
    setContactCode(record.code)
    setDataTypeSelected(record.fieldTypeValue.toString())
    setSettingItemId(record.id)
    setIsCreate(false)
    setIsModalOpen(true)
  }

  const handleDelete = (id) => {
    const handler = async () => {
      const response = await deleteSettingItems([id])

      if (!response.success) {
        dispatch(clearAndPushErrorMessage('Có lỗi xẩy ra'))
      }

      setToggleLoadData(!toggleLoadData)
      dispatch(clearAndPushSuccessMessage('Xóa bản ghi thành công'))
    }

    handler()
  }

  const columns = [
    {
      title: 'Tên trường dữ liệu',
      dataIndex: 'displayName',
      key: 'displayName'
    },
    {
      title: 'Kiểu nhập liệu',
      dataIndex: 'fieldType',
      key: 'fieldType'
    },
    {
      title: 'Mã trường',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Hành động',
      align: 'center',
      key: 'action',
      render: (record) => {
        if (!record.isDefault) {
          return (
            <div className="flex justify-center" key={record.id}>
              <Button
                className="edit-button"
                size="middle"
                icon={<EditOutlined />}
                onClick={() => handleUpdate(record)}
              />
              <Popconfirm
                title="Bạn có chắc chắn xóa trường này không?"
                onConfirm={() => handleDelete(record.id)}
              >
                <Button
                  className="ml-2 delete-button"
                  size="middle"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </div>
          )
        }
      }
    }
  ]

  function validateFieldName () {
    if (!fieldName) {
      setStatusInputFieldName('error')
      setWarningFieldMessage(true)
    } else {
      setStatusInputFieldName('')
      setWarningFieldMessage(false)
    }
  }
  function validateContactCode () {
    if (!contactCode) {
      setStatusInputCode('error')
      setWarningCodeMessage(true)
    } else {
      setStatusInputCode('')
      setWarningCodeMessage(false)
    }
  }
  function clearContactForm () {
    setStatusInputFieldName('')
    setWarningFieldMessage(false)
    setStatusInputCode('')
    setWarningCodeMessage(false)
    setFieldName('')
    setContactCode('')
    setDataTypeSelected()
  }

  const handleCreate = () => {
    const handler = async () => {
      const newExtendField = {
        displayName: fieldName,
        code: contactCode,
        FieldType: dataTypeSelected,
        ObjectType: customer.value,
        StrategyId: strategyId,
        isDefault: false
      }

      const response = await createSettingItem([newExtendField])
      if (!response.success) {
        dispatch(clearAndPushErrorMessage('Có lỗi xẩy ra'))
      }

      setToggleLoadData(!toggleLoadData)
      setIsModalOpen(false)
      dispatch(clearAndPushSuccessMessage('Tạo mới thông tin thành công'))
    }

    handler()
  }

  const handleUpdateExtendField = () => {
    const handler = async () => {
      const itemUpdate = {
        DisplayName: fieldName,
        FieldType: dataTypeSelected,
        Id: settingItemId
      }

      const response = await updateSettingItem([itemUpdate])
      if (!response.success) {
        dispatch(clearAndPushErrorMessage('Có lỗi xẩy ra'))
      }

      setToggleLoadData(!toggleLoadData)
      setIsModalOpen(false)
      dispatch(clearAndPushSuccessMessage('Cập nhật thông tin thành công'))
    }

    handler()
  }

  const handleOk = () => {
    if (fieldName && contactCode) {
      if (isCreate) {
        handleCreate()
      } else {
        handleUpdateExtendField()
      }

      clearContactForm()
      setIsModalOpen(false)
    } else {
      validateFieldName()
      validateContactCode()
    }
  }
  const handleCancel = () => {
    clearContactForm()
    setIsModalOpen(false)
  }

  const nextAction = () => {
    dispatch(next())
  }

  const prevAction = () => {
    dispatch(prev())
  }

  const message = (
    <ul>
      <li>
        Thông tin liên hệ là những dữ liệu sẽ được hiển thị cho tổng đài viên
        thấy trước khi tiến hành cuộc gọi
      </li>
      <li>
        Ngoài các trường thông tin mặc định, bạn có
        thể thêm các trường thông tin khác cho phù hợp với nhu cầu và nội dung
        chiến dịch
      </li>
    </ul>
  )

  return (
    <>
      <Alert message={message} type="info" />

      <div className="flex justify-end my-5">
        <Button
          type="primary"
          onClick={() => {
            setDisableChangeCode(false)
            openModal()
            actionName.current = 'Thêm mới'
          }}
          style={{ backgroundColor: '#48BC7F', border: 'none' }}
        >
          Thêm mới
        </Button>
        <Modal
          title={<div>{actionName.current} thông tin liên hệ</div>}
          okText="Lưu lại"
          cancelText="Huỷ"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div>
            <div>
              <div className="">Tên trường dữ liệu</div>
              <Input
                placeholder="Nhập tên trường dữ liệu"
                value={fieldName}
                onChange={(e) => {
                  setFieldName(e.target.value)
                  if (!disableChangeCode) {
                    setContactCode(
                      toLowerCaseNonAccentVietnamese(e.target.value)
                    )
                  }
                }}
                status={statusInputFieldName}
                suffix={
                  warningFieldMessage && (
                    <ExclamationCircleOutlined style={{ color: '#FF4D50' }} />
                  )
                }
              />
              {warningFieldMessage && (
                <p className="text-[#FF4D50] text-xs pt-1 mb-0  ">
                  Thông tin bắt buộc
                </p>
              )}
            </div>
            <div className="pt-3">
              <div className="">Mã</div>
              <Input
                placeholder="Nhập mã"
                value={contactCode}
                disabled={disableChangeCode}
                onChange={(e) => setContactCode(e.target.value)}
                status={statusInputCode}
                suffix={
                  warningCodeMessage && (
                    <ExclamationCircleOutlined style={{ color: '#FF4D50' }} />
                  )
                }
              />
              {warningCodeMessage && (
                <p className="text-[#FF4D50] text-xs pt-1 mb-0  ">
                  Thông tin bắt buộc
                </p>
              )}
            </div>
            <div className="pt-3">
              <div className="">Kiểu nhập dữ liệu</div>
              <Select
                name="fieldType"
                onChange={(value) => {
                  setDataTypeSelected(value)
                }}
                value={dataTypeSelected}
                suffixIcon={<CaretDownOutlined />}
                style={{ width: '100%' }}
              >
                {dataType.map((item) => (
                  <Option key={item.value}>{item.title}</Option>
                ))}
              </Select>
            </div>
          </div>
        </Modal>
      </div>
      <Table
        scroll={{
          y: 800,
          x: 500
        }}
        dataSource={dataSource}
        columns={columns}
      />

      <ActionButton next={nextAction} prev={prevAction} />
    </>
  )
}

export default ContactStep
