import Home from './HomePage/Home'
import Login from './Login/Login'
import Strategy from './Strategy/Strategy'
import CallStrategy from './Strategy/Calls/CallStrategy'
import { UserSettingLayout } from '../components/Layout'
import UserProfile from './User/Profile'
import StrategyDetail from './Strategy/Details/StrategyDetail'
import Report from './Report/Report'
import CallAction from './CallAction/CallAction'
import { StrategyTitle, StrategyDetailTitle, UserProfileTitle, ReportTitle, CallStrategyTitle } from '../components/CardTitles'
import Forbidden from './Error/Forbidden'
import Feedback from './Feedback/Feedback'
import HistoryTitle from '../components/CardTitles/HistoryTitle'
import History from './History/History'
import MicrosipPage from './MicrosipPage/MicrosipPage'
const Pages = [
  {
    page: StrategyDetail,
    cardTitle: StrategyDetailTitle,
    path: '/strategy/strategyDetail'
  },
  {
    page: CallStrategy,
    cardTitle: CallStrategyTitle,
    path: '/strategy/callStrategy'
  },
  {
    page: Home,
    path: '/',
    exact: true
  },
  {
    page: CallAction,
    path: '/callAction'
  },
  {
    page: Strategy,
    cardTitle: StrategyTitle,
    path: '/strategy'
  },
  {
    page: Login,
    path: '/login'
  },
  {
    page: Report,
    cardTitle: ReportTitle,
    path: '/report'
  },
  {
    page: History,
    cardTitle: HistoryTitle,
    path: '/callHistory'
  },
  {
    path: '/user/profile',
    cardTitle: UserProfileTitle,
    page: UserProfile
  },
  {
    path: '/forbidden',
    page: Forbidden
  }
  // {
  //   path: '/feedback',
  //   page: Feedback
  // },
  // {
  //   path: '/microsip',
  //   page: MicrosipPage
  // }

]

export default Pages
