import { Tag } from 'antd'
import React from 'react'
import { callStatuses } from '../../../constants/callStatuses'
function CallStatusTag ({ callStatusValue }) {
  const buildStatusTag = () => {
    const callStatusObj = callStatuses.find(x => x.value === callStatusValue)
    if (callStatusObj) {
      return (
        <Tag color={callStatusObj.tagColor}>{callStatusObj.title}</Tag>
      )
    }

    return <Tag color='warning'>Trạng thái chưa xác định</Tag>
  }

  return (
    <div>
      {buildStatusTag()}
    </div>
  )
}

export default CallStatusTag
