import {
  Button,
  Select,
  Table,
  Card,
  DatePicker,
  Tag, InputNumber
} from 'antd'

import React, { useState, useEffect } from 'react'
import { getEntryCallInfo, getRankingForMicrosip } from '../../../services/reportService'
import { useDispatch } from 'react-redux'
import { clearAndPushErrorMessage } from '../../../slices/errorMessageSlice'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/vi_VN'
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'
import { filterEmployees } from '../../../services/userInfoService'
import { DownloadOutlined, UserOutlined } from '@ant-design/icons'
import { checkIsEmployee, checkIsSupperAdmin } from '../../../permissions'
import excelFileFormat from '../../../constants/excelFileFormat'
const { RangePicker } = DatePicker

const originalColumn = [
  {
    title: 'Tên nhân viên',
    dataIndex: 'employeeName',
    key: 'employeeName'
  },
  {
    title: 'Tổng số lượng cuộc gọi',
    key: 'total',
    align: 'center',
    render: (record) => {
      return <span>{record.count}</span>
    }
  },
  {
    title: 'Tổng số kết nối',
    key: 'acceptCount',
    align: 'center',
    render: (record) => {
      return <span>{record.acceptCount}</span>
    }
  }
]

function MicrosipRanking () {
  const [dataSource, setDataSource] = useState(false)
  const dispatch = useDispatch()
  const { Option } = Select
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
  const [datePickerValue, setDatePickerValue] = useState([moment(), moment()])
  const [toggleSearch, setToggleSearch] = useState(false)
  const [isExport, setIsExport] = useState(false)
  const [employees, setEmployees] = useState([])
  const [employeeSelected, setEmployeeSelected] = useState([])
  const [isLoadingExport, setIsLoadingExport] = useState(false)
  const [isRefesh, setIsRefesh] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [acceptSeconds, setAcceptSeconds] = useState(8)
  const dateFormat = 'DD-MM-YYYY'

  const getEmployees = async () => {
    const STATUS_DEFAULT = 1
    const response = await filterEmployees({ status: STATUS_DEFAULT })

    if (response.success) {
      setEmployees(response.results)
    } else {
      dispatch(clearAndPushErrorMessage(response.message))
    }
  }

  const getRanking = async (dataFilter) => {
    setIsLoading(true)
    const response = await getRankingForMicrosip(dataFilter)
    if (response.success) {
      const data = response.data
      setDataSource(data)
    } else {
      dispatch(clearAndPushErrorMessage(response.errorMessage))
    }

    setIsLoading(false)
  }

  useEffect(() => {
    getEmployees()
  }, [])

  useEffect(() => {
    const dataFilter = {
      rangeDate: {
        startTime: startDate,
        endTime: endDate
      },
      employeeIds: employeeSelected,
      acceptSeconds,
      isExport
    }

    getRanking(dataFilter)
  }, [startDate, endDate, acceptSeconds, employeeSelected, toggleSearch, isExport, isRefesh])

  const onRangePickerChange = (date) => {
    setDatePickerValue(date)
    setStartDate(date[0].format('YYYY-MM-DD'))
    setEndDate(date[1].format('YYYY-MM-DD'))
  }

  const exportEntryCall = () => {
    const handler = async () => {
      setIsLoadingExport(true)
      const dataFilter = {
        rangeDate: {
          startTime: startDate,
          endTime: endDate
        },
        employeeIds: employeeSelected
      }

      const response = await getEntryCallInfo(dataFilter)
      if (response.success) {
        const fileName = 'export_callinfo'
        const dataMapping = response.data.map(item => {
          const newItem = {
            'Tên nhân viên': item.employeeName,
            'Tên chiến dịch': item.strategyName,
            'Ngày gọi': item.callCreatedAt,
            'Zalo báo nhóm': item.approveTimes,
            'Không nhu cầu': item.rejectTimes,
            'Thất bại': item.faildTimes,
            'Tổng số cuộc gọi thành công': item.approveTimes + item.rejectTimes,
            'Tổng số lượng cuộc gọi': item.approveTimes + item.rejectTimes + item.faildTimes
          }

          return newItem
        })
        const workSheet = XLSX.utils.json_to_sheet(dataMapping)
        const workBook = { Sheets: { data: workSheet }, SheetNames: ['data'] }
        const excelBuffer = XLSX.write(workBook, {
          bookType: 'xlsx',
          type: 'array'
        })
        const data = new Blob([excelBuffer], { type: excelFileFormat.FILE_TYPE })
        FileSaver.saveAs(data, fileName + excelFileFormat.FILE_EXTENSION)
      } else {
        dispatch(clearAndPushErrorMessage(response.message))
      }
      setIsLoadingExport(false)
    }

    handler()
  }
  return (
    <Card
      className="h-full"
      title={
        <div className="flex flex-col tablet:flex-row justify-between ">
          <RangePicker
            className='tablet:w-1/2 w-full tablet:min-w-[250px] mb-2 tablet:mb-0'
            placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
            format={dateFormat}
            value={datePickerValue}
            onChange={onRangePickerChange} showToday={true} locale={locale} />
          {
            checkIsSupperAdmin() &&
            <InputNumber className='w-1/3 ml-2' value={acceptSeconds} min={0} max={500} onChange={(e) => {
              if (e) { setAcceptSeconds(e) } else setAcceptSeconds(0)
            }} placeholder="Nhập số giây tính kết nối" />
          }
          {
            !checkIsEmployee() &&
            <Select
              maxTagCount={4}
              className='tablet:ml-2 mb-2 tablet:mb-0 w-full '
              placeholder="Chọn nhân viên"
              suffixIcon={<UserOutlined />}
              value={employeeSelected}
              mode="multiple"
              allowClear={true}
              filterOption={(input, option) => (option?.key ?? '').includes(input)}
              showArrow={true}
              onChange={(value) => {
                setEmployeeSelected(value)
              }}>
              {
                employees?.map((item) => (
                  <Option value={item.id} key={item.name}>
                    <div className="">
                      <div className="font-bold">{item.name}</div>
                      {item.roleInfo?.title && <Tag color="green" className='text-[12px]'>{item.roleInfo?.title}</Tag>}
                    </div>
                  </Option>
                ))
              }
            </Select>
          }

          <div className="flex justify-center">
            <Button
              type="primary"
              className="w-max tablet:ml-2 mb-2 tablet:mb-0"
              onClick={() => setToggleSearch(!toggleSearch)}
            >
              Tìm kiếm
            </Button>
            <Button
              type="primary"
              className="w-max tablet:ml-2 mb-2 tablet:mb-0"
              onClick={() => setIsRefesh(!isRefesh)}
            >
              Tải lại
            </Button>
          </div>

          {
            checkIsSupperAdmin() &&
            <Button loading={isLoadingExport} className='ml-2' onClick={exportEntryCall} icon={<DownloadOutlined />}>Xuất dữ liệu</Button>
          }
        </div>
      }
    >
      <Table pagination={false} scroll={{ x: '65vh' }} columns={originalColumn} dataSource={dataSource} loading={isLoading}></Table>

    </Card>
  )
}

export default MicrosipRanking
