export const CallSource = [
  {
    label: 'Tất cả cuộc gọi',
    value: -1
  },
  {
    label: 'CallMaster',
    value: 0
  },
  {
    label: 'Microsip',
    value: 1
  }
]
