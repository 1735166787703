import React, { useState, useEffect, useRef } from 'react'
import { Button, Card, Modal, Table, Input, Select, Tag, DatePicker, Pagination, Form } from 'antd'
import { getStrategyCallHistory } from '../../../services/strategyService'
import CompleteStatusTag from '../../../components/Common/Tags/CompleteStatusTag'
import { clearAndPushErrorMessage, clearAndPushSuccessMessage } from '../../../slices/errorMessageSlice'
import { useDispatch } from 'react-redux'
import CallStatusTag from '../../../components/Common/Tags/CallStatusTag'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { getExportCallInfo } from '../../../services/reportService'
import excelFileFormat from '../../../constants/excelFileFormat'
import { DownloadOutlined, SearchOutlined, CaretDownOutlined, SyncOutlined, UserOutlined, UsergroupAddOutlined, SortDescendingOutlined, SortAscendingOutlined, EditOutlined } from '@ant-design/icons'
import { getGroups } from '../../../services/groupUserService'
import { filterEmployees } from '../../../services/userInfoService'
import { callStatuses, RECALL, SUCCESS } from '../../../constants/callStatuses'
import { useMediaQuery } from 'react-responsive'
import screenSize from '../../../constants/screenSize'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/vi_VN'
import { IsOrderByDescending } from '../../../constants/sortType'
import { getStrategySettingInfo, updateCallResult } from '../../../services/callStrategyService'
import ExtendCallInfo from './ExtendCallInfo'
import { CHOISE, DATE, DATETIME, MULTICHOISE } from '../../../constants/dataType'
import { checkIsEmployee, checkIsSuperAdminAndManager, getRoles, isOnlyClient } from '../../../permissions'
import { getCurrentUserId } from '../../../services/common'
import { NORMAL } from '../../../constants/strategyType'
import { getSiteInfo } from '../../../services/siteService'
import { getLinkRecord } from '../../../utils/linkRecords'
function CallHistory ({
  currentStrategyName,
  openCallHistoryModal,
  setOpenCallHistoryModal,
  strategyId,
  strategyType
}) {
  const [form] = Form.useForm()
  const { TextArea } = Input
  const { RangePicker } = DatePicker
  const dateFormat = 'DD-MM-YYYY'
  const dispatch = useDispatch()
  const [historyDataSource, setHistoryDataSource] = useState([])
  const [groups, setGroups] = useState([])
  const [employees, setEmployees] = useState([])
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
  const [datePickerValue, setDatePickerValue] = useState([moment(), moment()])
  const [groupSelected, setGroupSelected] = useState([])
  const [employeeSelected, setEmployeeSelected] = useState([])
  const [selectedCallStatus, setSelectedCallStatus] = useState(null)
  const [searchText, setSearchText] = useState()
  const [countCustomer, setCountCustomer] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(10)
  const [isLoading, setIsLoading] = useState(false)
  const [isExportLoading, setIsExportLoading] = useState(false)
  const [sortTypeSelected, setSortTypeSelected] = useState(true)
  const isTabletMiniOrMobile = useMediaQuery({ query: screenSize.TABLETMINI }) // tablet mini
  const [isOpenCallDetailModal, setIsOpenCallDetailModal] = useState(false)
  const [callDetailData, setCallDetailData] = useState([])
  const customerStrategySettings = useRef([])
  const callInfoStrategySettings = useRef([])
  const [currentCallStatus, setCurrentCallStatus] = useState()
  const [currentCallExtendInfo, setCurrentCallExtendInfo] = useState()
  const [currentNote, setCurrentNote] = useState()
  const [currentCallId, setCurrentCallId] = useState()

  const siteInfo = useRef()
  const { Option } = Select
  const callHistoryTableColumns = [
    {
      title: 'Thông tin khách hàng',
      dataIndex: 'customerInfo'
    },
    {
      title: 'Thông tin cuộc gọi',
      dataIndex: 'callInfo'
    }
  ]

  const getGroupsDataSource = async () => {
    const STATUS_DEFAULT = -1
    const response = await getGroups(STATUS_DEFAULT) // get all group
    if (response.success) {
      const groups = response.results
      setGroups(groups)
    } else {
      dispatch(clearAndPushErrorMessage(response.message))
    }
  }

  const getEmployees = async () => {
    const STATUS_DEFAULT = 1
    const response = await filterEmployees({ status: STATUS_DEFAULT }) // get all user
    if (response.success) {
      setEmployees(response.results)
    } else {
      dispatch(clearAndPushErrorMessage(response.message))
    }
  }

  const getCustomerStrategySettings = async () => {
    if (strategyId) {
      const response = await getStrategySettingInfo(strategyId)
      if (response.success) {
        customerStrategySettings.current = response.data.customerStrategySettings
        callInfoStrategySettings.current = response.data.callInfoStrategySettings
      }
    }
  }

  useEffect(() => {
    if (openCallHistoryModal) {
      getCustomerStrategySettings()
      getGroupsDataSource()
      getEmployees()
    }
  }, [openCallHistoryModal])

  useEffect(() => {
    if (openCallHistoryModal) {
      handler()
    }
  }, [openCallHistoryModal, startDate, endDate, selectedCallStatus, groupSelected, employeeSelected, currentPage, currentPageSize, searchText, sortTypeSelected])

  useEffect(() => {
    const handler = async () => {
      const response = await getSiteInfo()
      const { data } = response
      siteInfo.current = data
    }

    handler()
  }, [])

  const refresh = () => {
    const handler = async () => {
      setSearchText()
      setStartDate(moment().format('YYYY-MM-DD'))
      setEndDate(moment().format('YYYY-MM-DD'))
      setDatePickerValue([moment(), moment()])
      setEmployeeSelected([])
      setGroupSelected([])
      setSelectedCallStatus(null)
    }

    handler()
  }

  const buildRequestFilter = () => {
    const requestFilter = {
      limit: currentPageSize,
      page: currentPage,
      strategyId,
      rangeDate: {
        startTime: startDate,
        endTime: endDate
      },
      callStatus: selectedCallStatus,
      employeeIds: employeeSelected,
      groupIds: groupSelected,
      searchText,
      isOrderByDescending: sortTypeSelected,
      userId: getCurrentUserId(),
      roles: getRoles()
    }

    if (isOnlyClient()) requestFilter.callStatus = SUCCESS.value

    return requestFilter
  }

  const renderExtendInfo = (setting, customerInfoExtend) => {
    const htmlItems = []
    // eslint-disable-next-line prefer-const
    for (let [key, value] of Object.entries(customerInfoExtend)) {
      const fieldInfos = setting.filter((field) => field.code === key)
      if (fieldInfos.length) {
        const fieldInfo = fieldInfos[0]
        if (fieldInfo.fieldType === DATETIME.value || fieldInfo.fieldType === DATE.value) {
          const date = new Date(value)
          value = moment(date).format('DD-MM-YYYY')
        } else if (fieldInfo.fieldType === CHOISE.value) {
          const fieldValue = fieldInfo.selectValues?.filter(selectValue => selectValue.key === value)
          if (fieldValue && fieldValue.length) {
            value = fieldValue[0].value
          }
        } else if (fieldInfo.fieldType === MULTICHOISE.value) {
          const valueItems = value.split(',')
          value = valueItems.map(x => {
            const fieldValue = fieldInfo.selectValues?.filter(selectValue => selectValue.key === x)
            if (!fieldValue || !fieldValue.length) {
              return ''
            }
            return fieldValue[0].value
          }).join(', ')
        }

        const displayName = fieldInfo.displayName
        htmlItems.push(
          <div className="" key={key}>
            <div>
              <span className="">{displayName + ':'} <b>{value}</b></span>
            </div>
          </div>
        )
      }
    }
    return <div>{htmlItems.map((item) => item)}</div>
  }

  const handler = async () => {
    const requestFilter = buildRequestFilter()
    setIsLoading(true)
    const response = await getStrategyCallHistory(requestFilter)
    if (response.success) {
      const { callInfoHistoryModels: history, count } = response.result
      setCountCustomer(count)
      const dataTable = history.map((item, key) => {
        const { customerInfo, callInfoBasic } = item
        const { fullName, description, phoneNumber, extendInfo, createdAt: customerCreatedAt, updatedAt: customerUpdatedAt, isCompleted } = customerInfo
        const callHistory = callInfoBasic.map((item, key) => {
          const { callId, employeeName, extendInfo, createdAt, durations, note, status, updatedAt, faildCause, recordLink, callioId } = item
          return (
            <Card
              size='small'
              className='mb-1'
              key={key}
              title={<div className='flex items-center'>
                <div className='mr-2'>Kết quả</div>
                <div ><CallStatusTag callStatusValue={status} /></div>
                <Button onClick={() => onOpenCallDetail(callId, status, extendInfo, note)} type='primary' size='small' icon={<EditOutlined />}></Button>
              </div>}>

              <div className='mb-2'>
                <div>Nhân viên: <b>{employeeName}</b></div>
                {extendInfo && renderExtendInfo(callInfoStrategySettings.current, JSON.parse(extendInfo))}
                <div>Thời gian đàm thoại: <b>{durations}</b> giây</div>
                {note && <div>Ghi chú: <b>{note}</b></div>}
                {faildCause && <div>Nguyên nhân lỗi: <b className='text-red-600'>{faildCause}</b></div>}
                <div>Thời gian gọi: <b>{moment.utc(createdAt).local().format('DD-MM-YYYY HH:mm:ss')
                }</b></div>
                <div className='whitespace-nowrap'>Lần cập nhật cuối: <b>{moment.utc(updatedAt).local().format('DD-MM-YYYY HH:mm:ss')}</b></div>
                {!!(callioId && callioId !== '_') &&
                  // <div>
                  //   Tải file ghi âm: <a href={recordLink} rel="noreferrer" ><DownloadOutlined /></a>
                  //   <audio className='w-full' autoPlay={false} src={recordLink} controls />
                  // </div>
                  <a onClick={() => {
                    const handler = async () => {
                      const url = await getLinkRecord(siteInfo.current, callioId)
                      if (url) { window.open(url, '_blank').focus() }
                    }
                    handler()
                  }}>
                    Tải file ghi âm: <DownloadOutlined />
                  </a>
                }
              </div>
            </Card>
          )
        })

        return {
          key,
          customerInfo: (
            <div>

              <div>Số điện thoại: <b>{phoneNumber}</b></div>
              {
                strategyType === NORMAL.value &&
                <div>
                  <div>Tên: <b>{fullName}</b></div>
                  <div>Mô tả: <b>{description}</b></div>
                  {extendInfo && renderExtendInfo(customerStrategySettings.current, JSON.parse(extendInfo))}
                  <div><CompleteStatusTag isCompleted={isCompleted} /></div>
                  <div>Tạo lúc: <b>{moment.utc(customerCreatedAt).local().format('DD-MM-YYYY HH:mm:ss')}</b></div>
                  <div>Cập nhật lúc: <b>{moment.utc(customerUpdatedAt).local().format('DD-MM-YYYY HH:mm:ss')}</b></div>
                </div>
              }
            </div>
          ),
          callInfo: callHistory
        }
      })

      setHistoryDataSource(dataTable)
      setIsLoading(false)
      setOpenCallHistoryModal(true)
    } else {
      dispatch(clearAndPushErrorMessage(response.message))
    }
  }

  const onOpenCallDetail = (callId, status, extendInfo, note) => {
    const handler = async () => {
      setCurrentCallStatus(status)
      setCurrentCallExtendInfo(extendInfo)
      setCurrentNote(note)
      setCurrentCallId(callId)
      setIsOpenCallDetailModal(true)
    }

    handler()
  }

  const onExportData = () => {
    const handler = async () => {
      const requestFilter = buildRequestFilter()
      setIsExportLoading(true)
      const response = await getExportCallInfo(requestFilter)
      if (!response.success) {
        dispatch(clearAndPushErrorMessage(response.message))
      } else {
        const workSheet = XLSX.utils.json_to_sheet(response.data)
        const workBook = { Sheets: { data: workSheet }, SheetNames: ['data'] }
        const excelBuffer = XLSX.write(workBook, {
          bookType: 'xlsx',
          type: 'array'
        })
        const data = new Blob([excelBuffer], { type: excelFileFormat.FILE_TYPE })

        FileSaver.saveAs(data, `report${excelFileFormat.FILE_EXTENSION}`)
        setIsExportLoading(false)
      }
    }

    handler()
  }

  const onRangePickerChange = (date, dateString) => {
    setDatePickerValue(date)
    setStartDate(date[0].format('YYYY-MM-DD'))
    setEndDate(date[1].format('YYYY-MM-DD'))
  }

  const handlePagingChange = (page, pageSize) => {
    setIsLoading(true)
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }

  const buildDefaultValueForCallResult = () => {
    const defaultValue = [
      {
        name: ['status'],
        value: currentCallStatus
      },
      {
        name: ['note'],
        value: currentNote
      }
    ]

    if (currentCallExtendInfo) {
      const obj = JSON.parse(currentCallExtendInfo)
      for (const [key, value] of Object.entries(obj)) {
        const fakeKey = key + '_custom'
        const settingItem = callInfoStrategySettings.current.find(x => x.code === key)
        if (settingItem) {
          if (settingItem.fieldType === MULTICHOISE.value) {
            const arrayValue = value.split(',')
            defaultValue.push({
              name: [fakeKey],
              value: arrayValue
            })
            form.setFieldValue(key, arrayValue)
          } else {
            defaultValue.push({
              name: [fakeKey],
              value
            })
          }
        }
      }
    }

    return defaultValue
  }

  const handleUpdateCallInfo = async (callInfo) => {
    const extendObject = {}
    for (let [key, value] of Object.entries(callInfo)) {
      if (key.includes('_custom') && value) {
        const realKey = key.replace('_custom', '')
        if (Array.isArray(value)) value = value.join(',')
        Object.assign(extendObject, { [realKey]: value })
      }
    }

    if (callInfo.status === 3 && !callInfo.callLaterTime) {
      dispatch(clearAndPushErrorMessage('Vui lòng chọn thời gian khách hẹn gọi lại'))
    } else {
      if (callInfo.callLaterTime) { callInfo.callLaterTime = callInfo.callLaterTime.toDate() }
      const callInfoUpdate = {
        extendInfo: JSON.stringify(extendObject),
        ...callInfo
      }
      const response = await updateCallResult(currentCallId, callInfoUpdate)
      if (response.success) {
        dispatch(clearAndPushSuccessMessage('Cập nhật thành công'))
        form.resetFields()
        setIsOpenCallDetailModal(false)
      } else {
        dispatch(clearAndPushErrorMessage(response.message))
      }
      await handler()
    }
  }

  return (
    <div>
      <Modal
        bodyStyle={{ maxHeight: '80vh', overflow: 'scroll' }}
        title={<h3>{'Lịch sử gọi: ' + currentStrategyName}</h3>}
        open={openCallHistoryModal}
        cancelButtonProps={{ style: { display: 'none' } }}
        closable={false}
        maskClosable={false}
        okText="Đóng"
        onOk={() => {
          refresh()
          setOpenCallHistoryModal(false)
        }}
        centered
        onCancel={() => {
          refresh()
          setOpenCallHistoryModal(false)
        }}
        className="min-w-[80%] modal-call-history"
      >
        <div className="">
          <div className="flex flex-col flex-end items-end mb-2">
            <div className="tablet:mb-1  w-full flex flex-col tablet:justify-end tablet:flex-row tablet:items-start">
              <Input
                value={searchText}
                placeholder='Nhập từ khóa tìm kiếm ...'
                suffix={<SearchOutlined />}
                className='mb-2 tablet:mr-1 tablet:w-[175px]'
                onChange={(e) => setSearchText(e.target.value)} />
              {
                checkIsEmployee()
                  ? <></>
                  : <>
                    <Select
                      maxTagCount={1}
                      className='tablet:mr-1 mb-2 w-full tablet:w-[200px]'
                      placeholder="Chọn nhóm"
                      allowClear={true}
                      suffixIcon={<UsergroupAddOutlined />}
                      mode="multiple"
                      filterOption={(input, option) => (option?.key ?? '').includes(input)}
                      value={groupSelected}
                      showArrow={true}
                      onChange={(value) => {
                        setGroupSelected(value)
                      }} >
                      {
                        groups?.map((item) => (
                          <Option value={item.id} key={item.name}>
                            <div className="">
                              <div className="">{item.name}</div>
                            </div>
                          </Option>
                        ))
                      }
                    </Select>
                    <Select
                      maxTagCount={1}
                      className='tablet:mr-1 mb-2 tablet:min-w-[175px]'
                      placeholder="Chọn nhân viên"
                      suffixIcon={<UserOutlined />}
                      value={employeeSelected}
                      mode="multiple"
                      allowClear={true}
                      showArrow={true}
                      filterOption={(input, option) => {
                        return (option?.key ?? '').includes(input)
                      }}
                      onChange={(value) => {
                        setEmployeeSelected(value)
                      }}>
                      {
                        employees?.map((item) => (
                          <Option value={item.id} key={item.name}>
                            <div className="">
                              <div className="font-bold">{item.name}</div>
                              {item.roleInfo?.title && <Tag color="green" className='text-[12px]'>{item.roleInfo?.title}</Tag>}
                            </div>
                          </Option>
                        ))
                      }
                    </Select>
                  </>
              }

              {
                isOnlyClient()
                  ? <></>
                  : <Select
                    className='mb-2 tablet:mr-1 tablet:min-w-[150px]'
                    value={selectedCallStatus}
                    allowClear={true}
                    suffixIcon={<CaretDownOutlined />}
                    onChange={(value) => {
                      setSelectedCallStatus(value)
                    }}>
                    <Option value={null} key={-1}>Chọn tất cả</Option>
                    {
                      callStatuses.map((item) => (
                        <Option value={item.value} key={item.value}>{item.title}</Option>
                      ))
                    }
                  </Select>
              }
            </div>
            <div className="flex w-full tablet:w-auto flex-col tablet:flex-row tablet:items-center items-end">
              <RangePicker
                className='w-full tablet:w-auto'
                placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                format={dateFormat}
                value={datePickerValue}
                onChange={onRangePickerChange} showToday={true} locale={locale} />

              <div className="mt-2 tablet:mt-0 w-full tablet:w-auto flex justify-end">
                <Select
                  className='tablet:ml-1 mr-1 tablet:min-w-[150px] grow '
                  value={sortTypeSelected}
                  suffixIcon={sortTypeSelected ? <SortDescendingOutlined /> : <SortAscendingOutlined />}
                  onChange={(value) => {
                    setSortTypeSelected(value)
                  }}>
                  {
                    IsOrderByDescending.map((item, key) => (
                      <Option value={item.value} key={key}>{item.title}</Option>
                    ))
                  }
                </Select>

                <Button type='primary' onClick={refresh} >
                  <SyncOutlined />
                </Button>
                <Button loading={isExportLoading} type='primary' onClick={onExportData} className="ml-1">
                  <DownloadOutlined />
                  {!isTabletMiniOrMobile && 'Liên hệ'}
                </Button></div>
            </div>

          </div>
          <Table
            columns={callHistoryTableColumns}
            dataSource={historyDataSource}
            loading={isLoading}
            scroll={{
              x: '80vh',
              y: '62vh'
            }}
            pagination={false}
          />
          <Pagination
            className="text-right mt-8"
            total={countCustomer}
            showSizeChanger
            showQuickJumper
            showTotal={(total) => `Tổng ${total} cuộc gọi`}
            defaultCurrent={currentPage}
            defaultPageSize={currentPageSize}
            pageSize={currentPageSize}
            current={currentPage}
            onChange={handlePagingChange}
          />
        </div>

      </Modal>
      {isOpenCallDetailModal && <Modal title="Cập nhật cuộc gọi" open={isOpenCallDetailModal}
        closable={false}
        maskClosable={false}
        cancelText="Đóng"
        okText="Lưu"
        onOk={() => {
          form.submit()
        }}
        onCancel={() => {
          form.resetFields()
          setIsOpenCallDetailModal(false)
        }}
      >
        <Form
          name="Basic"
          layout="vertical"
          form={form}
          onFinish={handleUpdateCallInfo}
          fields={buildDefaultValueForCallResult()}
        >
          <Form.Item
            label="Kết quả"
            name="status"
            rules={[
              {
                required: true,
                message: 'Đây là trường bắt buộc' // thêm rule cho type
              }
            ]}>
            <Select onChange={(value) => {
              setCurrentCallStatus(value)
            }}>
              {callStatuses.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {
            currentCallStatus === RECALL.value
              ? <Form.Item label="Thời gian khách hẹn gọi lại" name="callLaterTime">
                <DatePicker />
              </Form.Item>
              : <></>
          }
          <ExtendCallInfo
            callInfoStrategySettings={callInfoStrategySettings.current}
            callStatus={currentCallStatus}
          />

          <Form.Item label="Ghi chú" name="note">
            <TextArea />
          </Form.Item>
        </Form>
      </Modal>}
    </div>

  )
}

export default CallHistory
