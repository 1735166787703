import React, { useState, useEffect } from 'react'
import { Button, Input, Popconfirm, Select } from 'antd'
import { statusFilterStrategy } from '../../../constants/statusFilter'
import { CaretDownOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import { deleteStrategies } from '../../../services/strategyService'
import { useDispatch } from 'react-redux'
import { clearAndPushErrorMessage, clearAndPushSuccessMessage, clearEntryMessage } from '../../../slices/errorMessageSlice'
function FilterStrategy ({ textSearch, setTextSearch, statusSearch, setStatusSearch, defaultStatus, toggleData, setToggleData, selectedStrategies, setSelectedStrategies }) {
  const { Option } = Select
  const dispatch = useDispatch()
  const handleSelectStatus = (statusSelected) => {
    setStatusSearch(statusSelected)
  }
  const refreshStrategy = () => {
    setSelectedStrategies([])
    setTextSearch()
    setStatusSearch(defaultStatus)
    setToggleData(!toggleData)
  }

  useEffect(() => {
    return () => {
      dispatch(clearEntryMessage())
    }
  }, [])

  const handleDelete = () => {
    const handler = async () => {
      const response = await deleteStrategies(selectedStrategies)
      if (response.success) {
        dispatch(clearAndPushSuccessMessage('Xóa các chiến dịch thành công'))
        setToggleData(!toggleData)
      } else {
        dispatch(clearAndPushErrorMessage(response.message))
      }
    }

    handler()
  }

  return (
    <div className="py-2 flex flex-col tablet:flex-row justify-end">
      <div className="  flex flex-col tablet:flex-row w-full tablet:w-max ">
        <Input
          className="tablet:w-max mb-2  mr-2 w-full"
          placeholder="Từ khóa ..."
          onChange={(e) => setTextSearch(e.target.value)}
          value={textSearch}
          suffix={<SearchOutlined />}
        />
        <Select
          className="min-w-[150px] mb-2"
          onChange={handleSelectStatus}
          value={statusSearch}
          suffixIcon={<CaretDownOutlined />}
        >
          {statusFilterStrategy.map((item) => (
            <Option key={item.isActive} value={item.isActive}>
              {item.title}
            </Option>
          ))}
        </Select>
      </div>
      <div className="flex justify-center tablet:ml-4">
        <Button
          className="mr-2"
          onClick={() => {
            setToggleData(!toggleData)
            setSelectedStrategies([])
          }}
          type="primary"
        >
          Tìm kiếm
        </Button>
        <Button type="primary" onClick={refreshStrategy}>
          Làm mới
        </Button>
        {
          selectedStrategies.length
            ? <Popconfirm
                title="Bạn có chắc chắn xóa chiến dịch này không?"
                cancelText="Hủy"
                onConfirm={() => handleDelete()}
              >
                 <Button icon={<DeleteOutlined/>} className='ml-2' danger type="default">Xóa hàng loạt</Button>
              </Popconfirm>
            : <></>
        }
      </div>
    </div>
  )
}

export default FilterStrategy
