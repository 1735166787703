import { Input, Select, TreeSelect, Form, Button } from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import roles from '../../../../constants/roles'

import ActionButton from '../ActionButton'
import { next, prev } from '../../../../slices/currentStepSlice'
import {
  getAssignmentStrategyById,
  updateAssignmentForStrategy
} from '../../../../services/strategyService'
import {
  clearAndPushErrorMessage,
  clearAndPushSuccessMessage
} from '../../../../slices/errorMessageSlice'

function AssignmentStep ({ strategyId }) {
  const dispatch = useDispatch()
  const [dataTree, setDataTree] = useState([])
  const [managerNode, setManagerNode] = useState([])
  const [assignmentData, setAssignmentData] = useState([])
  const [strategyInfo, setStrategyInfo] = useState([])
  const [managerIdsSelected, setManagerIdSelected] = useState([])
  const [employeeIdsSelected, setEmployeeIdSelected] = useState([])
  const { SHOW_CHILD } = TreeSelect
  const { Option } = Select
  const [form] = Form.useForm()

  function buildDataTree (groupAndMembers) {
    const dataTree = groupAndMembers.map((item, key) => {
      const tree = {
        title: item.groupInfo.name,
        key: item.groupInfo.id,
        value: item.groupInfo.id,
        children: item.memberInfos.map((memberInfo, key) => {
          const childrenTree = {
            title: memberInfo.fullName,
            value: memberInfo.id,
            key: memberInfo.id
          }

          return childrenTree
        })
      }

      return tree
    })

    return dataTree
  }

  useEffect(() => {
    if (strategyId) {
      async function getData () {
        const response = await getAssignmentStrategyById(strategyId)
        if (response.success) {
          setAssignmentData(response.assignmentData)
          setStrategyInfo(response.strategyAssignmentData?.strategyInfo)
          const employeeIdSelected = getEmployeeIdsSelected(
            response.strategyAssignmentData?.employeeInfos
          )
          setEmployeeIdSelected(employeeIdSelected)
          const managerIdSelected = getManagerIdsSelected(
            response.strategyAssignmentData?.employeeInfos
          )
          setManagerIdSelected(managerIdSelected)
          const resultBuildDataTree = buildDataTree(
            response.assignmentData.groupAndMemberInfos
          )
          setDataTree(resultBuildDataTree)
          const managerInfos = response.assignmentData.managerInfos
          const managerNode = managerInfos.map((item) => {
            return (
              <Option key={item.id} value={item.id}>
                {item.fullName}
              </Option>
            )
          })

          setManagerNode(managerNode)
        } else {
          dispatch(clearAndPushErrorMessage(response.errorMessage))
        }
      }
      getData()
    }
  }, [])

  const tProps = {
    treeData: dataTree,
    treeCheckable: true,
    showCheckedStrategy: SHOW_CHILD,
    placeholder: 'Chọn tổng đài viên',
    style: {
      width: '100%'
    }
  }

  const nextAction = () => {
    form.submit()
  }

  const prevAction = () => {
    dispatch(prev())
  }

  const getEmployeeIdsSelected = (employees) => {
    const employeeIds = employees
      .filter((item) => item?.roleInfo?.name === roles.EMPLOYEE)
      .map((item) => item.id)
    return employeeIds
  }

  const getManagerIdsSelected = (employees) => {
    const managerIds = employees
      .filter(
        (item) =>
          item?.roleInfo?.name === roles.MANAGER ||
          item?.roleInfo?.name === roles.CLIENT
      )
      .map((item) => item.id)
    return managerIds
  }

  const updateAssignmentStrategy = async (newAssignmentData) => {
    const employeeIds = [
      ...newAssignmentData.employeeIdsSelected,
      ...newAssignmentData.managerIdsSelected
    ]
    const newStrategyInfo = {
      ...strategyInfo,
      callBackTimes: newAssignmentData.callBackTimes,
      callBackLaterMinutes: newAssignmentData.callBackLaterMinutes,
      acceptedSeconds: newAssignmentData.acceptedSeconds
    }

    const response = await updateAssignmentForStrategy(
      newStrategyInfo,
      employeeIds
    )
    if (response.success) {
      dispatch(next())
      dispatch(clearAndPushSuccessMessage('Cập nhật dữ liệu mới thành công'))
    } else {
      dispatch(clearAndPushErrorMessage(response.message))
    }
  }

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={updateAssignmentStrategy}
        fields={[
          {
            name: ['strategyId'],
            value: strategyId
          },
          {
            name: ['employeeIdsSelected'],
            value: employeeIdsSelected
          },
          {
            name: ['managerIdsSelected'],
            value: managerIdsSelected
          },
          {
            name: ['callBackTimes'],
            value: strategyInfo.callBackTimes
          },
          {
            name: ['callBackLaterMinutes'],
            value: strategyInfo.callBackLaterMinutes
          },
          {
            name: ['acceptedSeconds'],
            value: strategyInfo.acceptedSeconds
          }
        ]}
      >
        {strategyId && (
          <Form.Item name="strategyId" label="Mã chiến dịch" className="mb-4">
            <Input disabled />
          </Form.Item>
        )}
        <Form.Item name="employeeIdsSelected" label="Danh sách tổng đài viên">
          <TreeSelect {...tProps} />
        </Form.Item>
        <Form.Item name="managerIdsSelected" label="Nhân viên quản lý">
          <Select
            mode="multiple"
            allowClear
            style={{
              width: '100%'
            }}
            placeholder="Chọn nhân viên quản lý"
            showSearch="false"
          >
            {managerNode}
          </Select>
        </Form.Item>
        <div className="tablet:flex justify-between">
          <Form.Item
            name="callBackTimes"
            label="Số lần gọi lại khi kết nối thất bại"
            className="pt-5 w-full"
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              },
              {
                pattern: /^[0-9]{1,}$/,
                message: 'Email không hợp lệ'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="callBackLaterMinutes"
            label="Nếu kết nối thất bại, gọi lại sau"
            className="pt-5 w-full tablet:ml-3"
          >
            <Select className="w-full">
              {assignmentData?.callBackLaterMinuteModels?.map((item) => (
                <Option key={item.minutes} value={item.minutes}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="acceptedSeconds"
            label="Xác nhận cuộc gọi thành công sau:"
            className="pt-5 w-full tablet:ml-3"
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              },
              {
                pattern: /^[0-9]{1,}$/,
                message: 'Dữ liệu không hợp lệ'
              }
            ]}
          >
            <Input />
          </Form.Item>
        </div>
      </Form>
      <ActionButton next={nextAction} prev={prevAction} />
    </div>
  )
}

export default AssignmentStep
