import React, { useState, useEffect } from 'react'
import {
  updateUserFullInfo,
  getFullInfo,
  createEmployee
} from '../../../../services/userInfoService'
import { Button, Modal, Form, Input, Select } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import roles from '../../../../constants/roles'
import { useDispatch } from 'react-redux'
import {
  clearAndPushErrorMessage,
  clearAndPushSuccessMessage
} from '../../../../slices/errorMessageSlice'
import { getStaticInfo } from '../../../../services/common'

function EmployeeUpdate ({
  groupInfoDefaultId,
  toggleFetchData,
  groupInfos,
  roleInfos,
  formUpdateOrCreateUserState,
  setFormUpdateOrCreateUserState,
  employeeId,
  setUpdatedState,
  updatedState,
  titleButton
}) {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { Option } = Select
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [selectedRole, setSelectedRole] = useState()
  const [selectedGroups, setSelectedGroups] = useState([])
  const [isLoadingUpdateEmployee, setIsLoadingUpdateEmployee] = useState(false)

  useEffect(() => {
    if (employeeId) {
      const getFullInfoUser = async (userId) => {
        const response = await getFullInfo(userId)
        if (response.success) {
          const userInfo = response.result.userInfo
          setName(userInfo.fullName)
          setEmail(userInfo.email)
          setPhoneNumber(userInfo.phoneNumber)
          setSelectedRole(response.result.selectedRoleInfos?.name)
          setSelectedGroups(
            response.result.selectedGroupInfos.map((item) => item.id)
          )
        } else {
          dispatch(clearAndPushErrorMessage(response.errorMessage))
        }
      }

      getFullInfoUser(employeeId)
    } else {
      setName('')
      setPhoneNumber('')
      setEmail('')
      setSelectedGroups([groupInfoDefaultId])
      setSelectedRole(roles.EMPLOYEE)
    }
  }, [toggleFetchData])

  const updateUserInfo = async (values) => {
    setIsLoadingUpdateEmployee(true)
    const { siteId } = getStaticInfo()

    const dataUserUpdate = {
      userInfo: {
        id: employeeId,
        fullName: name,
        email,
        phoneNumber,
        siteId
      },
      groupIds: Array.isArray(selectedGroups)
        ? selectedGroups
        : [selectedGroups],
      roleName: selectedRole
    }

    const response = await updateUserFullInfo(dataUserUpdate)

    if (response.success) {
      setFormUpdateOrCreateUserState(false)
      setUpdatedState(!updatedState)
      dispatch(clearAndPushSuccessMessage('Cập nhật thành công'))
    } else {
      dispatch(clearAndPushErrorMessage(response.errorMessage))
    }
    setIsLoadingUpdateEmployee(false)
  }

  const createNewEmployee = async (values) => {
    setIsLoadingUpdateEmployee(true)
    const { siteId } = getStaticInfo()

    const dataUserCreate = {
      userInfo: {
        fullName: name,
        email,
        userName: values.userName,
        password: values.password,
        phoneNumber,
        siteId
      },
      groupIds: Array.isArray(selectedGroups)
        ? selectedGroups
        : [selectedGroups],
      roleName: selectedRole
    }

    const response = await createEmployee(dataUserCreate)

    if (response.success) {
      setFormUpdateOrCreateUserState(false)
      setUpdatedState(!updatedState)
      dispatch(clearAndPushSuccessMessage('Thêm mới thành công'))
    } else {
      dispatch(clearAndPushErrorMessage(response.errorMessage))
    }
    setIsLoadingUpdateEmployee(false)
  }

  const handleCreateOrUpdate = (values) => {
    employeeId ? updateUserInfo(values) : createNewEmployee(values)
  }
  return (
    <>
      <Modal
        title={titleButton + ' người dùng'}
        open={formUpdateOrCreateUserState}
        footer={null}
        onCancel={() => {
          form.resetFields()
          setFormUpdateOrCreateUserState(false)
        }}
      >
        <Form
          form={form}

          onFinish={handleCreateOrUpdate}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 18 }}
          fields={[
            {
              name: ['name'],
              value: name
            },
            {
              name: ['email'],
              value: email
            },
            {
              name: ['phoneNumber'],
              value: phoneNumber
            },
            {
              name: ['groups'],
              value: selectedGroups
            },
            {
              name: ['role'],
              value: selectedRole
            }
          ]}
        >
          <Form.Item
            name="name"
            label="Tên"
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              }
            ]}
          >
            <Input
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              },
              {
                pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: 'Tên đăng nhập không hợp lệ'
              }
            ]}
          >
            <Input
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label="Số điện thoại"
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              },
              {
                pattern: /^[0-9]{8,12}$/,
                message: 'Số điện thoại không hợp lệ'
              }
            ]}
          >
            <Input
              onChange={(e) => {
                setPhoneNumber(e.target.value)
              }}
            />
          </Form.Item>
          {!employeeId && (
            <React.Fragment>
              <Form.Item
                name="userName"
                label="Tên tài khoản"
                rules={[
                  {
                    required: true,
                    message: 'Không được để trống'
                  }
                ]}
              >
                <Input autoComplete='off' />
              </Form.Item>
              <Form.Item
                name="password"
                label="Mật khẩu"
                rules={[
                  {
                    required: true,
                    message: 'Không được để trống'
                  }
                  // {
                  //   pattern:
                  //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                  //   message: 'Mật khẩu không hợp lệ'
                  // }
                ]}
              >
                <Input.Password autoComplete='off' />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                label="Nhập lại mật khẩu"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: 'Mật khẩu không trùng khớp'
                  },
                  ({ getFieldValue }) => ({
                    validator (rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error('Mật khẩu không trùng khớp')
                      )
                    }
                  })
                ]}
              >
                <Input.Password />
              </Form.Item>
            </React.Fragment>
          )}
          <Form.Item
            name="groups"
            label="Nhóm"
            rules={[
              {
                required: true,
                message: 'Đây là trường bắt buộc'
              }
            ]}>
            <Select
              suffixIcon={<CaretDownOutlined />}
              onChange={(value) => {
                setSelectedGroups(value)
              }}
              mode={selectedRole === roles.EMPLOYEE ? 'single' : 'multiple'}
            >
              {groupInfos?.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="role"
            label="Quyền"
            rules={[
              {
                required: true,
                message: 'Đây là trường bắt buộc'
              }
            ]}
          >
            <Select
              onChange={(value) => {
                setSelectedRole(value)
                if (value === roles.EMPLOYEE) {
                  setSelectedGroups([groupInfoDefaultId])
                }
              }}
              value={selectedRole}
              suffixIcon={<CaretDownOutlined />}
            >
              {roleInfos?.map((item) => (
                <Option value={item.name} key={item.id}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item className="flex justify-end">
            <Button
              danger
              className="mr-5"
              onClick={() => {
                form.resetFields()
                setFormUpdateOrCreateUserState(false)
              }}
            >
              Huỷ
            </Button>
            <Button loading={isLoadingUpdateEmployee} type="primary" htmlType="submit">
              {titleButton}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default EmployeeUpdate
