export const statusFilterUserAccount = [
  {
    title: 'Chọn Tất cả',
    isActive: -1
  },
  {
    title: 'Đang hoạt động',
    isActive: 1
  },
  {
    title: 'Dừng hoạt động',
    isActive: 0
  }
]

export const statusFilterCustomer = [
  {
    title: 'Chọn Tất cả',
    isActive: -1
  },
  {
    title: 'Đã hoàn thành',
    isActive: 1
  },
  {
    title: 'Chưa hoàn thành',
    isActive: 0
  }
]

export const statusFilterStrategy = [
  {
    title: 'Chọn Tất cả',
    isActive: -1
  },
  {
    title: 'Đang hoạt động',
    isActive: 1
  },
  {
    title: 'Dừng hoạt động',
    isActive: 0
  }
]
