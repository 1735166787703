import request from '../utils/request'

export const login = async (email, password) => {
  try {
    const response = await request.post('User/Login', {
      email,
      password
    })
    if (response.status === 200 && response.data) {
      return response.data
    }
  } catch (error) {
    throw new Error(error.message)
  }
}

export const checkLogin = () => {
  const token = localStorage.getItem('jwtToken')

  return token
}
