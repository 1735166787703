import React, { useState, useEffect } from 'react'
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Checkbox,
  Card,
  Select,
  Tag
} from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  CaretDownOutlined,
  PlusOutlined
} from '@ant-design/icons'
import '../User.css'
import { deleteMultipleGroups, createGroups, getGroups, updateGroups } from '../../../services/groupUserService'
import { statusFilterUserAccount } from '../../../constants/statusFilter'
import { useMediaQuery } from 'react-responsive'
import { useDispatch } from 'react-redux'
import {
  clearAndPushErrorMessage,
  clearAndPushSuccessMessage
} from '../../../slices/errorMessageSlice'
import screenSize from '../../../constants/screenSize'

function GroupUser ({ tabIndex, setTabIndex }) {
  const dispatch = useDispatch()
  const [isEditGroupInfo, setIsEditGroupInfo] = useState(false)
  const [dataGroups, setDataGroups] = useState(false)
  const [isModalDeleteState, setIsModalDeleteState] = useState(false)
  const [listIdDelete, setListIdDelete] = useState([])
  const [modalCreateOrUpdateState, setModalCreateOrUpdateState] =
    useState(false)
  const [idGroupUpdate, setIdGroupUpdate] = useState()
  const [isUpdate, setIsUpdate] = useState(false)
  const [titleModalForm, setTitleModalForm] = useState()
  const [currentDataGroup, setCurrentDataGroup] = useState()
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(-1)
  const [form] = Form.useForm()
  const { Option } = Select
  const { TextArea } = Input

  const isTabletOrMobile = useMediaQuery({ query: screenSize.TABLETMINI })

  const confirmDelete = () => {
    const handleAction = async () => {
      const response = await deleteMultipleGroups(
        listIdDelete
      )
      if (!response.success) {
        dispatch(clearAndPushErrorMessage(response.errorMessage))
      } else {
        dispatch(clearAndPushSuccessMessage('Xoá nhóm người dùng thành công '))
        setIsEditGroupInfo(!isEditGroupInfo)
      }

      setIsModalDeleteState(false)
    }

    handleAction()
  }

  const handleAdd = () => {
    setCurrentDataGroup({ isActive: true }) // default active is true
    setTitleModalForm('Thêm mới nhóm!')
    setModalCreateOrUpdateState(true)
    setIsUpdate(false)
  }

  const openModal = () => setIsModalDeleteState(true)

  const deleteGroup = (id) => {
    setListIdDelete([id])
    openModal()
  }

  const handleUpdate = (value) => {
    setTitleModalForm('Cập nhật nhóm!')
    setCurrentDataGroup(value)
    setIdGroupUpdate(value.id)
    setModalCreateOrUpdateState(true)
    setIsUpdate(true)
  }

  const columns = [
    {
      title: 'Tên',
      render: (group) => {
        if (group.isDefault) {
          return <div className="font-semibold">{ group.name}</div>
        } else {
          return <div>{ group.name}</div>
        }
      }

    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Số lượng thành viên',
      dataIndex: 'numberOfUsers',
      key: 'numberOfUsers'
    },
    {
      title: 'Trạng thái',
      align: 'center',
      key: 'isActive',
      render: (value) => {
        if (value.isActive) {
          return < Tag color="green"> Đang hoạt động</Tag>
        } else {
          return < Tag color="volcano"> Đã dừng hoạt động</Tag>
        }
      }
    },
    {
      title: 'Chức năng',
      align: 'center',
      render: (value) => {
        if (!value.isDefault) {
          return (
            <div className="flex justify-center ">
              <Button
                className="edit-button"
                size="middle"
                icon={<EditOutlined />}
                onClick={() => handleUpdate(value)}
              />
              <Button
                className="ml-5 delete-button"
                size="middle"
                icon={<DeleteOutlined />}
                onClick={() => deleteGroup(value.id)}
              />
            </div>
          )
        }
      }
    }
  ]

  const closeModal = () => {
    setIsModalDeleteState(false)
  }

  const actionCreate = async (values) => {
    const response = await createGroups(values)
    if (response.success) {
      dispatch(
        clearAndPushSuccessMessage('Tạo mới nhóm người dùng thành công')
      )

      setModalCreateOrUpdateState(false)
    } else {
      dispatch(clearAndPushErrorMessage(response.message))
    }

    setIsEditGroupInfo(!isEditGroupInfo)
  }

  const actionUpdate = async (values) => {
    const infoGroupUpdate = { idGroupUpdate, ...values }
    const response = await updateGroups(infoGroupUpdate)
    if (response.success) {
      dispatch(
        clearAndPushSuccessMessage('Cập nhật nhóm người dùng thành công')
      )

      setModalCreateOrUpdateState(false)
    } else {
      dispatch(clearAndPushErrorMessage(response.errorMessage))
    }

    setIsEditGroupInfo(!isEditGroupInfo)
  }

  const confirmCreateOrUpdateGroup = (values) => {
    values = {
      name: values?.name?.trim(),
      description: values?.description?.trim(),
      ...values
    }

    isUpdate ? actionUpdate(values) : actionCreate(values)
  }

  const handleSelectStatus = (value) => {
    setSelectedStatusFilter(value)
  }

  const getDataGroups = async (status) => {
    const response = await getGroups(status)
    if (response.error) {
      dispatch(clearAndPushErrorMessage(response.errorMessage))
    } else {
      if (response.success) {
        setDataGroups(response.results)
      } else {
        dispatch(clearAndPushErrorMessage(response.errorMessage))
      }
    }
  }

  useEffect(() => {
    setTabIndex()

    getDataGroups(selectedStatusFilter)
  }, [isEditGroupInfo, selectedStatusFilter])

  return (
    <Card
      size='small'
      title={
        <div className=" flex flex-col  tabletmini:flex-row tabletmini:justify-between items-start">
          <h3>Danh sách nhóm người dùng</h3>
          <div className="flex w-full justify-end  tabletmini:w-max  feature-group mb-2 tabletmini:mb-0">
            <Select
              defaultValue={selectedStatusFilter}
              onChange={handleSelectStatus}
              suffixIcon={<CaretDownOutlined />}
            >
              {statusFilterUserAccount.map((item) => (
                <Option value={item.isActive} key={item.isActive}>
                  {item.title}
                </Option>
              ))}
            </Select>
            <Button
              className=" min-w-[50px]  mx-2 tablet:mx-4"
              style={{ backgroundColor: '#52c41a', color: 'white' }}
              onClick={handleAdd}
              icon={isTabletOrMobile && <PlusOutlined />}
            >
              {!isTabletOrMobile && 'Thêm mới'}
            </Button>
            {/* <Button
              type="primary"
              className="min-w-[50px]"
              danger
              disabled={!listIdDelete.length}
              onClick={openModal}
              icon={isTabletOrMobile && <DeleteOutlined />}
            >
              {!isTabletOrMobile && 'Xoá hàng loạt'}
            </Button> */}
          </div>
        </div>
      }
    >
      <div className="tablet:gap-4">
        <Table
          scroll={{
            y: 700,
            x: 800
          }}
          columns={columns}
          dataSource={dataGroups}
          rowKey="id"
        />
        <Modal
          title="Thông báo!"
          open={isModalDeleteState}
          onOk={confirmDelete}
          onCancel={closeModal}
        >
          Bạn có chắc muốn xoá không?
        </Modal>

        <Modal
          title={titleModalForm}
          forceRender
          open={modalCreateOrUpdateState}
          onCancel={() => setModalCreateOrUpdateState(false)}
          footer={null}
        >
          <Form
            form={form}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 18 }}
            onFinish={confirmCreateOrUpdateGroup}
            fields={[
              {
                name: ['name'],
                value: currentDataGroup?.name
              },
              {
                name: ['description'],
                value: currentDataGroup?.description
              },
              {
                name: ['isActive'],
                value: currentDataGroup?.isActive
              }
            ]}
          >
            <Form.Item
              label="Tên"
              name="name"
              rules={[{ required: true, message: 'Vui lòng nhập tên nhóm!' }]}
            >
              <Input autoComplete='off'/>
            </Form.Item>

            <Form.Item label="Mô tả" className="field-mb-2" name="description">
              <TextArea autoComplete='off'/>
            </Form.Item>

            <Form.Item
              name="isActive"
              valuePropName="checked"
              className=" tablet "
              wrapperCol={{ sm: { offset: 5, span: 16 } }}
            >
              <Checkbox>Kích hoạt</Checkbox>
            </Form.Item>

            <Form.Item className="flex justify-end">
              <Button
                danger
                className="mr-5"
                onClick={() => setModalCreateOrUpdateState(false)}
              >
                Huỷ
              </Button>
              <Button type="primary" htmlType="submit">
                Lưu lại
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </Card>
  )
}

export default GroupUser
