/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import * as signalR from '@microsoft/signalr'
import {
  MessageOutlined,
  CloseOutlined,
  SendOutlined,
  UploadOutlined
} from '@ant-design/icons'
import { checkIsTechnical } from '../../permissions'
import moment from 'moment'
import { Button, Input, Upload } from 'antd'
import { getStaticInfo } from '../../services/common'

const CustomerSupport = () => {
  const [showChat, setShowChat] = useState(false)
  const isTechnical = checkIsTechnical()
  const connectionHub = useRef()
  const [message, setMessage] = useState('')
  const { userId } = getStaticInfo()
  const [messages, setMessages] = useState([])

  const sendMessage = useCallback(
    async (message, messageType) => {
      if (connectionHub.current && message.trim().length > 0) {
        await connectionHub.current.send('SendAdminMessage', {
          userId,
          content: message,
          type: messageType,
          time: Date.now()
        })
        setMessage('')
      }
    },
    [connectionHub.current]
  )

  useEffect(() => {
    if (showChat) {
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_API_BASEURL}chatHub`, {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets
        })
        .build()
      if (connection) {
        connectionHub.current = connection
        connection.start()
        connection.on('UserReceiveMessage', (data) => {
          if (userId === data.sendBy || userId === data.receiveBy) {
            setMessages((msgs) => [...msgs, data])
          }
        })
      }
    }
    return () => {
      connectionHub?.current?.stop()
    }
  }, [showChat])

  // return !isTechnical
  //   ? (
  // 	<div className="fixed bottom-2 right-10 z-10">
  // 		{!showChat
  // 		  ? (
  // 			<span className="cursor-pointer" onClick={() => setShowChat(!showChat)}>
  // 				<MessageOutlined style={{ fontSize: '40px' }} />
  //         <p>Hỗ trợ</p>
  // 			</span>
  // 		    )
  // 		  : (
  // 			<div className="rounded w-72 h-96 border-solid bg-white">
  // 				<div className="flex flex-row p-2 justify-between bg-blue-200">
  // 					<div>Hỗ trợ</div>
  // 					<div
  // 						className="cursor-pointer"
  // 						onClick={() => setShowChat(!showChat)}
  // 					>
  // 						<CloseOutlined />
  // 					</div>
  // 				</div>
  // 				<div className="p-2 h-72 bg-white overflow-y-auto flex flex-col grow">
  // 					{messages?.map((message, i) => (
  // 						<div
  // 							className={`flex items-center gap-1 ${
  // 								message.sendBy === userId && 'self-end flex-row-reverse'
  // 							}`}
  // 							key={i}
  // 						>
  // 							<div
  // 								className={`rounded w-fit max-w-2/3 break-words py-0.5 px-1 mb-0.5 ${
  // 									message.sendBy === userId
  // 										? 'self-end bg-blue-300'
  // 										: 'bg-gray-400'
  // 								}`}
  // 							>
  // 								{message.content}
  // 							</div>
  // 							<div>{moment(message.time).format('hh:mm')}</div>
  // 						</div>
  // 					))}
  // 				</div>
  // 				<div className="pt-2 mx-2 flex items-center justify-center border-t-1 bt-solid">
  //           {/* <Upload {...props}>
  //             <Button icon={<UploadOutlined />}></Button>
  //           </Upload> */}
  // 					<Input
  // 						value={message}
  // 						onChange={(e) => setMessage(e.target.value)}
  // 					/>
  // 					<span className="ml-5">
  // 						<SendOutlined onClick={() => sendMessage(message, 1)} />
  // 					</span>
  // 				</div>
  // 			</div>
  // 		    )}
  // 	</div>
  //     )
  //   : null

  return <></>
}

export default CustomerSupport
