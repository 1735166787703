import { RightOutlined } from '@ant-design/icons'
import { Breadcrumb } from 'antd'
import React from 'react'

export default function HistoryBreadCrumb () {
  return (
    <Breadcrumb separator={<RightOutlined />}>
      <Breadcrumb.Item href='/'>Home</Breadcrumb.Item>
      <Breadcrumb.Item href="">Lịch sử cuộc gọi</Breadcrumb.Item>
    </Breadcrumb>
  )
}
