import { Tag } from 'antd'
import React from 'react'

function CompleteStatusTag ({ isCompleted }) {
  return (
    <div>
      {isCompleted ? <Tag color='blue'>Đã hoàn thành</Tag> : <Tag color='red'>Chưa hoàn thành</Tag>}
    </div>
  )
}

export default CompleteStatusTag
