import React, { useRef } from 'react'

import { ScriptStep, AssignmentStep, ContactStep, ReportStep, ListContactStep } from './Step/Index.jsx'
import { Steps } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { setCurrent, getCurrentStep } from '../../../slices/currentStepSlice'
import { useLocation } from 'react-router-dom'

const { Step } = Steps
function StrategyDetail () {
  const dispatch = useDispatch()
  const currentStep = useSelector(getCurrentStep)

  function useQuery () {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  const query = useQuery()
  const strategyId = useRef(query.get('strategyId'))

  function setStrategyId (newStrategyId) {
    strategyId.current = newStrategyId
  }

  const steps = [
    {
      title: 'Kịch bản',
      content: <ScriptStep strategyId = {strategyId.current} setStrategyId = {setStrategyId}/>
    },
    {
      title: 'Phân công',
      content: <AssignmentStep strategyId = {strategyId.current}/>
    },
    {
      title: 'Thông tin liên hệ',
      content: <ContactStep strategyId = {strategyId.current}/>
    },
    {
      title: 'Thông tin báo cáo',
      content: <ReportStep strategyId = {strategyId.current}/>
    },
    {
      title: 'Danh sách liên hệ',
      content: <ListContactStep strategyId = {strategyId.current}/>
    }
  ]

  const onChange = (value) => {
    dispatch(setCurrent(value))
  }

  return (
    <div className='mt-5 tablet:mt-0 p-5'>
      <Steps current={currentStep} onChange={onChange} labelPlacement="vertical">
        {steps.map((item) => (
             <Step className='whitespace-nowrap' disabled={!strategyId.current} key={item.title} title={item.title} />

        ))}
      </Steps>
      <div className="steps-content mt-7">{steps[currentStep].content}</div>
    </div>
  )
}

export default StrategyDetail
