import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter, Switch, Route, Navigate, useLocation } from 'react-router-dom'
import Pages from './pages'
import { DefaultLayout } from './components/Layout'
import 'antd/dist/antd.min.css'
import { store } from './store'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root'))

// const pageItem = Pages.map((item, key) => {
//   const Layout = item.Layout || DefaultLayout
//   const Page = item.page
//   const CardTitle = item.cardTitle

//   if (item.path === '/login') {
//     return <Route key={key} path={item.path} ><Page /></Route>
//   }
//   if (CardTitle) {
//     return <Route exact={item.exact} key={key} path={item.path} ><Layout cardTitle={<CardTitle />} ><Page /></Layout></Route>
//   } else {
//     return <Route exact={item.exact} key={key} path={item.path} ><Layout ><Page /></Layout></Route>
//   }
// })

// Sentry.init({
//   dsn: 'https://59e04734f2f84275b696aa2e7b364219@o4504908889718784.ingest.sentry.io/4504908891226112',
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: 1.0
// })

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />

    </BrowserRouter>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
